import React, { useLayoutEffect, useState, useCallback } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS, roundCurrency } from "../../../utils"
import FullScreenWrapper from "../../../components/FullScreenWrapper"
import {
    useSelector,
    useDispatch
} from "react-redux"
import {
    resetOrderSupplierItem,
    validateLotReception
} from "../../../actions/OrderReception/OrderReception"
import Button from "@mui/material/Button"
import BackModal from "../../../components/Order/Lot/BackModal"
import SupplierItemLotsLeftBloc from "./SupplierItemLotsLeftBloc"
import SupplierItemLotsRightBloc from "./SupplierItemsLotsRightBloc"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import { computeOrderSupplierItemQuantityLeft } from "../../../utils/supplierItemLotsUtils"
import { generateStockUnitLabel, generateStockUnitWeightLabel } from "../../../utils/orderReception"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import SingleReceptionHeader from "../../../components/Order/Reception/SingleReceptionHeader"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import SupplierItemCreditNotes from "../../../components/Order/Lot/SupplierItemCreditNotes"
import CreditNoteLot from "../../../components/Order/CreditNote/CreditNoteLot"
import CreditNoteBroken from "../../../components/Order/CreditNote/CreditNoteBroken"
import { creditNotesType } from "../../../utils/creditNoteUtils"

const useStyles = makeStyles(() => ({
    tabletMode: {
        transform: "rotate(-90deg)",
        transformOrigin: "left top"
    },
    root: {
        display: "flex",
        height: "91%"
    },
    appBar: {
        backgroundColor: COLORS.WHITE,
        borderBottom: `2px solid ${COLORS.LIGHT_GREY_3}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    appBarInfos: {
        margin: "10px auto 10px 20px"
    },
    appBarTitle: {
        color: COLORS.GREY_700,
        fontWeight: 700,
        fontSize: 16,
        textTransform: "uppercase"
    },
    appBarSubtitle: {
        marginTop: 10,
        color: COLORS.DARK_GREY,
        fontSize: 12,
        textTransform: "uppercase"
    },
    tabsContainer: {
        marginTop: 72,
        display: "flex",
        flexDirection: "row",
        borderBottom: `1px solid ${COLORS.LIGHT_GREY_3}`
    },
    lastTab: {
        marginLeft: "auto"
    },
    floatingButton: {
        position: "absolute",
        bottom: 20,
        left: 20
    },
    leftSide: {
        flex: "1 1 auto"
    },
    rightSide: {
        height: "100%",
        flex: "0 0 299px",
        justifyContent: "center",
        alignItems: "center",
        borderLeft: `2px solid ${COLORS.LIGHT_GREY_3}`
    },
    firstButton: {
        marginRight: 10,
    },
    black: {
        color: COLORS.BLACK
    },
}))

const SupplierItemLots = () => {
    const dispatch = useDispatch()
    const selectedOrderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const selectedOrderSupplierItem = useSelector(state => state.orderReception.selectedOrderSupplierItem)
    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)

    const classes = useStyles()
    const [showModalReturn, setShowModalReturn] = useState(false)
    const [showCreditNotesModal, setShowCreditNotesModal] = useState(false)
    const [showAddCreditNoteModal, setShowAddCreditNoteModal] = useState(false)
    const [creditNotetype, setCreditNoteType] = useState(null)
    const [lots, setLots] = useState(selectedOrderSupplierItem ? selectedOrderSupplierItem.lots : [])
    const [creditNotes, setCreditNotes] = useState(new Map())
    const [creditNotesAmount, setCreditNotesAmount] = useState(0)
    const [quantityLeft, setQuantityLeft] = useState(computeOrderSupplierItemQuantityLeft(selectedOrderSupplierItem))
    const [showAddLot, setShowAddLot] = useState(false)
    const [selectedLot, setSelectedLot] = useState(null)

    useLayoutEffect(() => {
        if (selectedOrderSupplierItem) {
            setLots(selectedOrderSupplierItem.lots)
            setQuantityLeft(computeOrderSupplierItemQuantityLeft(selectedOrderSupplierItem))
        }
    }, [selectedOrderReception, selectedOrderSupplierItem])

    useLayoutEffect(() => {
        const length = getCreditNotesAmount(creditNotes)
        setCreditNotesAmount(length)
    }, [creditNotes])

    const onClickReturn = () => setShowModalReturn(true)
    const cancelReturn = () => setShowModalReturn(false)

    const goBack = useCallback(() => {
        dispatch(resetOrderSupplierItem(selectedOrderReception, selectedOrderSupplierItem.supplierItem.objectId))
    }, [site, date, selectedOrderReception])

    const onValidateReception = () => {
        dispatch(validateLotReception(selectedOrderReception, selectedOrderSupplierItem, lots, creditNotes))
    }

    const onAddLot = () => {
        setSelectedLot(null)
        setShowAddLot(true)
    }

    const onSelectLot = (lot) => {
        setShowAddLot(false)

        if (selectedLot && lot.id === selectedLot.id) {
            setSelectedLot(null)
        }
        else {
            setSelectedLot(lot)
        }
    }

    const onShowCreditNotes = () => {
        setShowCreditNotesModal(true)
    }

    const onHideCreditNotes = () => {
        setShowCreditNotesModal(false)
    }

    const addLot = (newLot) => {
        setLots([
            ...lots,
            newLot
        ])
        const newQuantity = parseFloat((quantityLeft - newLot.quantity).toFixed(3))
        setQuantityLeft(newQuantity)
        setShowAddLot(false)
    }

    const deleteLot = (lot) => {
        const creditNotesCopy = new Map(creditNotes)
        const newQuantity = parseFloat((quantityLeft + lot.quantity).toFixed(3))
        creditNotesCopy.delete(lot.id)
        setQuantityLeft(newQuantity)
        setLots(lots.filter(el => el.id !== lot.id))
        setSelectedLot(null)
        setCreditNotes(creditNotesCopy)
    }

    const addCreditNote = data => {
        const values = creditNotes

        const notes = values.get(data.lotId) || []
        notes.push(data.creditNoteData)
        values.set(data.lotId, notes)

        if (data.lotId === "global") {
            const newQuantity = quantityLeft - data.creditNoteData.creditNote.quantity
            setQuantityLeft(parseFloat(newQuantity.toFixed(3)))
        }

        setCreditNotes(new Map(values))
    }

    const deleteCreditNotes = data => {
        const values = creditNotes
        let quantity = quantityLeft

        data.forEach(item => {
            const notes = values.get(item.lot) || []
            const elem = notes.find(el => el.creditNote.id === item.id)
            const index = notes.indexOf(elem)

            if (item.lot === "global") {
                quantity += elem.creditNote.quantity
            }

            if (index > -1) {
                notes.splice(index, 1)
            }
            if (notes.length === 0) {
                values.delete(item.lot)
            } else {
                values.set(item.lot, notes)
            }
        })

        setQuantityLeft(parseFloat(quantity.toFixed(3)))
        setCreditNotes(new Map(values))
    }

    const actions = (callBack) => {
        switch (callBack.method) {
            case "ADD_LOT":
                addLot(callBack.data)
                return
            case "DELETE_LOT":
                deleteLot(callBack.data)
                return
            case "ADD_CREDIT_NOTE":
                addCreditNote(callBack.data)
                setShowAddCreditNoteModal(false)
                return
            case "DELETE_CREDIT_NOTE":
                deleteCreditNotes(callBack.data)
                return
            case "SHOW_ADD_CREDIT_NOTE_MODAL":
                setCreditNoteType(callBack.data.type)
                setShowAddCreditNoteModal(true)
                return
            default:
                return
        }
    }

    const getCreditNotesAmount = (items) => {
        let length = 0
        Array.from(items).forEach(([, values]) => {
            length += values.length
        })

        return length
    }

    const generateHeadings = useCallback(() => {
        return {
            title: selectedOrderSupplierItem.supplierItem.name,
            subtitle: supplierItemTypes[selectedOrderSupplierItem.supplierItem.type].label.toUpperCase()
        }
    }, [selectedOrderSupplierItem])

    const generateHeaderInfos = useCallback(() => {
        const pricePerStockUnit = (selectedOrderSupplierItem.supplierItem.units && selectedOrderSupplierItem.supplierItem.units.stock && selectedOrderSupplierItem.supplierItem.units.stock.price) || ""
        const unitType = generateStockUnitLabel(selectedOrderSupplierItem.supplierItem)
        const stockUnitWeight = generateStockUnitWeightLabel(selectedOrderSupplierItem.supplierItem)
        return {
            reference: `${selectedOrderSupplierItem.supplierItem.reference}`,
            stockUnitWeight: `${stockUnitWeight}`,
            pricePerStockUnit: `${roundCurrency(pricePerStockUnit)} € / ${unitType}`
        }
    }, [selectedOrderSupplierItem])

    const renderHeaderActions = () => {
        return (
            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.firstButton}
                    endIcon={<InfoOutlinedIcon className={classes.black} />}
                    onClick={onShowCreditNotes}
                >
                    Voir les avoirs et réclamations ({creditNotesAmount})
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={quantityLeft > 0}
                    onClick={onValidateReception}
                >
                    Valider la réception
                </Button>
            </div>
        )
    }

    const generateCreditNoteData = () => {
        return {
            headerData: {
                goBack: () => setShowAddCreditNoteModal(false),
                headings: generateHeadings(),
                infos: generateHeaderInfos()
            },
            lotData: selectedLot,
            orderNumber: selectedOrderReception.orderNumber,
            supplierItemName: selectedOrderSupplierItem.supplierItem.supplierArticleName,
            supplierItem: selectedOrderSupplierItem.supplierItem,
            quantityLeft,
            lotActions: actions
        }
    }

    return (
        <FullScreenWrapper>
            {
                selectedOrderReception && selectedOrderSupplierItem &&
                <>
                    <div className={classes.headerBloc}>
                        <GenericTopBarHeader
                            leftAction={<GenericHeaderReturnButton handleClick={onClickReturn}/>}
                            title={<SingleReceptionHeader headings={generateHeadings()} infos={generateHeaderInfos()}/>}
                            rightAction={renderHeaderActions()}
                        />
                    </div>
                    <div className={classes.root}>
                        <div className={classes.leftSide}>
                            <SupplierItemLotsLeftBloc
                                quantityLeft={quantityLeft}
                                lots={lots}
                                selectedLot={selectedLot}
                                onAddLot={onAddLot}
                                onSelectLot={onSelectLot}
                                actions={actions}
                                creditNotes={creditNotes}
                            />
                        </div>
                        <div className={classes.rightSide}>
                            <SupplierItemLotsRightBloc
                                showAddLot={showAddLot}
                                selectedLot={selectedLot}
                                quantityLeft={quantityLeft}
                                orderSupplierItem={selectedOrderSupplierItem.supplierItem}
                                stockZones={selectedOrderReception.site.stockZones}
                                actions={actions}
                                receptionDate={selectedOrderReception.receptionDate}
                            />
                        </div>
                    </div>
                    {
                        showModalReturn &&
                        <BackModal
                            open={showModalReturn}
                            goBack={goBack}
                            onClose={cancelReturn}
                        />
                    }
                    {
                        showCreditNotesModal &&
                        <SupplierItemCreditNotes
                            creditNotes={creditNotes}
                            goBack={onHideCreditNotes}
                            selectedOrderReception={selectedOrderReception}
                            selectedOrderSupplierItem={selectedOrderSupplierItem}
                            actions={actions}
                        />
                    }
                    {
                        showAddCreditNoteModal && creditNotetype === creditNotesType.LOT &&
                        <CreditNoteLot
                            {...generateCreditNoteData()}
                        />
                    }
                    {
                        showAddCreditNoteModal && creditNotetype === creditNotesType.BROKEN &&
                        <CreditNoteBroken
                            {...generateCreditNoteData()}
                        />
                    }
                </>
            }
        </FullScreenWrapper>
    )
}

export default SupplierItemLots