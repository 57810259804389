import React from "react"
import { makeStyles } from "@mui/styles"
import LotCard from "../../../components/Order/Lot/LotCard"
import AddLotCard from "../../../components/Order/Lot/AddLotCard"
import Warning from "@mui/icons-material/Warning"
import Button from "@mui/material/Button"
import { COLORS } from "../../../utils"
import { creditNotesType } from "../../../utils/creditNoteUtils"

const useStyles = makeStyles(() => ({
	cardContainer: {
		padding: "25px 20px",
		width: "100%",
		position: "relative",
		height: "100%"
	},
	leftTitle: {
		fontSize: 25,
		marginBottom: 20
	},
	cards: {
		display: "flex",
		flexWrap: "wrap"
	},
	buttonContainer: {
		position: "absolute",
		left: "50%",
		bottom: "5%"
	},
	brokenButton: {
		position: "relative",
		left: "-50%",
		width: 220,
		height: 46,
		borderRadius: 23,
		backgroundColor: COLORS.RED_BROKEN,
		color: COLORS.WHITE,
		"&:hover": {
			backgroundColor: COLORS.RED_BROKEN,
			opacity: .7
		}
	}
}))

const SupplierItemLotsLeftBloc = (props) => {
	const {
		quantityLeft,
		lots,
		onAddLot,
		onSelectLot,
		selectedLot,
		creditNotes,
		actions
	} = props
	
	const classes = useStyles()

	const handleClickBroken = () => {
		actions({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL",
			data: {
				type: creditNotesType.BROKEN
			}
		})
	}
	
	return (
		<div className={classes.cardContainer}>
			<p className={classes.leftTitle}>
				Déclarez les lots et générez les avoirs :
			</p>
			<div className={classes.cards}>
				{
					lots.map((lot, i) =>
						<LotCard
							key={i}
							lot={lot}
							selectedLot={selectedLot}
							onSelectLot={onSelectLot}
							creditNotes={creditNotes.get(lot.id) ? creditNotes.get(lot.id).length : 0}
						/>
					)
				}
				{
					quantityLeft > 0 &&
						<AddLotCard
							onAddLot={onAddLot}
						/>
				}
			</div>
			<div className={classes.buttonContainer}>
				<Button
					className={classes.brokenButton}
					variant="contained"
					startIcon={<Warning />}
					onClick={handleClickBroken}
				>
					casse
				</Button>
			</div>
		</div>
	)
}

export default SupplierItemLotsLeftBloc