import { TableContainer, styled } from "@mui/material"
import { TableCell, TableHead } from "@mui/material"
import { Box } from "@mui/material"
import { Typography } from "@mui/material"
import { COLORS } from "../../utils"

export const StyledMainContainer = styled(Box)({
	paddingTop: "64px"
})

export const StyledHeadTableCell = styled(TableCell)({
	color: "#262626",
	fontSize: 14,
	fontWeight: 500,
	lineHeight: "22px",
	borderBottom: "none",
	padding: "14px 16px",
	verticalAlign: "top"
})

export const StyledTableCell = styled(TableCell)({
	color: "#555",
	fontSize: 16,
	fontWeight: 400,
	lineHeight: "24px",
	verticalAlign: "top"
})

export const StyledTableHead = styled(TableHead)({
	backgroundColor: COLORS.HEADER_BLUE
})

export const StyledTableContainer = styled(TableContainer)({
	borderTop: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
	borderLeft: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
	borderRight: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
	borderBottom: "none",
	borderRadius: "6px",
	margin: "16px 0px"
})

export const StyledContainer = styled(Box)({
	border: "1px solid " + COLORS.PRODUCTION_SCHEMA_BORDER_COLOR,
	borderRadius: "6px"
})

export const StyledSubStatusTitle = styled(Typography)({
	color: "#7C7C7C",
	fontSize: 16,
	fontWeight: 400,
	lineHeight: "24px"
})


export const StyledErrorMessage = styled(Typography)({
	color: "#F44259",
	fontSize: 14,
	fontWeight: 400,
	lineHeight: "22px"
})


export const StyledItemLabel = styled(Typography)({
	color: "#555",
	fontSize: "14px",
	fontWeight: 400,
	lineHeight: "22px"
})

export const StyledItemValue = styled(Typography)({
	color: "#262626",
	fontSize: "16px",
	fontWeight: 400,
	lineHeight: "24px"
})