import { cloneDeep } from "lodash"

export const getDataGridFiltersStored = (supplierItems = [], defaultAdditionalFilters, from) => {
    const filtersFromStorage = JSON.parse(localStorage.getItem("dataGridFilters"))
    const defaultFilters = cloneDeep(filtersFromStorage)
    if (defaultFilters && defaultFilters.supplierItems && supplierItems.length > 0) {
        const supplierItemStored = supplierItems.find(item => (item.name + " - " + item.objectId) === defaultFilters.supplierItems)

        if (supplierItemStored) {
            defaultFilters.supplierItems = supplierItemStored.name + " - " + supplierItemStored.objectId
            defaultAdditionalFilters.supplierItems = defaultFilters.supplierItems
        }
    }

    if (defaultFilters && from) {
        const filterToUse = from === "billing" ? "receptionDateBilling" : from === "creditNotes" ? "receptionDateCreditNotes" : "receptionDateOrderReception"
        if (defaultFilters[filterToUse]) {
            defaultAdditionalFilters.receptionDate = defaultFilters[filterToUse]
            defaultFilters.receptionDate = defaultFilters[filterToUse]
        }
        else {
            defaultFilters.receptionDate = defaultAdditionalFilters.receptionDate
        }
    }
    if (defaultFilters) {
        if (defaultFilters.receptionDateBilling) {
            delete defaultFilters.receptionDateBilling
        }
        if (defaultFilters.receptionDateCreditNotes) {
            delete defaultFilters.receptionDateCreditNotes
        }
        if (defaultFilters.receptionDateOrderReception) {
            delete defaultFilters.receptionDateOrderReception
        }
    }

    return defaultFilters
}

export const formatFiltersBeforeStorage = (filters, from) => {
    const storedFilters = cloneDeep(filters)
    const filterReceptionDate = storedFilters.receptionDate
    if (filterReceptionDate.isRange) {
        if (from === "billing") {
            storedFilters.receptionDateBilling = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
        if (from === "creditNotes") {
            storedFilters.receptionDateCreditNotes = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
        if (from === "orderReception") {
            storedFilters.receptionDateOrderReception = {
                start: filterReceptionDate.start,
                end: filterReceptionDate.end,
                isRange: true
            }
        }
    }
    delete storedFilters.receptionDate
    return storedFilters
}