import React, { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { Button, Stack } from "@mui/material"

import {
  PSE_STATUS,
  showProductionStepsSupervision,
  updateProductionStepExecutionStatusToDone,
  updateProductionStepExecutionLots,
  saveProductionStepsCoolingInformations,
  saveProductionStepExecutionEndCoolingInformations,
  saveProductionStepExecutionEndCookingInformations,
  updateProductionStepExecutionSubStatus,
  saveProductionStepExecutionWeightValidationInformation
} from "../../actions/ProductionSteps/ProductionStepExecutions"
import ProductionStepExecutionFooter from "./ProductionStepExecutionFooter"
import PSEResume from "./preview/PSEResume"
import PSEProgressBar from "./preview/PSEProgressBar"
import ProductionStepExecutionLotsSelection, { productionStepExecutionLotsSelectionFormId } from "./productionStepExecutionInProgressCases/ProductionStepExecutionLotsSelection"
import { calculateNextSubStatus, getPriorStepData, PSE_SUBSTATUSES } from "../../utils/productionStepExecution"
import ProductionStepExecutionStepCompletion from "./productionStepExecutionInProgressCases/ProductionStepExecutionStepCompletion"
import ProductionStepExecutionTemperatureValidation from "./productionStepExecutionInProgressCases/ProductionStepExecutionTemperatureValidation"
import { productionStepExecutionCoolingFormId } from "./productionStepExecutionInProgressCases/ProductionStepExecutionCoolingForm"
import { productionStepExecutionCoolingEndFormId } from "./productionStepExecutionInProgressCases/ProductionStepExecutionCoolingEndForm"
import { productionStepExecutionCookingEndFormId } from "./productionStepExecutionInProgressCases/ProductionStepExecutionCookingEndForm"
import ProductionStepExecutionWeightValidation, { productionStepExecutionWeightValidationFormId } from "./productionStepExecutionInProgressCases/ProductionStepExecutionWeightValidation"
import { StyledMainContainer } from "./styledComponents"
import ProductionStepsExecutionFixedHeader from "./ProductionSteoExecutionsFixedHeader"


const ProductionStepExecutionInProgress = ({
  productionStepExecution,
  onClickCommentsIcon,
  commentsCount
}) => {

  const dispatch = useDispatch()

  const handleBack = () => dispatch(showProductionStepsSupervision())

  const transformationType = useMemo(() => productionStepExecution.productionStepSnapshot.transformation, [productionStepExecution])

  const handleConfirmPSEToDone = useCallback(() => {
    const formattedValues = {
      status: productionStepExecution.isSectionLastStep ? PSE_STATUS.toTest : PSE_STATUS.done
    }
    dispatch(updateProductionStepExecutionStatusToDone(productionStepExecution.objectId, formattedValues))
  }, [productionStepExecution])

  const handleSubStatusChange = useCallback(() => {
    const newSubStatus = calculateNextSubStatus(productionStepExecution)
    if (newSubStatus) {
      dispatch(updateProductionStepExecutionSubStatus({ id: productionStepExecution.objectId, newSubStatus }))
    }
    if (!newSubStatus) {
      handleConfirmPSEToDone()
    }
  }, [productionStepExecution])

  const handleConfirmLotsForm = useCallback((values) => {
    const nextSubStatus = calculateNextSubStatus(productionStepExecution)
    const { secondaryDLCs, lots } = values
    dispatch(updateProductionStepExecutionLots({
      id: productionStepExecution.objectId,
      secondaryDLCsIds: secondaryDLCs || [],
      lotsIds: lots || [],
      newSubStatus: nextSubStatus
    }))
  }, [productionStepExecution])


  const handleConfirmCoolingForm = useCallback((values) => {
    const newSubStatus = calculateNextSubStatus(productionStepExecution)
    dispatch(saveProductionStepsCoolingInformations({
      productionStepExecutionId: productionStepExecution.objectId,
      machinesBatch: values.machinesBatch,
      newSubStatus
    }))
    if (!newSubStatus) {
      handleConfirmPSEToDone()
    }
  }, [productionStepExecution])

  const handleConfirmEndCoolingForm = useCallback((values) => {
    const newSubStatus = calculateNextSubStatus(productionStepExecution)
    dispatch(saveProductionStepExecutionEndCoolingInformations({
      productionStepExecutionId: productionStepExecution.objectId,
      machinesBatch: values.machinesBatch,
      newSubStatus
    }))
    if (!newSubStatus) {
      handleConfirmPSEToDone()
    }
  }, [productionStepExecution])


  const handleConfirmEndCookingForm = useCallback((values) => {
    const newSubStatus = calculateNextSubStatus(productionStepExecution)
    dispatch(saveProductionStepExecutionEndCookingInformations({
      productionStepExecutionId: productionStepExecution.objectId,
      machinesBatch: values.machinesBatch,
      newSubStatus
    }))
    if (!newSubStatus) {
      handleConfirmPSEToDone()
    }
  }, [productionStepExecution])

  const handleConfirmWeightValidation = useCallback((values) => {
    const newSubStatus = calculateNextSubStatus(productionStepExecution)
    dispatch(saveProductionStepExecutionWeightValidationInformation({
      productionStepExecutionId: productionStepExecution.objectId,
      netWeight: values.netWeight
    }))
    if (!newSubStatus) {
      handleConfirmPSEToDone()
    }
  }, [productionStepExecution])


  const renderRightActions = useCallback(() => {
    if (!productionStepExecution) return
    const buttonText = calculateNextSubStatus(productionStepExecution) ? "Passer à l'étape suivante" : "Terminer"
    switch (productionStepExecution.subStatus) {
      case PSE_SUBSTATUSES.lotsSelection:
        return (
          <Button variant="contained" form={productionStepExecutionLotsSelectionFormId} type="submit">
            {buttonText}
          </Button>
        )
      case PSE_SUBSTATUSES.stepCompletion:
        if (transformationType === "COOLING") {
          return (
            <Button variant="contained" form={productionStepExecutionCoolingFormId} type="submit">
              {buttonText}
            </Button>
          )
        }
        else {
          return (
            <Button variant="contained" onClick={handleSubStatusChange}>
              {buttonText}
            </Button>
          )
        }
      case PSE_SUBSTATUSES.temperatureValidation:
        if (transformationType === "COOKING") {
          return (
            <Button variant="contained" type="submit" form={productionStepExecutionCookingEndFormId}>
              {buttonText}
            </Button>
          )
        }
        if (transformationType === "COOLING") {
          return (
            <Button variant="contained" form={productionStepExecutionCoolingEndFormId} type="submit">
              {buttonText}
            </Button>
          )
        }
        else return null
      case PSE_SUBSTATUSES.weightValidation:
        return (
          <Button
            variant="contained"
            form={productionStepExecutionWeightValidationFormId} type="submit">
            {buttonText}
          </Button>
        )
      default:
        return null
    }

  }, [productionStepExecution.subStatus, transformationType])


  const _getMainContent = useCallback(() => {
    const transformationType = productionStepExecution.productionStepSnapshot.transformation
    switch (productionStepExecution.subStatus) {
      case PSE_SUBSTATUSES.lotsSelection:
        return (
          <ProductionStepExecutionLotsSelection
            productionStepExecution={productionStepExecution}
            handleSubmit={handleConfirmLotsForm}
            priorStepData={getPriorStepData(productionStepExecution)}
          />
        )
      case PSE_SUBSTATUSES.stepCompletion:
        return (
          <ProductionStepExecutionStepCompletion
            productionStepExecution={productionStepExecution}
            handleSubmit={handleConfirmCoolingForm}
            priorStepData={getPriorStepData(productionStepExecution)}
          />
        )
      case PSE_SUBSTATUSES.temperatureValidation:
        return (
          <ProductionStepExecutionTemperatureValidation
            productionStepExecution={productionStepExecution}
            handleSubmit={transformationType === "COOKING" ? handleConfirmEndCookingForm : handleConfirmEndCoolingForm}
            priorStepData={getPriorStepData(productionStepExecution)}
          />
        )
      case PSE_SUBSTATUSES.weightValidation:
        return (
          <ProductionStepExecutionWeightValidation
            productionStepExecution={productionStepExecution}
            handleSubmit={handleConfirmWeightValidation}
          />
        )
      default:
        return null
    }
  }, [productionStepExecution.subStatus])

  return (
    <>
      {/* header */}
      <ProductionStepsExecutionFixedHeader onBack={handleBack} />

      {/* body */}
      <StyledMainContainer>
        <Stack spacing={3} sx={{ p: 3, borderBottom: "1px solid #E0E0E0" }}>
          <PSEResume
            name={productionStepExecution.productionStepSnapshot.name}
            status={productionStepExecution.status}
            transformation={productionStepExecution.productionStepSnapshot.transformation}
            section={productionStepExecution.sectionName}
            recipe={productionStepExecution.recipeName}
            uniqueCode={productionStepExecution.uniqueCode}
            onClickComments={onClickCommentsIcon}
            commentsCount={commentsCount}
          />
          <PSEProgressBar
            currentSubStatus={productionStepExecution.subStatus}
            productionStepExecution={productionStepExecution}
            visible={[PSE_STATUS.inProgress, PSE_STATUS.todo].includes(productionStepExecution.status)}
            times={{
              startTime: productionStepExecution.startTime,
              endTime: productionStepExecution.endTime,
              theoreticalStartTime: productionStepExecution.theoreticalStartTime,
              theoreticalEndTime: productionStepExecution.theoreticalEndTime
            }}
          />
        </Stack>
        <Stack spacing={3} sx={{ p: 3 }}>
          {_getMainContent()}
        </Stack>
      </StyledMainContainer>

      {/* footer */}
      <ProductionStepExecutionFooter
        rightAction={renderRightActions()}
      />
    </>
  )
}

export default ProductionStepExecutionInProgress