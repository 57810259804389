const defaultState = {
    site: null,
    date: null,
    data: [],
    orderReceptionsToDo: [],
    orderReceptionsInProgress: [],
    orderReceptionsDone: [],
    selectedOrderReception: null,
    selectedOrderSupplierItem: null,
    parseSupplier: null,
    creditNotes: [],
    orderReceptionsFilters: null,
}

export default function (state = defaultState, action) {
    switch (action.type) {
        case "ORDER_RECEPTION_LOADED":
            return {
                ...state,
                date: action.date,
                site: action.site,
                data: action.data,
                orderReceptionsToDo: action.dataTodo,
                orderReceptionsInProgress: action.dataInProgress,
                orderReceptionsDone: action.dataDone,
                orderReceptionsFilters: action.filter
            }
        case "ORDER_DELIVERY_NOTE_LOADED":
            return {
                ...state,
                selectedOrderReception: action.selectedOrderReception,
                date: action.date,
                site: action.site
            }
        case "ORDER_RECEPTION_SITE_LOADED":
            return {
                ...state,
                site: action.site
            }
        case "ORDER_RECEPTION_SELECTED":
            return {
                ...state,
                selectedOrderReception: action.selectedOrderReception,
                parseSupplier: action.parseSupplier,
                date: action.date,
                site: action.site,
                creditNotes: action.creditNotes,
                lots: action.lots
            }
        case "UPDATE_SELECTED_ORDER_RECEPTION":
            return {
                ...state,
                selectedOrderReception: action.selectedOrderReception
            }
        case "UPDATE_ORDER_RECEPTION_CREDIT_NOTES":
            return {
                ...state,
                selectedOrderReception: action.selectedOrderReception,
                creditNotes: [...action.creditNotes]
            }
        case "UPDATE_SELECTED_ORDER_SUPPLIER_ITEM":
            return {
                ...state,
                selectedOrderSupplierItem: action.selectedOrderSupplierItem
            }
        case "ORDER_RECEPTION_QUANTITY":
            return {
                ...state,
                selectedOrderSupplierItem: action.selectedOrderSupplierItem,
                selectedOrderReception: action.selectedOrderReception,
                date: action.date,
                site: action.site
            }
        case "ORDER_SUPPLIER_ITEM_LOTS_LOADED":
            return {
                ...state,
                site: action.site,
                date: action.date,
                selectedOrderReception: action.selectedOrderReception,
                selectedOrderSupplierItem: action.selectedOrderSupplierItem
            }
        case "ORDER_RECEPTION_CLOSED": 
            return {
                ...state,
                selectedOrderReception: action.selectedOrderReception
            }
        case "CLEAR_ORDER_RECEPTION_CREDIT_NOTE": {
            return {
                ...state,
                creditNotes: [],
            }
        }
        case "CLEAR_SEARCHED_ORDERS": {
            return {
                ...state,
                orderReceptionsFilters: null,
                data: [],
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

/** Selector **/

export function getOrderReception(state, id) {
    return state.orderReception.data.find(order => order.orderNumber === parseFloat(id))
}

export function getOrderReceptionById(state, id) {
    return state.orderReception.data.find(order => order.objectId === id)
}
