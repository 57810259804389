import React, { useMemo } from "react"
import { Box, Typography, Stack } from "@mui/material"
import dayjs from "dayjs"
import { makeStyles } from "@mui/styles"
import { filterPSESubstatuses } from "../../../utils/productionStepExecution"
import NumberedProgressBar from "../../../components/common/NumberedProgressBar"

const useStyles = makeStyles({
	container: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	times: {
		fontSize: 12,
		lineHeight: "18px"
	},
	greyText: {
		composes: "$times",
		color: "#9B9B9B"
	},
	theoreticalTimeStrikeThrough: {
		composes: "$times",
		textDecoration: "line-through",
		color: "#9B9B9B"
	},
	endTime: {
		composes: "$times",
		color: "#9B9B9B"
	},
	completionLabel: {
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "16px",
		color: "#262626"
	},
	completionTime: {
		color: "#7C7C7C",
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "16px"
	}
})

const PSEProgressBar = ({
	productionStepExecution,
	currentSubStatus,
	isDone,
	times = {}
}) => {

	const classes = useStyles()

	const { theoreticalStartTime, theoreticalEndTime, startTime, endTime } = times

	const completionTime = useMemo(() => {
		if (times.startTime && times.endTime) {
			return dayjs(times.endTime).diff(dayjs(times.startTime), "minutes")
		}
		if (times.theoreticalStartTime && times.theoreticalEndTime) {
			return dayjs(times.theoreticalEndTime).diff(dayjs(times.theoreticalStartTime), "minutes")
		}
		return undefined
	}, [times])

	return (
		<div>
			<Box marginBottom={3}>
				<Typography>
					<span className={classes.completionLabel}>Déroulé de l'étape</span>
					{!isNaN(completionTime) && <span className={classes.completionTime}> ( ~ {completionTime} minutes)</span>}
				</Typography>
			</Box>
			<Box className={classes.container}>
				<Stack direction="row" justifyContent="space-between" width={"100%"}>
					<Stack>
						<span className={classes.greyText}>Début</span>
						{theoreticalStartTime && <span className={startTime ? classes.theoreticalTimeStrikeThrough : classes.greyText}>{dayjs(theoreticalStartTime).format("HH:mm")}</span>}
						{startTime && <span className={classes.times}>{dayjs(startTime).format("HH:mm")}</span>}
					</Stack>
					<NumberedProgressBar
						withSidelines
						statusAndLabels={filterPSESubstatuses(productionStepExecution)}
						currentStatus={currentSubStatus}
						isCompleted={isDone}
					/>
					<Stack>
						<span className={classes.greyText}>Fin</span>
						{theoreticalEndTime && <span className={endTime ? classes.theoreticalTimeStrikeThrough : classes.greyText}>{dayjs(theoreticalEndTime).format("HH:mm")}</span>}
						{endTime && <span className={classes.times}>{dayjs(endTime).format("HH:mm")}</span>}
					</Stack>
				</Stack>
			</Box >
		</div>
	)
}

export default PSEProgressBar