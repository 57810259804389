import React from "react"
import { makeStyles } from "@mui/styles"
import { creditNotesType } from "../../../utils/creditNoteUtils"
import CreditNoteBloc from "./CreditNoteBloc"
import CreditNoteLotInfos from "./CreditNoteLotInfos"
import CreditNoteTitle from "./CreditNoteTitle"
import CreditNoteQuantity from "./CreditNoteQuantity"
import CreditNoteComment from "./CreditNoteComment"
import CreditNoteAmount from "./CreditNoteAmount"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import moment from "moment"
import CreditNoteReasons from "./CreditNoteReasons"
import SingleReceptionHeaderTitle from "../Reception/SingleReceptionHeaderTitle"

const styles = makeStyles({
	contentBloc:{
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	subContentBloc: {
		margin: "30px auto"
	}
})

const CreditNote = (props) => {
	const {
		data
	} = props

	const classes = styles()
	const renderFirstBloc = (type) => {
		switch (type){
			case creditNotesType.LOT:
			case creditNotesType.BROKEN:
				return (
					<GenericTopBarHeader
						leftAction={<GenericHeaderReturnButton handleClick={data.header.goBack}/>}
						title={<SingleReceptionHeaderTitle headings={data.header.headings} />}
						rightAction={data.header.actions}
					/>
				)
			case creditNotesType.DELIVERY:
				return (
					<GenericTopBarHeader
						leftAction={<GenericHeaderReturnButton handleClick={data.header.goBack}/>}
						title={<SingleReceptionHeaderTitle headings={data.header.headings} />}
						rightAction={data.header.actions}
					/>
				)
			default:
				return
		}
		
	}
	
	const renderSecondBloc = (type) => {
		switch (type){
			case creditNotesType.LOT:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteLotInfos
								title="Avoir sur un lot"
								lotNumber={data.lot.lotNumber}
								dlc={moment(data.lot.dlc).format("DD/MM/YYYY")}
							/>
						}
					/>
				)
			case creditNotesType.DELIVERY:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteTitle
								title="Avoir pour refus de toute la livraison"
							/>
						}
					/>
				)
			case creditNotesType.BROKEN:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteTitle
								title="Déclaration de casse"
							/>
						}
					/>
				)
			default:
				return
		}
	}
	
	const renderThirdBloc = (type) => {
		switch (type){
			case creditNotesType.LOT:
			case creditNotesType.DELIVERY:
			case creditNotesType.BROKEN:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteReasons
								items={data.items}
								callback={data.callback}
							/>
						}
					/>
				)
			default:
				return
		}
	}
	
	const renderFourthBloc = (type) => {
		switch (type){
			case creditNotesType.LOT:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteQuantity
								maxQuantity={data.lot.quantity}
								isLotCreditNote={true}
								unit={data.unit}
								callback={data.callback}
							/>
						}
					/>
				)
			case creditNotesType.BROKEN:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteQuantity
								maxQuantity={data.quantityLeft}
								isLotCreditNote={false}
								unit={data.unit}
								callback={data.callback}
							/>
						}
					/>
				)
			case creditNotesType.DELIVERY:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteComment
								imageName={data.imageName}
								callback={data.callback}
							/>
						}
					/>
				)
			default:
				return
		}
	}
	
	const renderFifthBloc = (type) => {
		switch (type){
			case creditNotesType.LOT:
			case creditNotesType.BROKEN:
				return (
					<CreditNoteBloc
						data={
							<CreditNoteComment
								imageName={data.imageName}
								callback={data.callback}
							/>
						}
					/>
				)
			case creditNotesType.DELIVERY: 
				return (
					<CreditNoteBloc
						data={
							<CreditNoteAmount
								amount={data.amount}
							/>
						}
					/>
				)
			default:
				return
		}
	}
	
	return (
		<div>
			{renderFirstBloc(data.type)}
			<div className={classes.contentBloc}>
				<div className={classes.subContentBloc}>
					{renderSecondBloc(data.type)}
				</div>
				<div className={classes.subContentBloc}>
					{renderThirdBloc(data.type)}
				</div>
				<div className={classes.subContentBloc}>
					{renderFourthBloc(data.type)}
				</div>
				<div className={classes.subContentBloc}>
					{renderFifthBloc(data.type)}
				</div>
			</div>
		</div>
	)
}

export default CreditNote