import React, { useState } from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"
import TextField from "@mui/material/TextField"

const useStyles = makeStyles({
    container: {
        minWidth: 700,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "nowrap"
    },
    subContentBloc: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    blocTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: COLORS.DARK_GREY,
        textTransform: "uppercase"
    },
    quantity: {
        fontWeight: 400,
        fontSize: 50,
        color: COLORS.BLACK,
        margin: "10px 0",
        maxWidth: 220
    },
    label: {
        fontWeight: 400,
        color: COLORS.GREY_BORDER,
        fontSize: 14,
        marginTop: 10
    },
})

const CreditNoteQuantity = (props) => {
    const {
        maxQuantity,
        isLotCreditNote,
        callback,
        unit
    } = props

    const classes = useStyles()
    const [quantity, setQuantity] = useState(null)

    const handleQuantityChange = (e) => {
        const value = e.target.value
    
        if (value !== "" ) {
            if (parseFloat(value) > 0 && parseFloat(value) <= maxQuantity) {
                setQuantity(parseFloat(value))
                callback({
                    method: "UPDATE_QUANTITY",
                    data: {
                        quantity: parseFloat(value)
                    }
                })

            }

        }
        else {
            setQuantity(value)
            callback({
                method: "UPDATE_QUANTITY",
                data: {
                    quantity: value
                }
            })
        }
    }

    return (
        <div className={classes.container}>
            {
                isLotCreditNote &&
                    <div className={classes.subContentBloc}>
                        <Typography className={classes.blocTitle}>
                            quantité du lot 
                        </Typography>
                        <span className={classes.quantity}>
                            {maxQuantity}
                        </span>
                        <Typography className={classes.label}>
                            {unit}
                        </Typography>
                    </div>
            }
            <div className={classes.subContentBloc}>
                <Typography className={classes.blocTitle}>
                    { isLotCreditNote ? "quantité concernée par l'avoir" : "quantité à déclarer" }
                </Typography>
                <TextField
                    name="quantity"
                    type="number"
                    variant="standard"
                    value={quantity}
                    onChange={handleQuantityChange}
                    autoFocus
                    placeholder="0"
                    inputProps={{
                        className: classes.quantity,
                        style: { textAlign: "center" },
                        step: 1,
                        min: 1,
                    }}
                />
                <Typography className={classes.label}>
                    {unit}
                </Typography>
            </div>
        </div>
    )
}

export default CreditNoteQuantity