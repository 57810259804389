import React, { useState } from "react"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import LotFilterHeaderTitle from "../../components/Order/Lot/LotFilterHeaderTitle"
import { IconButton } from "@mui/material"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem } from "@mui/material"
import LotFilter from "./LotFilter"
import ExtractLotModal from "./ExtractLotModal"
import { LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"

const LotOutput = () => {


  const [anchorEl, setAnchorEl] = useState(null)
  const [extractInventory, setExtractInventory] = useState(false)

  const open = Boolean(anchorEl)
  const id = open ? "menu-popover" : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setExtractInventory(false)
  }


  const openExtractModal = () => {
    setExtractInventory(true)
    setAnchorEl(null)
  }
  return (
    <>
      <GenericTopBarHeader
        title={<LotFilterHeaderTitle mode={"outputColor"} />}
        rightAction={
          <span>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="large">
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={id}
              open={Boolean(open)}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              {
                <MenuItem
                  onClick={() => openExtractModal()}
                >
                  Extraire les sorties
                </MenuItem>

              }
            </Menu>
          </span>
        }
      />
      <LotFilter />
      <ExtractLotModal
        open={extractInventory}
        onClose={handleClose}
        mode={LOT_OUTPUT_MODE}
      />
    </>
  )
}

export default LotOutput