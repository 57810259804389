import { orderBy } from "lodash"
import { KFC_BRANDS } from "../utils"

export const MANAGEMENT_MODES = [
  {
      value: "manual",
      label: "Mise à la carte manuelle",
  },
  {
      value: "automatic",
      label: "Mise à la carte automatique",
  },
  {
      value: "none",
      label: "Pas de mise à la carte (gestion par l'entrepôt)",
  },
]

export const MANAGEMENT_MODES_ENUM = {
    manual: MANAGEMENT_MODES[0].value,
    automatic: MANAGEMENT_MODES[1].value,
    none: MANAGEMENT_MODES[2].value,
}

export const PRONOUNS = [
    {
        value: "Ce",
        label: "Ce"
    },
    {
        value: "Cet",
        label: "Cet"
    },
    {
        value: "Cette",
        label: "Cette"
    }
]

export const formatManagementMode = (managementMode) => {
    if (managementMode === MANAGEMENT_MODES_ENUM.manual) {
        return MANAGEMENT_MODES[0].label
    } else if (managementMode === MANAGEMENT_MODES_ENUM.automatic) {
        return MANAGEMENT_MODES[1].label
    } else if (managementMode === MANAGEMENT_MODES_ENUM.none) {
        return MANAGEMENT_MODES[2].label
    } 
}

export const WITH_MANAGEMENT_MODE_FILTER = { managementMode: [MANAGEMENT_MODES_ENUM.automatic, MANAGEMENT_MODES_ENUM.manual]}

/**
 * get all product types options except "ALL"
 * @param {*} productTypeOptions 
 * @returns 
 */
export const getDefaultProductOptions = (productTypeOptions = []) => productTypeOptions.filter(productType => productType.value !== "ALL")

/**
 * get the list of product types except "ALL"
 * @param {*} productTypeOptions 
 * @returns 
 */
export const getProductTypesByOptions = (productTypeOptions = [], managementModes) => {
    const productTypes = []

    for (const option of productTypeOptions) {
        if (option.value !== "ALL") {
            // by default get all
            if (managementModes) {
                if (managementModes.includes(option.managementMode)) {
                    productTypes.push(option.value)
                }
            } else {
                productTypes.push(option.value)
            }
        }
    }

    return productTypes
}

/**
 * values from form
 * @param {*} values 
 * @returns 
 */
export const formProductTypesToSave = (values) => {
    const newValues = { ...values }

    if (newValues.brands && newValues.brands.includes("ALL")) {
        newValues.brands = KFC_BRANDS.map(brand => brand.name)
    }

    if (newValues.uniqueCode && Array.isArray(newValues.uniqueCode)) {
        newValues.uniqueCode = newValues.uniqueCode.join("")
    }

    return newValues
}

/**
 * 
 * @param {*} product recipe or subcontractor product in json or format
 * @param {*} productTypeOptions 
 * @returns 
 */
export const getProductTypeLabelByProduct = (product, productTypeOptions) => {
    const productType = productTypeOptions.find(option => {
        if (product) {
            const type = product.get ? product.get("type") : product?.type
            return option.value === type
        }
        return false
    })

    if (productType) {
        return productType.label
    }

    return ""
}

export const getOrderedProductTypes = (productTypeOptions) => {
    return orderBy(productTypeOptions, ["order"])
}