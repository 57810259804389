import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@mui/styles"
import { Box, Typography } from "@mui/material"
import { Formik, Form } from "formik"
import { getProductionStepExecutionLotsSelector, getProductionStepExecutionSecondaryDLCsSelector } from "../../../reducers/Production/ProductionSteps"
import ProductionStepExecutionLotsTable from "./ProductionStepExecutionLotsTable"
import { ProductionStepExecutionsLotsSelectionSchema } from "../../../utils/yupValidators"

export const productionStepExecutionLotsSelectionFormId = "productionStepExecutionLotsSelectionForm"
import { StyledSubStatusTitle } from "../styledComponents"

const useStyles = makeStyles({
	weightLabel: {
		color: "#7C7C7C"
	},
	error: {
		color: "#F44259",
		fontSize: 14,
		fontWeight: 400,
		lineHeight: "22px"
	}
})

const ProductionStepExecutionLotsSelection = ({ productionStepExecution, handleSubmit, priorStepData }) => {

	const classes = useStyles()

	const lotsBySupplierItems = useSelector(getProductionStepExecutionLotsSelector)
	const secondaryDLCsBySupplierItems = useSelector(getProductionStepExecutionSecondaryDLCsSelector)

	if (!productionStepExecution) {
		return null
	}

	return (
		<div>
			<StyledSubStatusTitle>
				Indiquez les lots utilisés pour cette production.
			</StyledSubStatusTitle>
			<Formik
				onSubmit={handleSubmit}
				validationSchema={ProductionStepExecutionsLotsSelectionSchema}
				initialValues={{
					secondaryDLCs: [],
					lots: []
				}}
				validateOnBlur={false}
				validateOnChange={false}
			>
				{({ values, errors, setFieldValue }) => {
					return (
						<Form id={productionStepExecutionLotsSelectionFormId}>
							<ProductionStepExecutionLotsTable
								productionStepExecution={productionStepExecution}
								priorStepData={priorStepData}
								values={values}
								errors={errors}
								setFieldValue={setFieldValue}
								secondaryDLCsBySupplierItems={secondaryDLCsBySupplierItems}
								lotsBySupplierItems={lotsBySupplierItems}
								editable
							/>
							<Box>
								{errors.lots && <Typography className={classes.error}>{errors.lots}</Typography>}
							</Box>
						</Form>
					)
				}}
			</Formik>
		</div>
	)

}

export default ProductionStepExecutionLotsSelection