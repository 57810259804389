import React from "react"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/material"

const useStyles = makeStyles({
  root: {
    borderTop: "1px solid #E6E6E6",
    background: "#FFF",
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "10px",
    maxHeight: "fit-content",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100vw"
  }
})

const ActionFooter = ({ rightAction }) => {

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {rightAction}
    </Box>
  )

}

export default ActionFooter
