import React, { PureComponent } from "react"
import withStyles from "@mui/styles/withStyles"
import dayjs from "dayjs"
import { connect } from "react-redux"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Paper from "@mui/material/Paper"
import Modal from "@mui/material/Modal"
import { withCookies } from "react-cookie"
import AppBar from "@mui/material/AppBar"
import CircularProgress from "@mui/material/CircularProgress"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { grey } from "@mui/material/colors"
import Recipe from "./Recipe"
import { cloneDeep } from "lodash"
import CommentsDrawer from "../../components/Comments/CommentsDrawer"
import ExportProductsResultModal from "../../components/ExportProductsResultModal"
import {
    closeRecipeSnackBar,
    closeEtiquettableSnackBar,
    editNews,
    synchronizeRecipeToAndFromEtiquettable,
    downloadRecipeProduction,
    saveProductResume,
    showRecipeTab,
    flushExportRecipesErrors,
    updateRecipeSections,
    createReusableSection,
    updateRecipeDetails,
    showRecipeList,
    checkProductInCard,
    addRecipeComment
} from "../../actions/Recipe/recipes"
import {
    asyncPrintLabel
} from "../../actions/LabelProduct/LabelProduct"
import General from "../../components/Recipe/General"
import Details from "../../components/Recipe/Details"
import Header from "../../components/Recipe/Header"
import ProductInCardModal from "../../components/Recipe/ProductInCardModal"
import ProductPageHeader from "../Recipe/ProductPageHeader"
import ProductionSteps from "../../components/Recipe/ProductionSteps/ProductionSteps"
import SnackbarsChain from "../../components/SnackbarsChain"
import { displaySidebar } from "../../actions/Utils/app"
import CloudinaryImage from "../../components/Image/CloudinaryImage"

const styles = {
    mainContainer: {
        display: "block",
        overflow: "auto"
    },
    tabs: {
        display: "flex",
        borderRadius: 0,
        borderLeft: "none",
        borderRight: "none",
        marginBottom: 1
    },
    appBar: {
        backgroundColor: "white"
    },
    appBarMenuClosed: {
        composes: "$appBar",
        width: "calc(100% - 54.4px)"
    },
    appBarMenuOpenned: {
        composes: "$appBar",
        width: "calc(100% - 239px)"
    },
    appBarRoot: {
        borderBottom: `1px solid ${grey[200]}`,
        boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)"
    },
    title: {
        flexGrow: 1,
        marginLeft: 20
    },
    cancel: {
        marginRight: 15
    },
    recipeImage: {
        height: 64,
        marginRight: 15
    },
    toolbarGutters: {
        paddingLeft: 0
    },
    circularProgress: {
        margin: "auto",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 54.4 // size of left menu when it's close
    },
    backIcon: {
        paddingLeft: 25
    },
    width100: {
        "& .MuiButtonBase-root": {
            width: 162,
        },
        width: "100%"
    }
}

class RecipeNew extends PureComponent {
    constructor(props) {
        super(props)
        let tabValue = 0

        if (props.tab) {
            if ("recipe" === props.tab) {
                tabValue = 1
            } else if ("productionSteps" === props.tab) {
                tabValue = 2
            } else if ("details" === props.tab) {
                tabValue = 3
            }
        }

        this.state = {
            tabValue: tabValue,
            recipeEditing: false,
            selectedRecipe: props.jsonRecipe,
            selectedRecipeSnackbar: props.recipeSnackBar,
            selectedEtiquettableSnackbar: props.etiquettableSnackBar,
            openModalProductInCard: false,
            isProductionStepsEdition: false,
            snackbarsList: [],
            commentsDrawerShown: false
        }
        this.submitForm = null
        this.resetForm = null
        this.actionModal = null
    }

    componentDidMount() {
        document.getElementById("recipe-container")?.scrollIntoView()
    }

    componentDidUpdate() {
        if (this.props.jsonRecipe && this.props.jsonRecipe !== this.state.selectedRecipe) {
            this.setState({ selectedRecipe: this.props.jsonRecipe })
        }
        const needUpdateRecipeSnackBar = this.props.recipeSnackBar && (this.props.recipeSnackBar.type !== this.state.selectedRecipeSnackbar.type || this.props.recipeSnackBar.message !== this.state.selectedRecipeSnackbar.message)
        const needUpdateEtiquettableSnackBar = this.props.etiquettableSnackBar && (this.props.etiquettableSnackBar.type !== this.state.selectedEtiquettableSnackbar.type || this.props.etiquettableSnackBar.message !== this.state.selectedEtiquettableSnackbar.message)
        let snackbarsListNeedUpdate = false
        if (needUpdateRecipeSnackBar) {
            this.setState({ selectedRecipeSnackbar: this.props.recipeSnackBar })
            snackbarsListNeedUpdate = true
        }
        if (needUpdateEtiquettableSnackBar) {
            this.setState({ selectedEtiquettableSnackbar: this.props.etiquettableSnackBar })
            snackbarsListNeedUpdate = true
        }
        if (snackbarsListNeedUpdate) {
            this._updateSnackbarList()
        }
    }

    _updateSnackbarList = () => {
        const etiquettableSnackBar = { ...this.props.etiquettableSnackBar, label: "etiquettable" }
        const recipeSnackBar = { ...this.props.recipeSnackBar, label: "recipe" }
        const newSnackbarsList = [recipeSnackBar, etiquettableSnackBar]
            .filter((snackbar) => snackbar.open)
            .sort(this._sortSnackbarByAppearanceDate)
        this.setState({ snackbarsList: newSnackbarsList })
    }

    _sortSnackbarByAppearanceDate = (a, b) => {
        if (!a.triggerDate || !b.triggerDate) return 0
        return a.triggerDate.isBefore(b.triggerDate) ? -1 : 1
    }

    _onTabChange = (_event, tabValue) => {
        const { showRecipeTab, recipeId } = this.props
        let tabName = "general"

        switch (tabValue) {
            case 1:
                tabName = "recipe"
                break
            case 2:
                tabName = "productionSteps"
                break
            case 3:
                tabName = "details"
                break
            case 0:
            default:
                tabName = "general"
        }

        showRecipeTab(tabName, recipeId)
        this.setState({ tabValue })
    }

    _closeSnackbar = () => {
        const { recipeSnackBar, closeRecipeSnackBar } = this.props
        closeRecipeSnackBar(recipeSnackBar.type)
    }

    _onEditRecipe = async () => {
        const check = await this.checkIsProductInCard()

        this.props.displaySidebar(false)
        if (check) {
            this.showModalProductInCard(() => this.setState({ recipeEditing: true }))
            return
        }

        this.setState({ recipeEditing: true })
    }

    _onStopEditRecipe = () => {
        this.props.displaySidebar(true)
        this.setState({ recipeEditing: false })
    }

    _bindSubmitAndResetForm = (submitForm, resetForm = null) => {
        this.submitForm = submitForm
        this.resetForm = resetForm
    }

    _handleSubmitForm = (event) => {
        if (this.submitForm) {
            this.submitForm(event)
        }
    }

    _handleResetForm = (event) => {
        if (this.resetForm) {
            this.resetForm(event)
        }

        this._onStopEditRecipe()
    }

    downloadProd = (values) => {
        const { jsonRecipe } = this.props

        const id = jsonRecipe && jsonRecipe.objectId
            ? jsonRecipe.objectId
            : ""
        const name = jsonRecipe && jsonRecipe.name
            ? jsonRecipe.name
            : ""
        downloadRecipeProduction(id, name, values)
    }

    checkIsProductInCard = async () => {
        const { checkProductInCard, jsonRecipe, cookies } = this.props

        if (jsonRecipe.status === "6") {
            const cookie = cookies.get(`Recipe-${jsonRecipe.objectId}`)

            if (!cookie) {
                await checkProductInCard(jsonRecipe.objectId)
                let expires = new Date()
                expires.setTime(expires.getTime() + (5 * 60 * 1000))
                cookies.set(`Recipe-${jsonRecipe.objectId}`, true, { path: "/", expires })
                return true
            }
        }

        return false
    }

    showModalProductInCard = (callback) => {
        const { productItems } = this.props

        this.actionModal = callback
        if (productItems.length) this.setState({ openModalProductInCard: true })
    }

    onBack = () => {
        const { showRecipeList, displaySidebar } = this.props
        displaySidebar(true)
        showRecipeList()
    }

    toggleProductionStepsIsEdition = () => {
        this.props.displaySidebar(this.state.isProductionStepsEdition)
        this.setState({ isProductionStepsEdition: !this.state.isProductionStepsEdition })
    }

    onEditProductionSteps = () => {
        this.toggleProductionStepsIsEdition()
    }

    onSaveProductionSteps = async (values) => {
        const { parseRecipe, updateRecipeSections } = this.props
        const isProductionSteps = true

        await updateRecipeSections(cloneDeep(values), parseRecipe, isProductionSteps)

        this.toggleProductionStepsIsEdition()
    }

    onCancelProductionSteps = () => {
        this.toggleProductionStepsIsEdition()
    }

    handleAddComment = async (newComment) => {
        const { addRecipeComment, parseRecipe } = this.props
        await addRecipeComment({ ...newComment, creation_dt: dayjs.tz().valueOf() }, parseRecipe)
    }

    onToggleCommentsDrawer = () => this.setState({ commentsDrawerShown: !this.state.commentsDrawerShown })

    _renderTabs = () => {
        const { tabValue, recipeEditing, selectedRecipe } = this.state
        let {
            classes,
            genericSections,
            parseRecipe,
            jsonRecipe,
            updateRecipeSections,
            createReusableSection,
            updateRecipeDetails,
            editNews,
            synchronizeRecipeToAndFromEtiquettable,
            productsTags,
            saveProductResume,
            asyncPrintLabel,
            packagings,
            chefs
        } = this.props

        jsonRecipe = jsonRecipe ? jsonRecipe : selectedRecipe

        return jsonRecipe
            ?
            <>
                {
                    0 === tabValue &&
                    <General
                        jsonRecipe={jsonRecipe}
                        parseRecipe={parseRecipe}
                        onEditNews={(value) => editNews(jsonRecipe.objectId, value)}
                        synchronizeARecipeToAndFromEtiquettable={() => synchronizeRecipeToAndFromEtiquettable(jsonRecipe.objectId, true, jsonRecipe.status === "6")}
                        asyncPrintLabel={asyncPrintLabel}
                        downloadProd={this.downloadProd}
                        productsTags={productsTags}
                        onSaveProductResume={(values) => saveProductResume(jsonRecipe.objectId, values)}
                        chefs={chefs}
                        checkProductInCard={this.checkIsProductInCard}
                        showModalProductInCard={this.showModalProductInCard}
                    />
                }
                {
                    1 === tabValue &&
                    <Recipe
                        onEdit={this._onEditRecipe}
                        onStopEdit={this._onStopEditRecipe}
                        parseRecipe={parseRecipe}
                        genericSections={genericSections}
                        bindSubmitAndResetForm={this._bindSubmitAndResetForm}
                        updateRecipeSections={updateRecipeSections}
                        createReusableSection={createReusableSection}
                        editing={recipeEditing}
                    />
                }
                {
                    2 === tabValue &&
                    <ProductionSteps toggleEditForm={this.onEditProductionSteps} recipe={parseRecipe} />
                }
                {
                    3 === tabValue &&
                    <Details
                        onEdit={this._onEditRecipe}
                        onStopEdit={this._onStopEditRecipe}
                        parseRecipe={parseRecipe}
                        bindSubmitAndResetForm={this._bindSubmitAndResetForm}
                        updateRecipeDetails={updateRecipeDetails}
                        editing={recipeEditing}
                        packagings={packagings}
                    />
                }
            </>
            :
            <CircularProgress
                size={80}
                className={classes.circularProgress}
            />
    }

    render() {
        const { tabValue, recipeEditing, selectedRecipe, openModalProductInCard, isProductionStepsEdition, snackbarsList } = this.state
        const {
            classes,
            jsonRecipe,
            isMenuOpen,
            exportRecipesErrors,
            flushExportRecipesErrors,
            productItems,
            parseRecipe,
            genericSections
        } = this.props

        const image = selectedRecipe && selectedRecipe.defaultValues ? selectedRecipe.defaultValues.appImage : null

        // production steps edition
        if (isProductionStepsEdition) {
            return (
                <ProductionSteps
                    recipe={parseRecipe}
                    isEdition
                    onSave={this.onSaveProductionSteps}
                    onCancel={this.onCancelProductionSteps}
                    genericSections={genericSections}
                />
            )
        }

        return (
            <div className={classes.container} id="recipe-container">
                <ProductPageHeader subtitle={jsonRecipe.commercialName} onBack={this.onBack} withBackButton />
                {
                    !recipeEditing && (
                        <>
                            <Header image={image} jsonRecipe={selectedRecipe} onToggleDrawer={this.onToggleCommentsDrawer} />
                            <Paper className={classes.tabs}>
                                <Tabs
                                    className={classes.width100}
                                    value={tabValue !== null && tabValue !== undefined ? tabValue : false}
                                    onChange={this._onTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Général" value={0} />
                                    <Tab label="Recette" value={1} />
                                    <Tab label="Étapes de production" value={2} />
                                    <Tab label="Détails" value={3} />
                                </Tabs>
                            </Paper>
                        </>
                    )
                }
                {
                    recipeEditing && (
                        <AppBar className={isMenuOpen ? classes.appBarMenuOpenned : classes.appBarMenuClosed} classes={{
                            root: classes.appBarRoot
                        }}>
                            <Toolbar classes={{
                                gutters: classes.toolbarGutters
                            }}>
                                {
                                    image &&
                                    <CloudinaryImage
                                        containerClassName={classes.recipeImage}
                                        imageId={image && image.publicId}
                                        customQuality="auto"
                                    />
                                }
                                <Typography variant="h6" color="textPrimary" className={classes.title}>
                                    {jsonRecipe.name}
                                </Typography>
                                <Button
                                    color="primary"
                                    className={classes.cancel}
                                    onClick={this._handleResetForm}
                                >
                                    ANNULER
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this._handleSubmitForm}
                                >
                                    ENREGISTRER
                                </Button>
                            </Toolbar>
                        </AppBar>
                    )
                }
                {this._renderTabs()}
                <ProductInCardModal
                    productItems={productItems}
                    action={this.actionModal}
                    open={openModalProductInCard}
                    onClose={() => this.setState({ openModalProductInCard: false })}
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={!!(exportRecipesErrors && exportRecipesErrors.length)}
                    onClose={flushExportRecipesErrors}
                >
                    <ExportProductsResultModal
                        errors={exportRecipesErrors}
                        title="Erreur lors de l'export des produits internes"
                        subTitle="Une ou plusieurs erreurs sont survenus lors de l'export des produits internes : "
                        tableTitle="Produit Interne"
                        isFem={true}
                        searchFieldsLabel="Rechercher un produit interne"
                        isRecipes={true}
                        closeModal={flushExportRecipesErrors} />
                </Modal>
                <SnackbarsChain snackbars={snackbarsList} />
                <CommentsDrawer
                    open={this.state.commentsDrawerShown}
                    onClose={this.onToggleCommentsDrawer}
                    comments={Array.isArray(parseRecipe.get("comments")) && parseRecipe.get("comments")}
                    onAddComment={this.handleAddComment}
                    collection="recipe"
                    menuShown
                />
            </div>

        )
    }
}

RecipeNew = withStyles(styles)(RecipeNew)

export default connect((state, props) => {
    const recipe = state.recipes.selectedRecipe
    const { params: { tab, id } } = props

    return {
        jsonRecipe: recipe ? recipe.toJSON() : null,
        productsTags: state.recipes.productsTags,
        parseRecipe: recipe,
        recipeSnackBar: state.recipes.recipeSnackBar,
        etiquettableSnackBar: state.recipes.etiquettableSnackBar,
        packagings: state.recipes.packagings,
        genericSections: state.recipes.sections,
        exportRecipesErrors: state.recipes.exportRecipesErrors,
        chefs: state.recipes.chefs,
        isMenuOpen: state.app.menuIsOpen,
        tab: tab,
        recipeId: id,
        productItems: state.recipes.productItems
    }
}, {
    showRecipeTab,
    closeRecipeSnackBar,
    closeEtiquettableSnackBar,
    editNews,
    synchronizeRecipeToAndFromEtiquettable,
    asyncPrintLabel,
    downloadRecipeProduction,
    updateRecipeSections,
    createReusableSection,
    updateRecipeDetails,
    saveProductResume,
    flushExportRecipesErrors,
    showRecipeList,
    checkProductInCard,
    displaySidebar,
    addRecipeComment
})(withCookies(RecipeNew))
