import { isNumber } from "lodash"
import moment from "moment"

import { unitSelectValues } from "./supplierItemUtils"
import { roundNumber } from "../utils"
import dayjs from "dayjs"

export const STORAGE_ORDER_RECEPTION_KEY = "Orders/OrderReception/id"

export const getParamsFromPath = (pathname) => {
	const elems = pathname.split("/")
	if (pathname.startsWith("/orderReceptions/") && (elems.length === 4 || elems.length === 5 || elems.length === 6 || elems.length === 7)) {
		return {
			site: elems[2],
			date: moment.utc(parseFloat(elems[3])).startOf("day").valueOf()
		}
	}

	return null
}

export const generateStockUnitLabel = (item) => {
	let label = ""

	if (item.units && item.units.stock && item.units.stock.unity && item.units.stock.unity) {
		let showQuantity = true

		if ((item.units.stock.unity.name === "" || item.units.stock.unity.name === null) && item.units.stock.unity.quantity === 1) {
			showQuantity = false
		}

		label = `${item.units.stock.unity.name} ${showQuantity ? item.units.stock.unity.quantity : ""} ${unitSelectValues.find(el => el.value === item.units.stock.unity.unity).label}${item.units.stock.unity.quantity > 1 ? "s" : ""}`
	}
	return label
}

export const generateBillingUnitLabel = (item) => {
	let label = ""

	if (item.units && item.units.billing && item.units.billing.unity) {
		let showQuantity = true

		if ((item.units.billing.unity.name === "" || item.units.billing.unity.name === null) && item.units.billing.unity.quantity === 1) {
			showQuantity = false
		}

		label = `${item.units.billing.unity.name} ${showQuantity ? item.units.billing.unity.quantity : ""} ${unitSelectValues.find(el => el.value === item.units.billing.unity.unity).label}${item.units.billing.unity.quantity > 1 ? "s" : ""}`
	}
	return label
}

export const generateStockUnitWeightLabel = (item) => {
	let label = ""

	if (item.units && item.units.stock && item.units.stock.unity && item.units.stock.unity) {
		let showQuantity = true

		if ((item.units.stock.unity.name === "" || item.units.stock.unity.name === null) && item.units.stock.unity.quantity === 1) {
			showQuantity = false
		}

		label = `${item.units.stock.weight} ${item.units.stock.weight > 1 ? "Kgs/" : "Kg/"} ${item.units.stock.unity.name} ${showQuantity ? item.units.stock.unity.quantity : ""} ${unitSelectValues.find(el => el.value === item.units.stock.unity.unity).label}${item.units.stock.unity.quantity > 1 ? "s" : ""}`
	}
	return label
}

export const generateReceptionLabel = (site, date) => {
	return `des commandes - ${site}, ${moment(date).format("DD/MM/YY")}`
}

export const computeReceivedArticleWeight = (supplierItems) => {
	let result = 0

	for (const supplierItem of supplierItems) {
		if (supplierItem.quantities?.reception && supplierItem.supplierItem.units) {
			result += supplierItem.quantities.reception * supplierItem.supplierItem.units.stock.weight
		}
	}
	return roundNumber(result, 2)
}

export const computeReceptionDeliveryDelay = (order) => {
	if (!order.expectedDeliveryDate || order.expectedDeliveryDate === order.receptionDate) {
		return "À jour"
	}
	if (order.expectedDeliveryDate > order.receptionDate) {
		return "En avance"
	}
	if (order.expectedDeliveryDate < order.receptionDate) {
		return "En retard"
	}
}

export const computeOrderSupplierItemQuantity = orderSupplierItem => {
	if (
		(orderSupplierItem.quantities && orderSupplierItem.quantities.order)
		&& (orderSupplierItem.supplierItem && orderSupplierItem.supplierItem.units && orderSupplierItem.supplierItem.units.order.weight)
		&& (orderSupplierItem.supplierItem && orderSupplierItem.supplierItem.units && orderSupplierItem.supplierItem.units.stock.weight)
	) {
		return roundNumber(
			orderSupplierItem.quantities.order
			* orderSupplierItem.supplierItem.units.order.weight
			/ orderSupplierItem.supplierItem.units.stock.weight
		)
	}

	return 0
}

export const getDeliveryNoteInitialValues = order => {
	return {
		number: order && order.deliveryNote && order.deliveryNote.number,
		images: (order && order.deliveryNote && order.deliveryNote.images) || [],
		inputDate: order && order.deliveryNote && order.deliveryNote.inputDate
	}
}

export const getOrderShippingFees = order => {
	return (
		order &&
		order.supplier &&
		order.supplier.order &&
		order.supplier.order.shippingFees
	) || 0
}

export const getSupplierItemQuantity = (supplierItem) => {
	let quantity = 0

	if (supplierItem.quantities) {
		// check if is number instead of falsy in order to exclude 0
		if (isNumber(supplierItem.quantities.deliveryNote)) {
			quantity = supplierItem.quantities.deliveryNote
		} else if (isNumber(supplierItem.quantities.reception)) {
			quantity = supplierItem.quantities.reception
		} else {
			quantity = supplierItem.quantities.order || 0
		}
	}

	return quantity
}

export const getSupplierItemBillQuantity = (supplierItem) => {
	let quantity = 0

	if (supplierItem.quantities) {
		// check if is number instead of falsy in order to exclude 0
		if (isNumber(supplierItem.quantities.bill)) {
			quantity = supplierItem.quantities.bill
		}
		return quantity
	}
}

export const getOrderTotalAmount = order => {
	return order && order.supplierItems
		.map(supplierItem => {
			const quantity = getSupplierItemQuantity(supplierItem)

			return quantity * (supplierItem.deliveryNoteUnitPrice || 0)
		})
		.reduce((a, b) => a + b, 0)
}

export const getBillTotalAmount = order => {
	return order && order.supplierItems
		.map(supplierItem => {
			const quantity = getSupplierItemBillQuantity(supplierItem)

			return quantity * (supplierItem.billUnitPrice || 0)
		})
		.reduce((a, b) => a + b, 0)
}

export const getAllOrderCreditNotes = (order) => {
	const creditNotesOrder = order.creditNotes ? [...order.creditNotes] : []

	const creditNotesSupplierItems = []
	const creditNotesLots = []

	for (const supplierItem of order.supplierItems) {
		if (supplierItem.creditNotes) {
			creditNotesSupplierItems.push(...supplierItem.creditNotes)
		}

		for (const lot of supplierItem.lots) {
			if (lot.creditNotes) {
				creditNotesLots.push(...lot.creditNotes)
			}
		}
	}

	return [...creditNotesOrder, ...creditNotesSupplierItems, ...creditNotesLots]
}

export const receptionStatus = [
	{
		label: "Commande à réceptionner",
		value: "TODO"
	},
	{
		label: "Réception en cours",
		value: "IN_PROGRESS"
	},
	{
		label: "Réception clôturée",
		value: "DONE"
	},
	{
		label: "Réception annulée",
		value: "CANCELLED"
	}
]

export const defaultReceptionDate = {
	start: dayjs().subtract(1, "week").toDate(),
	end: dayjs().add(1, "week").startOf("day").toDate()
}
export const getOptionLabel = (option) => {
	return receptionStatus.find((r) => r.value === option).label
}