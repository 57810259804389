import _cloneDeep from "lodash/cloneDeep"
import { batch } from "react-redux"

import { getSites, getSiteById } from "../../parseManager/site/parseSiteManager"
import {
    getParamsFromPath,
    getAllOrderCreditNotes
} from "../../utils/orderReception"
import { actionWithLoader, onEnter, push, sendCreditNoteMail } from "../Utils/utils"
import { getOrderReception, getOrderReceptionById } from "../../reducers/OrderReception/orderReception"
import {
    getOrders,
    createOrderReception,
    getOrder,
    addOrderSupplierItem,
    updateOrderSupplierItem,
    updateOrderShippingFees,
    saveOrderDeliveryNote,
    createOrderLots,
    closeOrderReception,
    startOrderReception,
    initializeDeliveryNoteUnitPrices,
    updateArticleDeliveryNoteQuantityAndPrice,
    updateArticleBillQuantityAndPrice,
    addOrderCreditNote,
    updateOrderCreditNotesAmount,
    updateOrderReceptionStatus,
    updateOrderCreditNotesByTotalAmount,
} from "../../parseManager/orders/parseOrdersManager"
import { loadSuppliersList } from "../Supplier/suppliers"
import { getParseSupplier } from "../../parseManager/suppliers/suppliers/parseSupplierManager"
import { creditNotesReasons, formatCreditNote } from "../../utils/creditNoteUtils"
import {getLotsByOrderSupplierItem} from "../../parseManager/lot/parseLotManager"

export function loadSites() {
    return actionWithLoader(async (dispatch) => {
        const sites = await getSites()

        dispatch({
            type: "SITES_LOADED",
            sites
        })
    })
}

export function loadOrders() {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const path = state.routing.locationBeforeTransitions.pathname
        const data = getParamsFromPath(path)

        if (data && data.site && data.date) {
            const site = await getSiteById(data.site, ["*"], false)
            const orders = await getOrders(site, data.date)

            if (site) {
                batch(() => {
                    dispatch({
                        type: "ORDER_RECEPTION_LOADED",
                        date: data.date,
                        site: site.toJSON(),
                        data: orders,
                        dataTodo: orders.filter(order => order.receptionStatus === "TODO"),
                        dataInProgress: orders.filter(order => order.receptionStatus === "IN_PROGRESS"),
                        dataDone: orders.filter(order => order.receptionStatus === "DONE"),
                    })
                    dispatch(loadSuppliersList())
                })
            }
            else {
                dispatch(showOrderFilter())
            }
            return
        }

        dispatch(showOrderFilter())
    })
}

export function createOrderFromReception(site, date, supplierId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const parseSite = await getSiteById(site.objectId, ["*"], false)
        const supplier = state.suppliers.suppliers.find(el => el.objectId === supplierId)

        await createOrderReception(parseSite, date, supplier)

        dispatch(loadOrders())
    })
}

export const onCloseOrderReception = ({ orderReceptionId, creditNotes, totalAmount, isRefusedReception = false }) => {
    return async (dispatch, getState) => {
        const state = getState()
        const date = state.orderReception.date
        const site = state.orderReception.site

        await closeOrderReception(orderReceptionId, creditNotes, totalAmount)

        // if the reception is refused
        if (isRefusedReception) {
            // the order creditNotesAmount should be equal to the order  totalAmount
            await updateOrderCreditNotesAmount(orderReceptionId, totalAmount)
        } else {
            // the order creditNotesAmount is sum of all creditNotes (array) amount
            await updateOrderCreditNotesByTotalAmount(orderReceptionId)
        }

        const orderReception = state.orderReception.selectedOrderReception

        const orderCreditNotes = []

        if (creditNotes) {
            Array.from(creditNotes).forEach(([, values]) => {
                orderCreditNotes.push(...values)
            })
        }

        if (orderReception.creditNotes && Array.isArray(orderReception.creditNotes)) {
            orderCreditNotes.push(...orderReception.creditNotes)
        }

        orderReception.supplierItems.forEach(item => {
            if (Array.isArray(item.supplierItem.creditNotes)) {
                orderCreditNotes.push(...item.supplierItem.creditNotes)
            }
            if (Array.isArray(item.lots)) {
                item.lots.forEach(lot => {
                    if (Array.isArray(lot.creditNotes)) {
                        orderCreditNotes.push(...lot.creditNotes)
                    }
                })
            }
        })

        const hasCreditNodeToPaid = !!orderCreditNotes.find(d => d.amount > 0)
        if (orderCreditNotes.length > 0 && hasCreditNodeToPaid) {
            setTimeout(() => dispatch(sendCreditNoteMail(orderReceptionId)), 15000)
        }

        batch(() => {
            dispatch({
                type: "ORDER_RECEPTION_CLOSED",
                selectedOrderReception: null
            })
            dispatch(loadOrders())
            dispatch(showOrderList(site.objectId, date))
        })

    }
}

export function clearSelectedOrderReception() {
    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: null
        })
    }
}

export function clearOrderCreditNotes() {
    return dispatch => {
        dispatch({
            type: "CLEAR_ORDER_RECEPTION_CREDIT_NOTE",
        })
    }
}

export function clearSelectedOrderSupplierItem() {
    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_SUPPLIER_ITEM",
            selectedOrderSupplierItem: null
        })
    }
}

export function updateOrderSupplierItemQuantity(orderReception, supplierItemId, values) {
    return async dispatch => {

        const updatedOrderReception = await updateOrderSupplierItem(orderReception.objectId, supplierItemId, "IN_PROGRESS", values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    }
}

export function updateShippingFees(orderReception, shippingFees) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateOrderShippingFees(orderReception.objectId, shippingFees)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function updateArticleChanges(orderReception, article, values) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateArticleDeliveryNoteQuantityAndPrice(orderReception.objectId, article.supplierItem.objectId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function updateArticleThunk(orderReception, article, values) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await updateArticleBillQuantityAndPrice(orderReception.objectId, article.supplierItem.objectId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function resetOrderSupplierItem(orderReception, supplierItemId) {
    return async dispatch => {

        const updatedOrderReception = await updateOrderSupplierItem(orderReception.objectId, supplierItemId, "TODO", null)

        if (updatedOrderReception) {
            batch(() => {
                dispatch({
                    type: "UPDATE_SELECTED_ORDER_RECEPTION",
                    selectedOrderReception: updatedOrderReception
                })
                dispatch(clearSelectedOrderSupplierItem())
                dispatch(showSingleOrder(orderReception.site.objectId, orderReception.receptionDate, orderReception.objectId))
            })
        }
    }
}

export function updateOrderReceptionSupplierItem(orderReception, supplierItem) {
    const updatedOrderReception = _cloneDeep(orderReception)
    const index = orderReception.supplierItems.indexOf(orderReception.supplierItems.find(item => item.supplierItem.reference === supplierItem.reference))
    updatedOrderReception.supplierItems[index] = {
        supplierItem,
        receptionStatus: "DONE"
    }

    return dispatch => {
        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    }
}

export function addSupplierItemToOrderReception(orderId, supplierItemId) {
    return actionWithLoader(async (dispatch) => {
        const order = await addOrderSupplierItem(orderId, supplierItemId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function validateReceptionDate(orderId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()
        const order = getOrderReceptionById(state, orderId)

        if (order.expectedDeliveryDate < order.receptionDate) {
            const creditNote = formatCreditNote({
                reason: creditNotesReasons.LATE_DELIVERY.key,
                amount: 0
            })

            const updatedOrderReception = await addOrderCreditNote(orderId, [creditNote], true)
            const creditNotes = getAllOrderCreditNotes(updatedOrderReception)

            dispatch({
                type: "UPDATE_ORDER_RECEPTION_CREDIT_NOTES",
                selectedOrderReception: updatedOrderReception,
                creditNotes
            })
        }
    })
}

export function startReceptionTreament(orderId) {
    return actionWithLoader(async (dispatch) => {
        const order = await startOrderReception(orderId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function saveDeliveryNote(orderId, values) {
    return actionWithLoader(async (dispatch) => {
        const order = await saveOrderDeliveryNote(orderId, values)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function validateLotReception(orderReception, orderSupplierItem, lots, creditNotes) {
    return actionWithLoader(async (dispatch) => {
        const parseUpdatedOrderReception = await createOrderLots(orderReception.objectId, orderSupplierItem, lots, creditNotes)
        const jsonUpdatedOrderReception = parseUpdatedOrderReception.toJSON()

        batch(() => {
            dispatch({
                type: "UPDATE_SELECTED_ORDER_RECEPTION",
                selectedOrderReception: jsonUpdatedOrderReception
            })
            dispatch(clearSelectedOrderSupplierItem())
            dispatch(showSingleOrder(jsonUpdatedOrderReception.site.objectId, jsonUpdatedOrderReception.receptionDate, jsonUpdatedOrderReception.objectId))
        })
    })
}

export function saveCreditNote(orderReceptionId, values) {
    return actionWithLoader(async (dispatch) => {
        const order = await addOrderCreditNote(orderReceptionId, values, true)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: order
        })
    })
}

export function initializeOrderReception(orderId, orderSupplierItemId) {
    return actionWithLoader(async (dispatch, getState) => {
        const state = getState()

        const updatedSelectedOrder = await updateOrderReceptionStatus(orderId, orderSupplierItemId)
        const creditNotes = getAllOrderCreditNotes(updatedSelectedOrder)

        dispatch({
            type: "ORDER_RECEPTION_SELECTED",
            ...state.orderReception,
            selectedOrderReception: updatedSelectedOrder,
            creditNotes
        })
    })
}

export function completeOrderDeliveryNoteUnitPrices(orderReception) {
    return actionWithLoader(async (dispatch) => {
        const updatedOrderReception = await initializeDeliveryNoteUnitPrices(orderReception.objectId)

        dispatch({
            type: "UPDATE_SELECTED_ORDER_RECEPTION",
            selectedOrderReception: updatedOrderReception
        })
    })
}

export function loadOrderReceptionSite(site) {
    return actionWithLoader(async (dispatch) => {
        dispatch({
            type: "ORDER_RECEPTION_SITE_LOADED",
            site
        })
    })
}

export function onEnterOrderReception(store) {
    return onEnter({
        store,
        actionThunk: loadSites
    })
}

export function onEnterOrderReceptionList(store) {
    return onEnter({
        store,
        actionThunk: loadOrders
    })
}

export function onEnterOrderDeliveryNote(store) {
    return async (nextState) => {
        const { params } = nextState
        store.dispatch(clearSelectedOrderReception())
        if (params) {
            const state = store.getState()

            const date = state.orderReception.date || params.date
            const site = state.orderReception.site || (await getSiteById(params.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            store.dispatch({
                type: "ORDER_DELIVERY_NOTE_LOADED",
                selectedOrderReception: selectedOrder,
                date,
                site
            })
        }
    }
}

export function onEnterSingleOrderReception(store) {
    return async (nextState) => {
        const { params } = nextState


        store.dispatch(clearSelectedOrderReception())
        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))
            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            const orderSupplierItemIdList = selectedOrder.supplierItems.map(orderSupplierItem => orderSupplierItem.supplierItem.objectId)
            const lots = await getLotsByOrderSupplierItem(orderSupplierItemIdList, ["events", "dlc", "lotNumber", "quantity", "orderSupplierItem", "receptionDate", "supplierItem"])
            const parseSupplier = await getParseSupplier(selectedOrder.supplier.objectId)
            const creditNotes = getAllOrderCreditNotes(selectedOrder)

            store.dispatch({
                type: "ORDER_RECEPTION_SELECTED",
                selectedOrderReception: selectedOrder,
                date,
                site,
                parseSupplier,
                creditNotes,
                lots
            })
        }
    }
}

export function onEnterOrderReceptionQuantities(store) {
    return async (nextState) => {
        const { params } = nextState

        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            let selectedOrderSupplierItem = selectedOrder.supplierItems.find(el => el.supplierItem.objectId === params.orderSupplierItemId)

            batch(() => {
                store.dispatch({
                    type: "ORDER_RECEPTION_QUANTITY",
                    selectedOrderReception: selectedOrder,
                    selectedOrderSupplierItem,
                    date,
                    site
                })
            })
        }
    }
}

export function onEnterOrderSupplierItemLots(store) {
    return async (nextState) => {
        const { params } = nextState

        if (params) {
            const state = store.getState()
            const path = state.routing.locationBeforeTransitions.pathname
            const data = getParamsFromPath(path)

            const date = state.orderReception.date || data.date
            const site = state.orderReception.site || (await getSiteById(data.site, ["*"], true))

            let selectedOrder = getOrderReception(state, params.id)

            if (!selectedOrder) {
                selectedOrder = await getOrder(params.id)
            }

            let selectedOrderSupplierItem = selectedOrder.supplierItems.find(el => el.supplierItem.objectId === params.orderSupplierItemId)

            store.dispatch({
                type: "ORDER_SUPPLIER_ITEM_LOTS_LOADED",
                site,
                date,
                selectedOrderReception: selectedOrder,
                selectedOrderSupplierItem: selectedOrderSupplierItem
            })
        }
    }
}

/** Routers **/

export function showOrderFilter() {
    return push("/orderReceptions")
}

export function showOrderList(site, date) {
    if (site && date) {
        return push(`/orderReceptions/${site}/${date}`)
    }
}

export function showSingleOrder(site, date, id) {
    if (site && date && id) {
        const localStorage = window.localStorage
        const pathname = window.location.pathname
        localStorage.setItem("singleOrderReceptionNavigationHistory", JSON.stringify({ lastPath: pathname.substring(1) }))
        return push(`/orderReceptions/${site}/${date}/${id}`)
    }
}

export function showOrderSupplierItemLots(site, date, id, supplierItemId) {
    if (site && date && id && supplierItemId) {
        return push(`/orderReceptions/${site}/${date}/${id}/${supplierItemId}`)
    }
}

export function showOrderSupplierItemQuantities(site, date, id, supplierItemId) {
    if (site && date && id && supplierItemId) {
        return push(`/orderReceptions/${site}/${date}/${id}/${supplierItemId}/quantities`)
    }
}

export function showOrderDeliveryNote(site, date, id) {
    if (site && date && id) {
        return push(`/orderReceptions/${site}/${date}/${id}/deliveryNote`)
    }
}

export function showOrderDeliveryNoteControl(site, date, id) {
    if (site && date && id) {
        return push(`/orderReceptions/${site}/${date}/${id}/deliveryNoteControl`)
    }
}

export default function showBillingCreditNotesControl(site, date, id) {
    if (site && date && id) {
        return push(`/billingCreditNotes/${site}/${date}/${id}/control`)
    }
}
