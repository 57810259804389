import React from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import FullScreenWrapper from "../../../components/FullScreenWrapper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form } from "formik"
import TextField from "@mui/material/TextField"
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto"
import CloseIcon from "@mui/icons-material/Close"
import {
    showSingleOrder,
    saveDeliveryNote
} from "../../../actions/OrderReception/OrderReception"
import { useCallback } from "react"
import clsx from "clsx"
import { orderDeliveryNoteSchema } from "../../../utils/yupValidators"
import {
    getDeliveryNoteInitialValues
} from "../../../utils/orderReception"
import { openUploadWidget } from "../../../utils/cloudinary"
import GenericTopBarHeader from "../../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../../components/GenericHeaderReturnButton"
import SingleReceptionHeaderTitle from "../../../components/Order/Reception/SingleReceptionHeaderTitle"
import moment from "moment"

const useStyles = makeStyles(() => ({
    headerBloc: {
        flex: "0 0 72px"
    },
    firstButton: {
        marginRight: 10,
    },
    disabled: {
        opacity: 0.6
    },
    subtitle: {
        fontSize: 20,
        fontWeight: 500
    },
    flex: {
        display: "flex",
    },
    flexColumn: {
        composes: "$flex",
        flexDirection: "column",
    },
    mainContainer: {
        composes: "$flexColumn",
        marginTop: 40,
        paddingLeft: 75
    },
    row: {
        marginBottom: 68
    },
    informationRow: {
        composes: "$flex",
        marginTop: 24
    },
    informationColumn: {
        composes: "$flexColumn",
        minWidth: 200,
        paddingRight: 86
    },
    informationTitle: {
        fontSize: 12,
        opacity: 0.87,
        color: COLORS.INFORMATION_TITLE
    },
    informationValue: {
        fontSize: 14
    },
    numberInput: {
        width: 378
    },
    imagesRow: {
        composes: "$flexColumn",
        paddingTop: 35
    },
    addImage: {
        width: 102,
        height: 62,
        border: `1px solid ${COLORS.PRIMARY_COLOR}`,
        composes: "$flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    addPhotoIcon: {
        color: COLORS.PRIMARY_COLOR
    },
    deletePhotoIcon: {
        color: COLORS.GREY_SEARCH,
        cursor: "pointer"
    },
    imageInfo: {
        color: COLORS.SITE_INFORMATION_VALUE,
        fontSize: 12
    },
    imageContainer: {
        composes: "$flex",
        background: COLORS.GREY_IMAGE_LIST,
        justifyContent: "space-between",
        alignItems: "center",
        width: 119,
        height: 32,
        marginRight: 17,
        padding: "4px 6px",
        marginBottom: 28
    },
    errorField: {
        color: COLORS.RED,
        marginTop: 7
    },
    divider: {
        border: `1px solid ${COLORS.SITE_INFORMATION_VALUE}`,
        marginRight: 85
    },
    inputDate: {
        marginTop: 10,
        fontSize: 18,
    }
}))

const OrderDeliveryNote = () => {
    const dispatch = useDispatch()

    const orderReception = useSelector(state => state.orderReception.selectedOrderReception)
    const date = useSelector(state => state.orderReception.date)
    const site = useSelector(state => state.orderReception.site)

    const classes = useStyles()

    const onClickReturn = useCallback(() => {
        dispatch(showSingleOrder(site.objectId, date, orderReception.objectId))
    }, [site, date, orderReception])

    const onDeleteImage = (key, images, setFieldValue) => {
        images.splice(key, 1)

        setFieldValue("images", images)
    }
    const addNewPhoto = (images, setFieldValue) => {
        openUploadWidget({ tags: ["order"] }, (error, result) => {
            if (error) {
                console.error(error)
                return
            }
            if (result.event !== "success") {
                // we don't use this event
                return
            }

            const publicId = result.info.public_id

            images = [...images, {
                publicId: { publicId }
            }].map((img, key) => {
                img.name = `order_${orderReception && orderReception.orderNumber}_${key + 1}`

                return img
            })
            if (images.publicId !== null) {
                setFieldValue("images", images)
            }
        })
    }

    const submitForm = async (values) => {
        dispatch(saveDeliveryNote(orderReception.objectId, values))
        dispatch(showSingleOrder(site.objectId, date, orderReception.objectId))
    }

    const generateHeadings = useCallback(() => {
        return {
            title: `commande ${(orderReception && orderReception.supplier && orderReception.supplier.name) || ""}`,
            subtitle: `N° de commande: ${(orderReception && orderReception.orderNumber) || ""}`
        }
    }, [orderReception])

    return (
        <FullScreenWrapper>
            <Formik
                enableReinitialize
                initialValues={getDeliveryNoteInitialValues(orderReception)}
                onSubmit={submitForm}
                validationSchema={orderDeliveryNoteSchema}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleSubmit
                }) => {
                    const allowValidate = errors && !errors.number && !errors.images && values.number

                    return (
                        <Form>
                            <div className={classes.headerBloc}>
                                <GenericTopBarHeader
                                    leftAction={<GenericHeaderReturnButton handleClick={onClickReturn} />}
                                    title={<SingleReceptionHeaderTitle headings={generateHeadings()} />}
                                    rightAction={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={clsx(!allowValidate ? classes.disabled : "")}
                                            onClick={allowValidate ? handleSubmit : () => { }}
                                        >
                                            VALIDER LE NUMÉRO DE BL + PHOTOS
                                        </Button>
                                    }
                                />
                            </div>
                            <div className={classes.mainContainer}>
                                <div className={classes.row}>
                                    <Typography className={classes.subtitle}>
                                        Ajouter le numéro du bon de livraison
                                    </Typography>
                                    <div>
                                        <TextField
                                            variant="standard"
                                            name="number"
                                            value={values.number}
                                            onChange={handleChange}
                                            label="Numéro du bon de livraison"
                                            className={classes.numberInput}
                                            InputLabelProps={
                                                values.number &&
                                                { shrink: true }
                                            }
                                        />
                                        {
                                            errors.number ?
                                                (
                                                    <div className={classes.errorField}>{errors.number}</div>
                                                )
                                                : null
                                        }
                                    </div>
                                    {values.inputDate &&
                                      (
                                        <Typography className={classes.inputDate}>
                                            Date de saisie : { moment(values.inputDate).format("DD/MM/YYYY - HH:mm") }
                                        </Typography>
                                      )
                                    }
                                </div>
                                <div className={classes.row}>
                                    <Typography className={classes.subtitle}>
                                        Ajouter une photo du bon de livraison
                                    </Typography>
                                    <div className={classes.imagesRow}>
                                        <div className={classes.flex}>
                                            {
                                                values.images.map((img, key) => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className={classes.imageContainer}
                                                        >
                                                            <Typography
                                                                className={classes.imageInfo}
                                                            >
                                                                {img.name}
                                                            </Typography>
                                                            <CloseIcon
                                                                className={classes.deletePhotoIcon}
                                                                onClick={() => onDeleteImage(key, values.images, setFieldValue)}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div
                                            className={classes.addImage}
                                            onClick={() => addNewPhoto(values.images, setFieldValue, null)}
                                        >
                                            <AddAPhotoIcon className={classes.addPhotoIcon} />
                                        </div>
                                        {
                                            errors.images ?
                                                (
                                                    <div className={classes.errorField}>{errors.images}</div>
                                                )
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </FullScreenWrapper>
    )
}

export default OrderDeliveryNote