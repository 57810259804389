import app from "./Utils/app"
import recipes from "./Recipe/recipes"
import packagingList from "./Packaging/packagingList"
import packagingExecution from "./Packaging/packagingExecution"
import subcontractorsProducts from "./Subcontractor/subcontractorsProducts"
import productions from "./Recipe/productions"
import subcontractorsCommercialNames from "./Ingredients/SubcontractorsCommercialNames/SubcontractorsCommercialNames"
import familiesIngredients from "./Ingredients/Classifications/FamiliesIngredients"
import groupsIngredients from "./Ingredients/Classifications/GroupsIngredients"
import allergensIngredients from "./Ingredients/Classifications/AllergensIngredients"
import commercialNames from "./Ingredients/Internal/CommercialName"
import sectionsGeneric from "./Section/SectionsGeneric"
import dispatch from "./Dispatch/dispatch"
import planning from "./Planning/Planning"
import products from "./Products/Products"
import ingredientsPage from "./Ingredients/IngredientsPage"
import productsTags from "./ProductsTags/ProductsTags"
import planningSettings from "./Planning/PlanningSettings"
import planningDashboard from "./Planning/PlanningDashboard"
import rights from "./Rights/Rights"
import chefs from "./Chefs/Chefs"
import reception from "./Reception/reception"
import inventory from "./Inventory/inventory"
import receptionGap from "./Reception/receptionGap"
import searchAdvanced from "./Products/searchAdvanced"
import receptionWarehouse from "./Reception/receptionWarehouse"
import lotGroup from "./Reception/lotGroup"
import sites from "./Site/sites"
import suppliers from "./Suppliers/Suppliers/suppliers"
import orderReception from "./OrderReception/orderReception"
import supplierItems from "./Suppliers/SupplierItems/supplierItems"
import lotInventory from "./LotInventory/LotInventory"
import orders from "./Orders/orders"
import billingCreditNotes from "./BillingCreditNotes/billingCreditNotes"
import machines from "./Machines/machines"
import transformationModes from "./TransformationModes/transformationModes"
import steps from "./Steps/steps"
import productTypes from "./ProductTypes/productTypes"
import productionSchemas from "./ProductionSchemas/productionSchemas"
import productionSteps from "./Production/ProductionSteps"
import settings from "./Settings/settings"
import secondaryDLC from "./SecondaryDLC/SecondaryDLC"
import productionReception from "./ProductionReception/ProductionReception"
import productionSubstitutions from "./ProductionSubstitution/ProductionSubstitutions"

const appReducers = {
	app,
	recipes,
	packagingList,
	subcontractorsProducts,
	productions,
	subcontractorsCommercialNames,
	familiesIngredients,
	groupsIngredients,
	allergensIngredients,
	commercialNames,
	sectionsGeneric,
	dispatch,
	planning,
	products,
	supplierItems,
	ingredientsPage,
	productsTags,
	planningSettings,
	planningDashboard,
	rights,
	chefs,
	reception,
	inventory,
	receptionGap,
	searchAdvanced,
	receptionWarehouse,
	lotGroup,
	sites,
	suppliers,
	orderReception,
	lotInventory,
	orders,
	billingCreditNotes,
	machines,
	transformationModes,
	steps,
	productTypes,
	productionSchemas,
	productionSteps,
	packagingExecution,
	settings,
	secondaryDLC,
	productionReception,
	productionSubstitutions
}

export default appReducers

/**
 * used by selectors
 * @param state
 * @param path
 * @param errorMessageIfNotFound
 * @returns {*}
 */
export function getData(state, path, errorMessageIfNotFound) {
	let data
	try {
		if (typeof state === "function") {
			throw new Error("The state parameter must not be a function. The error is usually the usage of getState instead of getState(). Path is", path)
		}
		data = path.split(".").reduce((res, prop) => res[prop], state)
		if (errorMessageIfNotFound && data == null) {
			throw new Error(errorMessageIfNotFound)
		}
	} catch (error) {
		return undefined
	}
	return data
}
