import Parse from "parse"

import {
	actionWithLoader,
	push,
	routerLocation,
	queryUserRights,
	onEnter
} from "./utils"
import { axiosCall } from "../../utils/axiosUtils"
import { getServerUrl } from "../../utils"
import { getUserNotDeletedByUsername } from "../../parseManager/rights/parseRights"

const CONNECTION_ERROR = "Votre connexion est perturbée et ne vous a pas permis de poursuivre l'action que vous souhaitiez réaliser. Vérifiez vos paramètres de connexion et tentez à nouveau."
const LOGIN_ERROR = "Il semble que vous vous soyez trompé sur les informations saisies. Essayez de nouveau ou réinitialisez votre mot de passe."
const EXISTING_ACCOUNT = "Un compte existe déjà à cette adresse"
const CREATE_ACCOUNT_SUCCESS = "Le compte a bien été créé"
const PASSWORD_CHANGE_REQUESTED = "Un e-mail a été envoyé à l'adresse suivant : [email]"
const PASSWORD_CHANGED = "Le mot de passe du compte [email] a bien été modifié"

export function getErrorMessageFr(error) {
	if (!error || typeof error === "string") return error
	switch (error.code) {
		case 100:
			return CONNECTION_ERROR
		case 101:
			return LOGIN_ERROR
		default:
			return error.message
	}
}

export function showError(errorOrMessage) {
	return dispatch => {
		try {
			console.error(errorOrMessage)
			//const message = typeof errorOrMessage === "string" ? errorOrMessage : errorOrMessage.message
			dispatch({
				type: "ERROR",
				message: getErrorMessageFr(errorOrMessage)
			})
		} catch (error) {
			console.error(error.message)
		}

	}
}

export function closeError() {
	return { type: "CLOSE_ERROR" }
}

export function closeMessage() {
	return { type: "CLOSE_MESSAGE" }
}

export const updateScreen = (screen) => {
	return dispatch => {
		dispatch({
			type: "SCREEN_UPDATED",
			screen
		})
	}
}
export function updateMenu(value) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "UPDATE_MENU",
			menuIsOpen: value
		})
	})
}

export function requestPasswordChange(email) {
	return async dispatch => {
		try {
			await Parse.User.requestPasswordReset(email)
			// Password reset request was sent successfully
			dispatch({
				type: "MESSAGE",
				message: "Nous vous avons envoyé un e-mail de réinitialisation de mot de passe."
			})
		} catch (error) {
			console.error(error)
			// Show the error message
			dispatch({
				type: "MESSAGE",
				message: "Aïe ! Vous n'avez jamais crée de compte avec l'e-mail " + email + ". Vous avez probablement utilisé un autre e-mail."
			})
		}
		dispatch(showHome())
	}
}

/**
 * this route is in another route with onEnterMobileAccount (in users.js)
 * @param store
 * @returns {*}
 */
/*export function onEnterChangePassword(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				dispatch({
					type: 'PASSWORD_CHANGE_DATA',
					passwordChangeData: {

					}
				})
			}
		}
	})
}*/

export function changePassword({ token, newPassword, username }) {
	return async dispatch => {
		try {
			const url = `${getServerUrl()}/users/_changePassword`
			const data = {
				token,
				newPassword,
				username
			}

			const result = await axiosCall("post", url, data, { "Content-Type": "application/json" })

			if (result.status === 200) {
				dispatch(showLogin())
				dispatch({
					type: "SUCCESS_MESSAGE",
					appSnackBar: {
						open: true,
						type: "success",
						message: PASSWORD_CHANGED.replace("[email]", username),
						duration: 5000
					}
				})
			}
		} catch (error) {
			if (error.response.data) {
				const dataError = error.response.data

				dispatch({
					type: "ERROR_MESSAGE",
					appSnackBar: { open: true, type: "error", message: (dataError.error && dataError.error.message) ? dataError.error.message : dataError.error, duration: 5000 }
				})
			}
		}
	}
}

export function connectLoggedUser() {
	return async (dispatch) => {
		if (window.location.pathname.endsWith("login") || window.location.pathname === "/") {
			dispatch(showHome())
		}
	}
}

export function createAccount(username, password, returnPath) {
	return actionWithLoader(async (dispatch) => {
		try {
			const url = `${getServerUrl()}/users/_createUser`
			const data = {
				username,
				password,
				email: username
			}

			const result = await axiosCall("post", url, data, { "Content-Type": "application/json" })

			if (result.status === 200) {
				if (returnPath) {
					dispatch(push(returnPath))
				} else {
					dispatch(showLogin())
				}

				dispatch({
					type: "SUCCESS_MESSAGE",
					appSnackBar: { open: true, type: "success", message: CREATE_ACCOUNT_SUCCESS, duration: 5000 }
				})
			}
		} catch (error) {
			if (error.response.data) {
				const dataError = error.response.data

				if (dataError.error && dataError.error.code === 202) {
					if (returnPath) {
						dispatch(push(returnPath))
					} else {
						dispatch(showLogin())
					}

					dispatch({
						type: "ERROR_MESSAGE",
						appSnackBar: { open: true, type: "error", message: EXISTING_ACCOUNT, duration: 5000 }
					})
				} else {
					dispatch({
						type: "ERROR_MESSAGE",
						appSnackBar: { open: true, type: "error", message: (dataError.error && dataError.error.message) ? dataError.error.message : dataError.error, duration: 5000 }
					})
				}
			}
		}
	})
}

export function closeAppSnackBar(currentType) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "CLOSE_APP_SNACKBAR",
			appSnackBar: { open: false, type: currentType, message: "", duration: 1000 }
		})
	})
}

export const openAppMessageSnackBar = (message, type = "success", duration = 5000) => {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "APP_MESSAGE",
			appSnackBar: {
				open: true,
				type,
				message,
				duration
			}
		})
	})
}

export const closeAppMessageSnackBar = () => {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "APP_MESSAGE",
			appSnackBar: {
				open: false,
				type: "",
				message: "",
				duration: 1000
			}
		})
	})
}

export function displaySidebar(display) {
	return actionWithLoader(async (dispatch) => {
		dispatch({
			type: "TOGGLE_SIDEBAR",
			displaySidebar: display
		})
	})
}

export function sendRequestPasswordChange(email, returnPath) {
	return actionWithLoader(async (dispatch) => {
		try {
			const user = await getUserNotDeletedByUsername(email)

			if (!user) throw new Error()

			await Parse.User.requestPasswordReset(email)

			if (returnPath) {
				dispatch(push(returnPath))
			} else {
				dispatch(showLogin())
			}

			dispatch({
				type: "SUCCESS_MESSAGE",
				appSnackBar: { open: true, type: "success", message: PASSWORD_CHANGE_REQUESTED.replace("[email]", email), duration: 5000 }
			})
		} catch (error) {
			dispatch({
				type: "SUCCESS_MESSAGE",
				appSnackBar: { open: true, type: "success", message: PASSWORD_CHANGE_REQUESTED.replace("[email]", email), duration: 5000 }
			})
		}
	})
}

export function login(username, password, returnPath) {
	return actionWithLoader(async (dispatch, getState) => {
		try {
			const user = await getUserNotDeletedByUsername(username)

			if (!user) throw new Error()

			await Parse.User.logIn(username, password)

			// Do after successful login.
			await loginSuccess(dispatch, getState)
			await loadUserRights()(dispatch)
			if (returnPath) {
				dispatch(push(returnPath))
			} else {
				// dispatch(showHome())
				await connectLoggedUser()(dispatch, getState)
			}
		} catch (error) {
			// The login failed. Check error to see why.
			dispatch({
				type: "ERROR_MESSAGE",
				appSnackBar: { open: true, type: "error", message: LOGIN_ERROR, duration: 5000 }
			})
			/*dispatch({
				type: 'MESSAGE',
				message: getErrorMessageFr(error)
			})*/
		}
	})
}

export function logoutThunk() {
	return async (dispatch) => {
		await Parse.User.logOut()
		cleanAfterLogout(dispatch)
		dispatch({
			type: "LOGOUT_SUCCESS"
		})

	}
}

export function logout() {
	return async (dispatch, getState) => {
		logoutThunk()(dispatch, getState)
		dispatch(showLogin())
	}
}

export async function loginSuccess(dispatch) {
	const user = Parse.User.current()
	dispatch({
		type: "LOGIN_SUCCESS",
		user
	})
}

export async function getDatabaseEnvironment() {
	const url = `${getServerUrl()}/rights/getEnvironment`
	const result = await axiosCall("get", url, null, { "Content-Type": "application/json" })

	if (result.status === 200) {
		return result.data.environment
	}

	return null
}

function cleanAfterLogout() {
	// dispatch({
	// 	type: 'REMOVE_PLAN'
	// })
	// dispatch({
	// 	type: 'REMOVE_ORDER'
	// })
	// dispatch({
	// 	type: 'REMOVE_SHIPPING_DAY'
	// })
	// dispatch({
	// 	type: 'REMOVE_PLAN_OFFERS'
	// })
}
//--------------------------------------------------------//
//------------------- Site Password ----------------------//
//--------------------------------------------------------//

function loadUserRights() {
	return actionWithLoader(async (dispatch) => {
		const rights = await queryUserRights()
		dispatch({ type: "USER_RIGHTS_LOADED", rights: rights })
	})
}
export function onEnterApp(store) {
	return onEnter({
		store,
		actionThunk: loadUserRights,
	})
}

export function sitePassword(sitePassword) {
	return dispatch => {
		dispatch({
			type: "SITE_PASSWORD",
			sitePassword
		})
		dispatch(showHome())
	}
}


//----------------------------------------------------------------//

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
export function showHome() {
	return push("/")
}

export function createAccountPath(returnPath) {
	return routerLocation("/account/create", returnPath)
}
export function createRequetPasswordChangePath(returnPath) {
	return routerLocation("/account/request-password-change", returnPath)
}
export function loginPath(returnPath) {
	return routerLocation("/login", returnPath)
}
export function showLogin(returnPath) {
	return push(loginPath(returnPath))
}
export function showCreateAccount(returnPath) {
	return push(createAccountPath(returnPath))
}
export function showRequestPasswordChange(returnPath) {
	return push(createRequetPasswordChangePath(returnPath))
}
