import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import {
	Dialog,
	styled,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button
} from "@mui/material"
import { COLORS } from "../../utils"
import PSESubstitutionSupplierItemsForm from "../ProductionStepExecutions/PSESubstitutionSupplierItemsForm"
import PSESubstitutionRecipesForm from "../ProductionStepExecutions/PSESubstitutionRecipesForm"
import { substituteSupplierItemsInProductionStepExecutions } from "../../actions/ProductionSteps/ProductionStepExecutions"
import { saveProductionSubstitution } from "../../actions/ProductionSubstitutions/ProductionSubstitutions"

export const pseSubstitutionStep1Id = "pseSubstitutionStep1"
export const pseSubstitutionStep2Id = "pseSubstitutionStep2"

const StyledDialog = styled(Dialog)({
	"& .MuiPaper-root": {
		width: 500,
		padding: 10,
	},
})

const sx = {
	title: {
		pb: 0,
		fontSize: 16,
		color: COLORS.BLACK_500,
		fontWeight: 400,
		lineHeight: 1.375,
	},
}

const PSESubstitutionDialog = ({ open, onClose, supplierItems = [] }) => {

	const dispatch = useDispatch()
	
	const [values, setValues] = useState({})
	const [step, setStep] = useState(0)

	const recipes = []

	const title = useMemo(() => {
		if (step === 0) {
			return "Veuillez sélectionner l’article à remplacer ainsi que l’article de remplacement que vous souhaitez utiliser."
		}
		return "Veuillez sélectionner le jour de production et la/les recettes pour lesquelles vous souhaitez utiliser l'article de remplacement."
	}, [step])

	const handleSubmitFirstStep = (values) => {
		const { originalSupplierItem, replacementSupplierItem } = values
		setValues({
			originalSupplierItem: originalSupplierItem.value,
			replacementSupplierItem: replacementSupplierItem.value
		})
		setStep(1)
	}

	const handleSubmitSecondStep = (values) => {
		dispatch(saveProductionSubstitution(values, false))
		dispatch(substituteSupplierItemsInProductionStepExecutions(values))
		onClose && onClose()
	}

	const handleClose = () => {
		setStep(0)
		onClose()
	}

	return (
		<StyledDialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" sx={sx.title}>
				{title}
			</DialogTitle>
			<DialogContent sx={{ mt: 5, mb: 1, p: 4 }}>
				{step === 0 && (
					<PSESubstitutionSupplierItemsForm
						formId={pseSubstitutionStep1Id}
						handleSubmit={handleSubmitFirstStep}
						supplierItems={supplierItems}
					/>
				)}
				{step === 1 && (
					<PSESubstitutionRecipesForm
						handleSubmit={handleSubmitSecondStep}
						recipes={recipes}
						formerValues={values}
						formId={pseSubstitutionStep2Id}
					/>
				)}
			</DialogContent>
			<DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
				<Button onClick={handleClose} color="primary">
					Annuler
				</Button>
				<Button
					color="primary"
					variant="contained"
					type="submit"
					form={step === 0 ? pseSubstitutionStep1Id : pseSubstitutionStep2Id}
				>
					{step === 0 ? "Suivant" : "Confirmer"}
				</Button>
			</DialogActions>
		</StyledDialog>
	)
}

export default PSESubstitutionDialog
