import React, { useMemo } from "react"
import { makeStyles } from "@mui/styles"
import { Stack } from "@mui/material"
import { PSE_STATUS } from "../../../actions/ProductionSteps/ProductionStepExecutions"
import { roundNumber } from "../../../utils"

const useStyles = makeStyles({
  root: {
    border: "1px solid #E6E6E6",
    padding: "24px",
    gap: "24px",
    borderRadius: "8px"
  },
  labelContainer: {
    flexDirection: "row",
    gap: "8px"
  },
  mainLabel: {
    color: "#262626",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  },
  label: {
    color: "#555",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "22px"
  },
  list: {
    flexDirection: "row",
    gap: "24px"
  },
  headerCell: {
    color: "#555555",
    fontWeight: 400,
    paddingBottom: "8px !important",
    width: "150px"
  },
  value: {
    color: "#262626",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  }
})

const PSEWeights = ({ productionStepExecution, isVisible = true, status }) => {

  const classes = useStyles()

  const _getData = useMemo(() => {
    const data = []
    if ([PSE_STATUS.todo, PSE_STATUS.locked].includes(productionStepExecution.status)) {
      data.push({ key: "theoreticalNetWeight", label: "Poids final prévu", value: `${roundNumber(productionStepExecution.theoreticalNetWeight, 2)} kg` })
    }
    if (status === PSE_STATUS.done) {
    data.push({ key: "netTheoreticalWeight", label: "Poids final réel/prévu", value: roundNumber(productionStepExecution.netWeight, 2) + " / " + roundNumber(productionStepExecution.theoreticalNetWeight, 2) + " kg" })
    data.push({ key: "gap", label: "Écart en valeur", value: roundNumber(productionStepExecution.netWeight - productionStepExecution.theoreticalNetWeight, 2) + " kg" })
    data.push({ key: "gapPercentage", label: "Écart en %", value: roundNumber((productionStepExecution.netWeight - productionStepExecution.theoreticalNetWeight) / productionStepExecution.theoreticalNetWeight * 100, 2) + " %" })
    }
    return data
  }, [status, productionStepExecution])

  if (!isVisible) {
    return null
  }

  return (
    <Stack className={classes.root}>
      <Stack className={classes.labelContainer}>
        <img src="/img/recipes/weight.svg" alt="Poids" />
        <span className={classes.mainLabel}>Poids</span>
      </Stack>
      <Stack className={classes.list}>
        {_getData.map((header, index) => (
          <Stack key={index} gap={1}>
            <div className={classes.label}>{header.label}</div>
            <div className={classes.value}>{header.value}</div>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )

}

export default PSEWeights