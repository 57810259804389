import React, { } from "react"
import { makeStyles } from "@mui/styles"
import moment from "moment"
import Button from "@mui/material/Button"
import ActionHeader from "./ActionHeader"
import { COLORS } from "../../utils"
import AddIcon from "@mui/icons-material/Add"
import IconButton from "@mui/material/IconButton"

const useStyles = makeStyles(() => ({
	root: {
		marginBottom: 55,
		textAlign: "center",
		alignSelf: "center",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		flexGrow: 1,
		minWidth: "350px"
	},
	title: {
		fontSize: 24,
		margin: "0px 16px"
	},
	subtitle: {
		marginTop: 24
	},
	headerText: {
		fontSize: 20,
		marginTop: 16
	},
	bold: {
		fontWeight: 700
	},
	quantity: {
		margin: "0px auto",
		width: "100%",
		fontSize: 40,
		textAlign: "center",
		color: COLORS.GREY_DISABLED
	},
	buttons: {
		padding: "15px 24px",
		top: 48,
		position: "relative"
	},
	stockUnit: {
		marginTop: "-8px",
		marginBottom: 8,
		fontSize: 16
	},
	flexForAllZones: {
		flexGrow: 0.1,
	},
	substituteContainer: {
		flexGrow: 0.5,
		width: "100%",
		display: "block"
	},
	substituteHeader: {
		backgroundColor: COLORS.PRODUCTION_STEPS_BLUE,
		flexGrow: 0.5,
		padding: 20,
		textAlign: "left",
		fontWeight: "bold"
	},
	substituteList: {
		flexGrow: 0.5,
		padding: 20,
		display: "flex",
		flexDirection: "row",
		border: `1px solid ${COLORS.PRODUCTION_SCHEMA_BORDER_COLOR}`,
		cursor: "pointer"
	},
	substituteRow: {
		flexGrow: 0.5,
		textAlign: "left",
		color: "#7C7C7C"
	},
	substituteName: {
		fontWeight: "bold",
		margin: 0,
		color: COLORS.DRAFT_GREY
	},
	substituteNameAlreadyOutput: {
		margin: 0,
		color: COLORS.BLACK,
		fontWeight: 500
	},
	substituteAction: {
		flexGrow: 0.5,
		display: "flex",
		justifyContent: "flex-end"
	},
	substituteOutputLabel: {
		color: COLORS.DRAFT_GREY
	},
	substituteOutputQuantity: {
		color: COLORS.BLACK
	}
}))

const LotHypotheticalActionContent = (props) => {
	const {
		lot,
		disableSelection,
		substituteLots,
		onSelectSubstitute,
		closeHypotheticalLot
	} = props
	const classes = useStyles()

	return (
		<>
			<div className={classes.root}>
				<ActionHeader
					handleClose={disableSelection}
					noBorder={true}
				/>
				<>
					<div>
						<div className={classes.title}>
							<div className={classes.title}>
								<div className={classes.bold}>
									{lot.orderSupplierItem.name}
								</div>
							</div>
							<div className={classes.headerText}>
								Quantité manquante
							</div>
							<div className={classes.quantity}>
								{lot.quantityOutput}
							</div>
							<div className={classes.stockUnit}>
								KG
							</div>
						</div>
					</div>
				</>
				<>
					<div className={classes.substituteContainer}>
						{substituteLots && substituteLots.length > 0 && (
						<div className={classes.substituteHeader}>
							Substituts
						</div>
						)}
						{
							substituteLots.map(substituteLot =>
								(
									<div className={classes.substituteList} key={substituteLot.objectId} onClick={() => substituteLot.quantity > 0 ? onSelectSubstitute(substituteLot): null}>
										<div className={classes.substituteRow}>
											<p className={substituteLot.outputAlreadyDone && substituteLot.outputAlreadyDone > 0 ? classes.substituteNameAlreadyOutput : classes.substituteName}>
												{substituteLot.orderSupplierItem.name}
											</p>
											<span>Lot : {substituteLot.lotNumber}</span>
											<span style={{marginLeft: 16}}>DLC : {moment(substituteLot.dlc).format("DD/MM")}</span>
										</div>
										<div className={classes.substituteAction}>
											{substituteLot.outputAlreadyDone && substituteLot.outputAlreadyDone > 0 ?
												(
													<p style={{ margin: 0 }}>
														<span className={classes.substituteOutputLabel}>QTÉ SORTIE : </span>
														<span className={classes.substituteOutputQuantity}>{substituteLot.outputAlreadyDone}KG</span>
													</p>
												)
												:
												(
												<IconButton
													aria-label="Add"
													variant="contained"
													className={classes.addSubstitute}
													size="large">
													<AddIcon htmlColor={COLORS.DARK_GREY}/>
												</IconButton>
												)
											}
										</div>
									</div>
								)
							)
						}

						<div className={classes.buttons}>
							<Button variant="contained" color="primary" onClick={() => closeHypotheticalLot(lot)}>
								TRAITER LE MANQUANT
							</Button>
						</div>
					</div>
				</>
			</div>
		</>
	)
}

export default LotHypotheticalActionContent