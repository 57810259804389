import React, { Fragment, useCallback } from "react"

import styled from "@emotion/styled"
import {
  Accordion,
  AccordionSummary,
  Stack,
  Box
} from "@mui/material"
import { cloneDeep } from "lodash"

import { ACCORDION_ICONS_OFFSETS } from "../../../../utils/constant"
import { COLORS } from "../../../../utils"
import ReusableStepFormRow from "../../../../containers/Settings/TechnicalSheet/Resources/reusableSteps/ReusableStepFormRow"
import { computeProductionStepsRecipeOnFieldChange, computeSectionData, getProductionStepPointerObj, updateRecipeAfterReusableStepChange } from "../../../../utils/recipes"
import LoopPriorSteps from "./priorSteps/LoopPriorSteps"
import { sortReusableStepsByName } from "../../../../actions/Utils/utils"
import StepComponentPreview from "./StepComponentPreview"
import EditableStepComponent from "./EditableStepComponent"
import PriorStepComponentPreview from "./PriorStepComponentPreview"
import StepPreview from "./StepPreview"
import EditableStep from "./EditableStep"

export const COMPONENT_NAME = "STEPS"
export const PRODUCTION_STEPS_COMPONENT_NAME = "PRODUCTION_STEPS"
export const PRODUCTION_STEPS_PRIOR_COMPONENT_NAME = "PRODUCTION_STEPS_PRIOR_COMPONENT"

// ----------------------------------------------- //
// -------------- styled components -------------- //
// ----------------------------------------------- //
// -------------- Table -------------- //

// -------------- Accordion -------------- //
const StyledAccordion = styled((props) => (
  <Accordion {...props} />
))({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
})

export const StyledAccordionSummary = styled(
  (props) => <AccordionSummary {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "componentName" && prop !== "usage" && prop !== "isReusable"
  }
)(({ componentName = COMPONENT_NAME, usage = "section", isReusable = false }) => ({
  flexDirection: "row-reverse",
  position: "relative",
  // opened and closed expanded icon
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded,& .MuiAccordionSummary-expandIconWrapper": {
    position: "sticky",
    left: ACCORDION_ICONS_OFFSETS[usage],
    zIndex: 30 // must be above stickyStyle in StyledSectionFirstBodyColumn
  },
  // row
  "& .MuiAccordionSummary-content": {
    padding: 0,
    margin: 0,
    borderBottom: "1px solid #cccccc",
    marginLeft: !isReusable && usage === "stepComponents" ? - 16 : usage === "stepComponents" ? - 25 : -28, // important! for the summary to not take account of the expand icon space, the value depends on the placement inside nested accordions
    backgroundColor: componentName === COMPONENT_NAME ? COLORS.PRODUCTION_STEPS_GREY : COLORS.PRODUCTION_STEPS_COMPONENT_WHITE
  }
}))

export const PriorStepsStyledAccordionSummary = styled(
  (props) => <AccordionSummary {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "isReusable" && prop !== "usage"
  }
)(({ usage = "stepComponents", isReusable = false }) => ({
  flexDirection: "row-reverse",
  position: "relative",
  paddingLeft: 0,
  // opened and closed expanded icon
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded,& .MuiAccordionSummary-expandIconWrapper": {
    position: "sticky",
    left: ACCORDION_ICONS_OFFSETS[usage],
    zIndex: 30 // must be above stickyStyle in StyledSectionFirstBodyColumn
  },
  // row
  "& .MuiAccordionSummary-content": {
    height: 54,
    padding: 0,
    margin: 0,
    borderBottom: "1px solid #cccccc",
    marginLeft: isReusable ? - 21 : -12 // important! for the summary to not take account of the expand icon space, the value depends on the placement inside nested accordions
  }
}))

const StyledLeftBar = styled("div")({
  width: 8,
  backgroundColor: COLORS.DARK_YELLOW_REUSABLE_STEP_PARENT,
  position: "sticky",
  left: 0,
  top: 0,
  flex: 1,
  zIndex: 4
})

const Steps = ({
  recipe,
  section,
  steps,
  isEdition,
  sectionIndex,
  onRowHover,
  onRowBlur,
  hoveredRow,
  // genericSections,
  // onClearFocus,
  onFieldFocus,
  onFieldBlur,
  onKeyUp,
  hasError,
  machineTypes,
  kitchenAreas,
  setFieldValue,
  supplierItems,
  transformationModes,
  handleChange,
  isReusable,
  computeReusableStepsFormValues,
  errors,
  setValues,
  formValues,
  onClearFocus,
  allReusableSteps,
  parentStepIndex,
  stepNumbers,
  fromRecipe = true
}) => {
  const _isStepHover = useCallback((index) => {
    if (!isEdition) return
    // reusable step children should not be hovered
    if (parentStepIndex !== undefined) return

    // from recipe BO
    if (fromRecipe) {
      return (
        hoveredRow &&
        COMPONENT_NAME === hoveredRow.component &&
        hoveredRow.index === index &&
        hoveredRow.parentIndex === sectionIndex
      )
    }

    // from reusable steps BO
    return (
      hoveredRow &&
      COMPONENT_NAME === hoveredRow.component &&
      hoveredRow.index === index
    )
  }, [sectionIndex, hoveredRow, fromRecipe, parentStepIndex, isEdition])

  const _isStepComponentHover = useCallback((index, componentIndex, stepComponentIndexesList = []) => {
    if (hoveredRow) {
      if (PRODUCTION_STEPS_COMPONENT_NAME === hoveredRow.component) {
        if (fromRecipe) {
          return (
            hoveredRow.index === index &&
            hoveredRow.parentIndex === sectionIndex &&
            hoveredRow.componentIndex === componentIndex &&
            stepComponentIndexesList.length === 0 // only for priors
          )
        }

        // there is no sectionIndex in reusable steps BO
        return (
          hoveredRow.index === index &&
          hoveredRow.componentIndex === componentIndex &&
          stepComponentIndexesList.length === 0 // only for priors
        )
      }

      if (
        PRODUCTION_STEPS_PRIOR_COMPONENT_NAME === hoveredRow.component
      ) {
        if (fromRecipe) {
          return (
            hoveredRow.index === index &&
            hoveredRow.parentIndex === sectionIndex &&
            hoveredRow.stepComponentIndexesList.join("") === stepComponentIndexesList.join("")
          )
        }
        return (
          hoveredRow.index === index &&
          hoveredRow.stepComponentIndexesList.join("") === stepComponentIndexesList.join("")
        )
      }
    }

    return false
  }, [hoveredRow, sectionIndex, fromRecipe])


  const computeStepComponentsFormValues = useCallback((stepComponents, stepIndex) => {

    const newFormValues = { ...formValues }

    const newSteps = [...steps]
    if (!newSteps[stepIndex]) return

    // production steps
    newSteps[stepIndex].stepComponents = stepComponents

    newSteps[stepIndex].stepComponents.forEach((_, stepComponentIndex) => {
      computeProductionStepsRecipeOnFieldChange({
        recipe: newFormValues,
        sectionIndex,
        stepIndex,
        stepComponentIndex,
        isProductionStep: fromRecipe
      })
    })

    newFormValues.sections[sectionIndex].productionSteps = newSteps
    setValues(newFormValues)
  },
    [steps, formValues, setValues, sectionIndex]
  )

  /**
   * update section netWeight when a reusable step (parent) coeff is changed
   */
  const updateSectionByReusableStepCoeff = useCallback((stepObj, newStepIndex) => {
    const newFormValues = cloneDeep(formValues)
    const section = newFormValues.sections[sectionIndex]
    section.productionSteps[newStepIndex] = stepObj
    computeSectionData(section, "productionSteps", "stepComponents", true)
    newFormValues.sections[sectionIndex] = section

    updateRecipeAfterReusableStepChange({
      recipe: newFormValues,
      section,
      reusableStepChanged: stepObj
    })

    setValues(newFormValues)
  },
    [formValues, setValues, sectionIndex]
  )

  const updateSectionInReusableStepDeletion = useCallback((steps) => {
    const newFormValues = cloneDeep(formValues)
    const section = newFormValues.sections[sectionIndex]
    section.productionSteps = steps
    computeSectionData(section, "productionSteps", "stepComponents", true)
    newFormValues.sections[sectionIndex] = section

    // todo update recipe 

    setValues(newFormValues)
  },
    [formValues, setValues, sectionIndex]
  )

  const _hasError = useCallback((index, field) => {
    return hasError(index, field, sectionIndex)
  },
    [sectionIndex, hasError]
  )

  return (
    <Box className="flexColumn" sx={{ position: "relative" }}>
      {steps.map((currentStep, index) => {
        const step = getProductionStepPointerObj(currentStep, fromRecipe && !isReusable)
        return (
          <Fragment key={step.objectId + index}>
            {step.isReusable ? (
              <ReusableStepFormRow
                onRowBlur={onRowBlur}
                onRowHover={onRowHover}
                hoveredRow={hoveredRow}
                onFieldFocus={onFieldFocus}
                onFieldBlur={onFieldBlur}
                onKeyUp={onKeyUp}
                errors={errors}
                machineTypes={machineTypes}
                kitchenAreas={kitchenAreas}
                transformationModes={transformationModes}
                setFieldValue={setFieldValue}
                stepValues={
                  getProductionStepPointerObj(formValues.sections[sectionIndex].productionSteps[index], fromRecipe) || []
                }
                setValues={setValues}
                parentStepIndex={index}
                fromRecipe={fromRecipe}
                index={index}
                steps={steps}
                isEdition={isEdition}
                sectionIndex={sectionIndex}
                coeff={currentStep.coeff}
                grossWeight={currentStep.grossWeight}
                cost={currentStep.realCost}
                stepNumbers={stepNumbers}
                updateSectionByReusableStepCoeff={updateSectionByReusableStepCoeff}
                updateSectionInReusableStepDeletion={updateSectionInReusableStepDeletion}
              />
            ) : (
              <Stack direction="row" spacing={0}>
                {isReusable && <StyledLeftBar />}
                <StyledAccordion
                  elevation={0}
                  defaultExpanded
                  square
                  disableGutters
                >
                  <StyledAccordionSummary
                    expandIcon={<img alt="chevron" src="/icons/chevron-down.svg" />}
                    onMouseEnter={() => onRowHover?.(COMPONENT_NAME, index, sectionIndex)}
                    onMouseLeave={onRowBlur}
                    usage="productionSteps"
                    isReusable={isReusable}
                  >
                    {isEdition ? (
                      <EditableStep
                        recipe={recipe}
                        steps={steps}
                        step={step}
                        inputValue={step}
                        index={index}
                        isEdition={isEdition}
                        isHover={_isStepHover(index)}
                        sectionIndex={sectionIndex}
                        onFieldFocus={onFieldFocus}
                        onFieldBlur={onFieldBlur}
                        onKeyUp={onKeyUp}
                        hasError={_hasError}
                        machineTypes={machineTypes}
                        kitchenAreas={kitchenAreas}
                        setFieldValue={setFieldValue}
                        isReusable={isReusable}
                        computeReusableStepsFormValues={computeReusableStepsFormValues}
                        fromRecipe={fromRecipe}
                        onClearFocus={onClearFocus}
                        allReusableSteps={sortReusableStepsByName(allReusableSteps)}
                        formValues={formValues}
                        setValues={setValues}
                        stepNumbers={stepNumbers}
                      />
                    ) : (
                      <StepPreview step={step} isReusable={isReusable} />
                    )}
                  </StyledAccordionSummary>
                  {step.stepComponents.map((stepComponent, indexComponent) => (
                    /** START SUPPLIER ITEM STEP COMPONENT CASE **/
                    (stepComponent.emptyComponent && !stepComponent.priorSteps) || stepComponent.supplierItem ? (
                      <StyledAccordion
                        elevation={0}
                        defaultExpanded
                        square
                        disableGutters
                        key={indexComponent}
                        sx={{ backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE }}
                      >
                        <StyledAccordionSummary
                          onMouseEnter={() => onRowHover?.(PRODUCTION_STEPS_COMPONENT_NAME, index, sectionIndex, indexComponent)}
                          onMouseLeave={onRowBlur}
                          componentName={PRODUCTION_STEPS_COMPONENT_NAME}
                          usage="stepComponents"
                          isReusable={isReusable}
                        >
                          {/* display autocomplete when in edition, not from recipe (sections) or not is a reusable parent step */}
                          {isEdition && (!fromRecipe || (fromRecipe && !isReusable)) ? (
                            <EditableStepComponent
                              recipe={recipe}
                              section={section}
                              steps={steps}
                              isEdition={isEdition}
                              sectionIndex={sectionIndex}
                              onFieldFocus={onFieldFocus}
                              onFieldBlur={onFieldBlur}
                              onKeyUp={onKeyUp}
                              hasError={_hasError}
                              stepComponent={stepComponent}
                              stepComponents={step.stepComponents}
                              parentStep={step}
                              indexComponent={indexComponent}
                              indexStep={index}
                              supplierItems={supplierItems}
                              transformationModes={transformationModes}
                              setFieldValue={setFieldValue}
                              isHover={_isStepComponentHover(index, indexComponent)}
                              fromRecipe={fromRecipe}
                              computeStepComponentsFormValues={computeStepComponentsFormValues}
                            />
                          ) :
                            (
                              <StepComponentPreview
                                stepComponent={stepComponent}
                                index={indexComponent}
                                supplierItem={stepComponent && stepComponent.supplierItem}
                              />
                            )
                          }
                        </StyledAccordionSummary>
                      </StyledAccordion>
                      /** END SUPPLIER ITEM STEP COMPONENT CASE **/
                    ) : (
                      /** START PRIOR STEPS COMPONENT CASE **/
                      stepComponent.priorSteps ? (
                        <StyledAccordion
                          elevation={0}
                          defaultExpanded
                          square
                          disableGutters
                          key={indexComponent + stepComponent.priorSteps.objectId}
                          sx={{ backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE }}
                        >
                          <PriorStepsStyledAccordionSummary
                            onMouseEnter={() => onRowHover?.(PRODUCTION_STEPS_COMPONENT_NAME, index, sectionIndex, indexComponent)}
                            onMouseLeave={onRowBlur}
                            componentName={PRODUCTION_STEPS_COMPONENT_NAME}
                            usage="stepComponents"
                            expandIcon={<img alt="chevron" src="/icons/chevron-down.svg" />}
                            isReusable={isReusable}
                          >
                            {/* display autocomplete when in edition, not from recipe (sections) or not is a reusable parent step */}
                            {isEdition && (!fromRecipe || (fromRecipe && !isReusable)) ? (
                              <EditableStepComponent
                                recipe={recipe}
                                steps={steps}
                                isEdition={isEdition}
                                sectionIndex={sectionIndex}
                                onFieldFocus={onFieldFocus}
                                onFieldBlur={onFieldBlur}
                                onKeyUp={onKeyUp}
                                hasError={_hasError}
                                stepComponent={stepComponent}
                                stepComponents={step.stepComponents}
                                parentStep={step}
                                indexComponent={indexComponent}
                                indexStep={index}
                                supplierItems={supplierItems}
                                transformationModes={transformationModes}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                fromRecipe={fromRecipe}
                                isHover={_isStepComponentHover(index, indexComponent)}
                                computeStepComponentsFormValues={computeStepComponentsFormValues}
                                section={section}
                                stepNumbers={stepNumbers}
                              />
                            ) : (
                              <PriorStepComponentPreview stepComponent={stepComponent} stepNumbers={stepNumbers} />
                            )
                            }
                          </PriorStepsStyledAccordionSummary>
                          <LoopPriorSteps
                            stepComponent={stepComponent}
                            isEdition={isEdition}
                            onRowBlur={onRowBlur}
                            onRowHover={onRowHover}
                            index={index}
                            sectionIndex={sectionIndex}
                            indexComponent={indexComponent}
                            hasError={_hasError}
                            recipe={recipe}
                            section={section}
                            steps={steps}
                            step={step}
                            transformationModes={transformationModes}
                            isStepComponentHover={_isStepComponentHover}
                            setFieldValue={setFieldValue}
                            fromRecipe={fromRecipe}
                            stepComponentsIndexesList={[indexComponent]}
                            isReusable={isReusable}
                          />
                        </StyledAccordion>
                      ) : null
                      /** END PRIOR STEPS COMPONENT CASE **/
                    )
                  ))}
                </StyledAccordion>
              </Stack>
            )}
          </Fragment>
        )
      })}
    </Box>
  )
}

export default Steps
