import React, { useState, useLayoutEffect } from "react"
import { makeStyles } from "@mui/styles"
import { COLORS } from "../../../utils"
import Typography from "@mui/material/Typography"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import { generateOrderUnitLabel, getStockUnitWeightLabel } from "../../../utils/ordersUtils"

const useStyles = makeStyles({
    containerGeneric: {
        width: 187,
        height: 117,
        padding: 8,
        borderRadius: 10,
        boxShadow: "0px 1px 3px rgba(0,0,0,.2)",
        marginBottom: 10,
        marginRight: 7,
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        border: "2px solid transparent"
    },
    container: {
        composes: "$containerGeneric",
        backgroundColor: COLORS.WHITE,
        color: COLORS.GREY_DISABLED
    },
    containerSelected: {
        composes: "$containerGeneric",
        backgroundColor: COLORS.WHITE,
        color: COLORS.GREY_DISABLED,
        border: `2px solid ${COLORS.PRIMARY_COLOR}`
    },
    containerInCart: {
        composes: "$containerGeneric",
        backgroundColor: COLORS.PRIMARY_COLOR,
        color: COLORS.WHITE
    },
    title: {
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical"
    },
    type: {
        fontSize: 10
    },
    infoRowGeneric: {
        display: "flex",
        justifyContent: "space-between"
    },
    firstInfoRow: {
        composes: "$infoRowGeneric",
        marginTop: "auto"
    },
    info: {
        fontSize: 10
    },
    tooltip: {
        top: 0,
        left: 0,
        padding: 8,
        borderRadius: 10,
        border: `1px solid ${COLORS.LIGHT_GREY}`,
        position: "absolute",
        backgroundColor: COLORS.WHITE,
        fontSize: 12,
        fontWeight: 700,
        textTransform: "uppercase",
        zIndex: 2,
        boxShadow: "0px 1px 3px rgba(0,0,0,.8)",
    }
}) 

const OrderCard = (props) => {
    const {
        supplierItem,
        isInCart,
        isSelected,
        actions,
        isValidOrder,
        selectedOrder
    } = props
    
    const classes = useStyles()
    const [showFullName, setShowFullName] = useState(false)
    const [isTitleHover, setIsTitleHover] = useState(false)
    const ref = React.createRef()
    
    useLayoutEffect(() => {
        if (ref.current.clientHeight < ref.current.scrollHeight && isTitleHover) {
            setShowFullName(true)
        }
        else {
            setShowFullName(false)
        }
    }, [ref, isTitleHover])
    
    const onSelectCard = () => {
        actions({
            method: "SELECT_CARD",
            data: {
                card: supplierItem
            }
        })
    }

    return (
        <>
            {selectedOrder && selectedOrder.orderStatus === "DONE" ?
                <div
                    className={isInCart ? classes.containerInCart : (isSelected ? classes.containerSelected : classes.container)}
                    onClick={() => { !isInCart && isValidOrder && onSelectCard() }}
                >
                    <div>
                        <Typography
                            className={classes.title}
                            onMouseEnter={() => { setIsTitleHover(true) }}
                            ref={ref}
                        >
                            {supplierItem.name}
                        </Typography>
                        {
                            showFullName &&
                            <div
                                className={classes.tooltip}
                                onMouseLeave={() => { setIsTitleHover(false) }}
                            >
                                {supplierItem.name}
                            </div>
                        }
                    </div>
                    <div>
                        <Typography className={classes.type}>
                            {supplierItemTypes[supplierItem.type].label}
                        </Typography>
                    </div>
                    <div className={classes.firstInfoRow}>
                        <Typography className={classes.info}>
                            PRIX:
                        </Typography>
                        <Typography className={classes.info}>
                            {`${supplierItem.units.order.price}€ / ${generateOrderUnitLabel(supplierItem, "order")}`}
                        </Typography>
                    </div>
                    <div className={classes.infoRowGeneric}>
                        <Typography className={classes.info}>
                            POIDS:
                        </Typography>
                        <Typography className={classes.info}>
                            {getStockUnitWeightLabel(supplierItem)}
                        </Typography>
                    </div>
                </div>
                :
                <div
                    className={isInCart ? classes.containerInCart : (isSelected ? classes.containerSelected : classes.container)}
                    onClick={() => { !isInCart && onSelectCard() }}
                >
                    <div>
                        <Typography
                            className={classes.title}
                            onMouseEnter={() => { setIsTitleHover(true) }}
                            ref={ref}
                        >
                            {supplierItem.name}
                        </Typography>
                        {
                            showFullName &&
                            <div
                                className={classes.tooltip}
                                onMouseLeave={() => { setIsTitleHover(false) }}
                            >
                                {supplierItem.name}
                            </div>
                        }
                    </div>
                    <div>
                        <Typography className={classes.type}>
                            {supplierItemTypes[supplierItem.type].label}
                        </Typography>
                    </div>
                    <div className={classes.firstInfoRow}>
                        <Typography className={classes.info}>
                            PRIX:
                        </Typography>
                        <Typography className={classes.info}>
                            {`${supplierItem.units.order.price}€ / ${generateOrderUnitLabel(supplierItem, "order")}`}
                        </Typography>
                    </div>
                    <div className={classes.infoRowGeneric}>
                        <Typography className={classes.info}>
                            POIDS:
                        </Typography>
                        <Typography className={classes.info}>
                            {getStockUnitWeightLabel(supplierItem)}
                        </Typography>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderCard