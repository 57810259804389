import React from "react"
import { makeStyles } from "@mui/styles"
import AddLotBloc from "../../../components/Order/Lot/AddLotBloc"
import LotPreview from "../../../components/Order/Lot/LotPreview"
import { COLORS } from "../../../utils"

const useStyles = makeStyles(() => ({
	actionRoot: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center"
	},
	bold: {
		fontWeight: 500,
		color: COLORS.DARK_GREY
	},
	textBloc: {
		textAlign: "center",
		fontWeight: 400,
		fontSize: 24,
		lineHeight: "43px",
		//marginTop: 50,
		color: COLORS.DARK_GREY
	},
	textBlocNoQuantityLeft: {
		textAlign: "center",
		fontWeight: 400,
		fontSize: 24,
		lineHeight: "43px",
		color: COLORS.DARK_GREY
	}
}))

const SupplierItemLotsRightBloc = (props) => {
	const {
		showAddLot,
		selectedLot,
		quantityLeft,
		actions,
		orderSupplierItem,
		stockZones,
		receptionDate,
	} = props

	const classes = useStyles()
	return (
		<div className={classes.actionRoot}>
			{
				selectedLot === null && quantityLeft > 0 &&
				<div className={classes.textBloc}>
					Quantité restante à déclarer : <span className={classes.bold}>{quantityLeft}</span>
				</div>
			}
			{
				!selectedLot && !showAddLot && quantityLeft === 0 &&
				<div className={classes.textBlocNoQuantityLeft}>
					Vous pouvez <span className={classes.bold}> générer des avoirs </span> ou <span className={classes.bold}> valider la réception </span>
				</div>
			}
			{
				!selectedLot && quantityLeft > 0 &&
				<AddLotBloc
					quantityLeft={quantityLeft}
					orderSupplierItem={orderSupplierItem}
					stockZones={stockZones}
					onCreateLot={actions}
					receptionDate={receptionDate}
				/>
			}
			{
				selectedLot &&
				<LotPreview
					lot={selectedLot}
					onDeleteLot={actions}
					onAddCreditNote={actions}
				/>
			}
		</div>
	)
}

export default SupplierItemLotsRightBloc