import React from "react"
import { Modal } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { COLORS } from "../utils"

const useStyles = makeStyles(() => ({
    fullScreenModal: {
        backgroundColor: COLORS.WHITE,
        opacity: 1,
        outline: "none",
        overflow: "scroll"
    },
    fullScreenModalContainer: {
        outline: "none",
        height: "100%",
        flex: 1,
    }
}))

const FullScreenWrapper = (props) => {
	const { children, open = true } = props
    const classes = useStyles()
    
    return (
        <Modal key="dispatch-modal" BackdropProps={{invisible: true}} className={classes.fullScreenModal} open={open}>
            <div className={classes.fullScreenModalContainer}>
                {children}
            </div>
        </Modal>
    )
}

export default FullScreenWrapper