import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Formik } from "formik"
import * as Yup from "yup"
import { makeStyles } from "@mui/styles"
import moment from "moment"

import { COLORS } from "../../utils"
import ProductionStepsFilterForm from "./ProductionStepsFilterForm"
import {
	getProductionStepsSiteSelector,
	getProductionStepsSitesSelector,
	getProductionStepsKitchenAreaSelector,
	getProductionStepsProductionDaySelector,
	isPSEGenerationDialogOpen,
	isGenerationForOneRecipe
} from "../../reducers/Production/ProductionSteps"
import { updateProductionStepsFilters, showProductionStepsSupervision, generateProductionStepExecutions, doPSEsExist } from "../../actions/ProductionSteps/ProductionStepExecutions"
import { confirm } from "../../components/react-await-dialog/react-await-dialog"
import DateFormDialog from "../../components/DateFormDialog"
import PSEGenerationForOneRecipeDialog from "./PSEGenerationForOneRecipeDialog"
import { openAppMessageSnackBar } from "../../actions/Utils/app"

const useStyles = makeStyles({
	wrapper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1
	},
	container: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: COLORS.GREY_SUPPLIER_BACKGROUND
	},
	error: {
		margin: "16px 0px"
	},
	pseCreationConfirm: {
		marginTop: 450,
		width: 500
	}
})

const ProductionStepsFilter = () => {
	const classes = useStyles()

	const dispatch = useDispatch()
	const sites = useSelector(getProductionStepsSitesSelector)
	const selectedSite = useSelector(getProductionStepsSiteSelector)
	const selectedKitchenArea = useSelector(getProductionStepsKitchenAreaSelector)
	const selectedProductionDay = useSelector(getProductionStepsProductionDaySelector)
	const [kitchenAreas, setKitchenAreas] = useState([])
	const openPSEGenerationDialog = useSelector(isPSEGenerationDialogOpen)
	const openGenerationForOneRecipe = useSelector(isGenerationForOneRecipe)

	useEffect(() => {
		const updatedKitchenAreas = sites.reduce((acc, site) => {
			return [...acc, ...site.kitchenAreas]
		}, [])
		setKitchenAreas(updatedKitchenAreas)
	}, [sites])

	const _submitForm = (values) => {
		const site = sites.find(site => site.objectId === values.site)
		const kitchenArea = kitchenAreas.find(kitchenArea => kitchenArea.objectId === values.kitchenArea)
		const productionDay = values.productionDay
		// save filters into local storage
		dispatch(updateProductionStepsFilters(site, kitchenArea, productionDay))
		// redirection
		dispatch(showProductionStepsSupervision())
	}

	const closePSEGenerationDialog = () => {
		dispatch({
			type: "TOGGLE_PSE_GENERATION_DIALOG",
			open: false
		})
	}

	const handleGeneratePSE = async (values) => {
		const pseExists = await doPSEsExist(values.date)

		if (pseExists) {
			const response = await confirm({
				message: "Les étapes pour ce jour de production ont déjà été générées. Confirmez-vous la régénération ? Toute modification sera perdue",
				confirmText: "Confirmer",
				classes: {
					paper: classes.pseCreationConfirm
				}
			})

			if (response) {
				closePSEGenerationDialog()
				dispatch(generateProductionStepExecutions({ productionDate: values.date, isNew: false }))
			}

			closePSEGenerationDialog()
		} else {
			closePSEGenerationDialog()
			dispatch(generateProductionStepExecutions({ productionDate: values.date }))
		}
	}

	const handleGeneratePSEForOneRecipe = async (values) => {
		const pseExists = await doPSEsExist(values.date, values.recipe && values.recipe.objectId)

		if (pseExists) {
			const response = await confirm({
				message: "Les étapes pour ce jour de production et cette recette ont déjà été générées. Confirmez-vous la régénération ? Toute modification sera perdue",
				confirmText: "Confirmer"
			})

			if (response) {
				closePSEGenerationDialog()
				const recipeLabel = values.recipe.uniqueCode + " - " + values.recipe.name
				dispatch(generateProductionStepExecutions({ productionDate: values.date, recipeLabel, recipeId: values.recipe.objectId, isNew: false }))
			}

			closePSEGenerationDialog()
		} else {
			dispatch(openAppMessageSnackBar("Aucune PSE trouvée", "error"))
			closePSEGenerationDialog()
		}
	}

	return (
		<div className={classes.wrapper}>
			<div className={classes.container}>
				<Formik
					validationSchema={Yup.object().shape({
						site: Yup.string().required("Veuillez sélectionner un site"),
						kitchenArea: Yup.string(),
						productionDay: Yup.string().required("Veuillez sélectionner un jour de production")
					})
					}
					initialValues={{
						site: selectedSite?.objectId || "",
						kitchenArea: selectedKitchenArea?.objectId || "",
						productionDay: moment.utc(selectedProductionDay).valueOf()
					}}
					onSubmit={_submitForm}
				>
					{({
						values,
						handleChange,
						setFieldValue,
						handleSubmit,
					}) => {
						return (
							<ProductionStepsFilterForm
								sites={sites}
								values={values}
								setFieldValue={setFieldValue}
								handleChange={handleChange}
								handleSubmit={handleSubmit}
							/>
						)
					}}
				</Formik>
			</div>

			<DateFormDialog
				title="Veuillez sélectionner le jour de production pour lequel vous souhaitez générer les étapes de production."
				onClose={closePSEGenerationDialog}
				open={openPSEGenerationDialog && !openGenerationForOneRecipe}
				onConfirm={handleGeneratePSE}
				buttonText="Générer les étapes de production"
				inputLabel="Jour de production"
			/>

			<PSEGenerationForOneRecipeDialog
				title="Veuillez sélectionner le jour et la recette pour laquelle vous souhaitez régénérer les étapes de production."
				onClose={closePSEGenerationDialog}
				open={openGenerationForOneRecipe && openPSEGenerationDialog}
				onConfirm={handleGeneratePSEForOneRecipe}
				buttonText="Regénérer les étapes de production"
				inputLabel="Jour de production"
			/>
		</div>
	)
}
export default ProductionStepsFilter

