import moment from "moment"
import { supplierItemTypes, productTypeToAdd } from "./supplierItemUtils"
import _cloneDeep from "lodash/cloneDeep"
import { groupBy } from "lodash"
import { roundNumber } from "../utils"
import { getStockUnitWeight } from "./ordersUtils"
import dayjs from "dayjs"

export const LOT_INVENTORY_MODE = "INVENTORY"
export const LOT_HISTORY_MODE = "HISTORY"
export const LOT_OUTPUT_MODE = "OUTPUT"
export const LOT_DAMAGE_MODE = "BROKEN"
export const LOT_RECEPTION_MODE = "RECEPTION"
export const LOT_DISPATCH_MODE = "DISPATCH"
export const UNKNOWN_LOT_GROUP_ID = "unknownGroup"
export const UNKNOWN_LOT_PRODUCT_TYPE_ID = "unknownProductType"

export const LOT_EVENTS_MODES = [
	LOT_INVENTORY_MODE,
	LOT_RECEPTION_MODE,
	LOT_OUTPUT_MODE,
	LOT_DAMAGE_MODE,
	LOT_DISPATCH_MODE
]

export const LOT_MODES_LABELS = {
	[LOT_RECEPTION_MODE]: "Réception",
	[LOT_INVENTORY_MODE]: "Inventaire",
	[LOT_OUTPUT_MODE]: "Sortie",
	[LOT_DAMAGE_MODE]: "Mise à la casse",
	[LOT_DISPATCH_MODE]: "Dispatch"
}

export const DLC_FILTER_TYPES = [
	{ key: "PASSED", label: "DLC dépassée" },
	{ key: "SHORT", label: "DLC courte" },
	{ key: "STANDARD", label: "DLC standard" }
]

const isGroupedByProductType = (key) => {
	const splittedKey = key.split("-")
	const type = splittedKey[0]
	if (type === supplierItemTypes.SALABLE_PRODUCT.key) {
		return supplierItemTypes.SALABLE_PRODUCT.key
	} else if (type === supplierItemTypes.PACKAGING_CONSUMABLE.key) {
		return supplierItemTypes.PACKAGING_CONSUMABLE.key
	}
	else if (type === UNKNOWN_LOT_PRODUCT_TYPE_ID) {
		return UNKNOWN_LOT_PRODUCT_TYPE_ID
	}
}

export const getGroupIngredientNameFromId = (id, groupIngredients) => {
	const group = groupIngredients.find(el => el.objectId === id)
	return group ? group.name : "Groupe inconnu"
}

// Get product type name to put in inventory and output headers
export const getSupplierItemTypeByKey = (key, productTypesOptions) => {
	if (key.split("_").shift() === "SALABLE") {
		const splittedKey = key.split("-")
		const productTypeKey = splittedKey[splittedKey.length - 1]
		const currentProductType = productTypesOptions.find(el => el.value === productTypeKey)
		const group = currentProductType ? currentProductType.label : "Inconnu"
		return group
	} else if (key.split("_").shift() === "PACKAGING") {
		const splittedKey = key.split("-")
		const productTypeKey = splittedKey[splittedKey.length - 1]
		const currentProductType = productTypeToAdd.find(el => el.value === productTypeKey)
		const group = currentProductType.value
		return group.replaceAll("_", " ")
	}
	else if (key.split("_").shift() === UNKNOWN_LOT_PRODUCT_TYPE_ID) {
		return "Anciens types de produit"
	}
}

export const getLotsGroupName = (key, groupIngredients, productTypesOptions) => {
	const groupName = isGroupedByProductType(key) ? getSupplierItemTypeByKey(key, productTypesOptions) : getGroupIngredientNameFromId(key, groupIngredients)
	return groupName
}

export function getLotMode(path) {
	if (path.startsWith("/lotIventory")) {
		return LOT_INVENTORY_MODE
	} else if (path.startsWith("/lotOutput")) {
		return LOT_OUTPUT_MODE
	} else {
		return LOT_INVENTORY_MODE
	}
}

export function getLotFilterModeLabel(mode) {
	switch (mode) {
		case LOT_INVENTORY_MODE:
			return "pour accéder à l'inventaire"
		case LOT_OUTPUT_MODE:
			return "pour effectuer une sortie"
		default:
			return ""
	}
}

export const getParamsFromPath = (pathname) => {
	const paths = pathname.split("/")

	if (pathname.startsWith("/lotInventory/")) {
		return {
			stockZone: paths[2]
		}
	}

	if (pathname.startsWith("/lotOutput/") && (paths.length === 4 || paths.length === 5)) {
		return {
			stockZone: paths[2],
			date: moment.utc(parseFloat(paths[3])).startOf("day").valueOf(),
		}
	}

	return null
}

const groupLotsByCharacter = (lots) => {
	const lotsForGroup = new Map()
	const characters = lots.map(lot => lot.orderSupplierItem.name.trim().charAt(0).toUpperCase())

	for (const char of characters) {
		const lotsForCharacter = lots.filter(lot => lot.orderSupplierItem.name.trim().charAt(0).toUpperCase() === char.toUpperCase())
		lotsForGroup.set(char, lotsForCharacter)
	}
	return lotsForGroup
}

const isLotWithGroupIngredient = (lot, group) => {
	return (
		lot.orderSupplierItem
		&& lot.orderSupplierItem.commercialName
		&& lot.orderSupplierItem.commercialName.group
		&& lot.orderSupplierItem.commercialName.group.objectId === group.objectId
	)
}

const isLotWithoutGroupIngredient = (lot) => lot.orderSupplierItem && !lot.orderSupplierItem.commercialName

const isWithoutValidProductType = (lot, productTypes) => lot.orderSupplierItem && lot.orderSupplierItem.productType && !productTypes.find(el => el.value === lot.orderSupplierItem.productType)

const isProductTypeWithGroupIngredient = (lot, type) => lot.orderSupplierItem && lot.orderSupplierItem.productType === type.value

export const sortLots = (lotsData, groupIngredients, newLot, productTypes) => {
	const sortedLots = new Map()

	// --------------------------------------------------------------- //
	// ----------------- RAW MATERIAL SUPPLIER ITEMS ----------------- //
	// --------------------------------------------------------------- //

	const rawMaterialLots = lotsData.filter((lot) => lot.orderSupplierItem.type && lot.orderSupplierItem.type === supplierItemTypes.RAW_MATERIAL.key)
	// lots with group
	for (const group of groupIngredients) {
		const lotsWithGroup = rawMaterialLots.filter(lot => isLotWithGroupIngredient(lot, group))
		if (newLot && newLot.orderSupplierItem.type === supplierItemTypes.RAW_MATERIAL.key && isLotWithGroupIngredient(newLot, group) && newLot.quantity === 0) {
			lotsWithGroup.push(newLot)
		}
		if (lotsWithGroup.length > 0) {
			const lotsForGroup = groupLotsByCharacter(lotsWithGroup)
			sortedLots.set(group.objectId, lotsForGroup)
		}
	}

	// lot without group
	const lotsWithoutGroup = rawMaterialLots.filter(lot => isLotWithoutGroupIngredient(lot))
	// lot to display manually even if quantity is 0
	if (newLot && newLot.orderSupplierItem.type === supplierItemTypes.RAW_MATERIAL.key && isLotWithoutGroupIngredient(newLot) && newLot.quantity === 0) {
		lotsWithoutGroup.push(newLot)
	}
	if (lotsWithoutGroup.length > 0) {
		const lotsForGroup = groupLotsByCharacter(lotsWithoutGroup)
		sortedLots.set(UNKNOWN_LOT_GROUP_ID, lotsForGroup)
	}

	// --------------------------------------------------------------- //
	// --------------- SALABLE PRODUCT SUPPLIER ITEMS ---------------- //
	// --------------------------------------------------------------- //
	const salableProductLots = lotsData.filter((lot) => lot.orderSupplierItem.type && lot.orderSupplierItem.type === supplierItemTypes.SALABLE_PRODUCT.key && lot.orderSupplierItem.productType)

	for (const type of productTypes) {
		const lotsWithGroup = salableProductLots.filter(lot => isProductTypeWithGroupIngredient(lot, type))
		// lot to display manually even if quantity is 0
		if (newLot && newLot.orderSupplierItem.type === supplierItemTypes.PACKAGING_CONSUMABLE.key && isProductTypeWithGroupIngredient(newLot, type) && newLot.quantity === 0) {
			lotsWithGroup.push(newLot)
		}
		if (lotsWithGroup.length > 0) {
			const lotsForGroup = groupLotsByCharacter(lotsWithGroup)
			sortedLots.set(supplierItemTypes.SALABLE_PRODUCT.key + "-" + type.value, lotsForGroup)
		}
	}

	const salableProductsLotsWithoutValidProductTypes = salableProductLots.filter(lot => isWithoutValidProductType(lot, productTypes))

	if (salableProductsLotsWithoutValidProductTypes.length > 0) {
		const lotsForGroup = groupLotsByCharacter(salableProductsLotsWithoutValidProductTypes)
		sortedLots.set(UNKNOWN_LOT_PRODUCT_TYPE_ID, lotsForGroup)
	}

	// ---------------------------------------------------------------------- //
	// ------------ PACKAGING CONSUMABLE PRODUCT SUPPLIER ITEMS ------------- //
	// ---------------------------------------------------------------------- //
	const packagingConsumableProductLots = lotsData.filter((lot) => lot.orderSupplierItem.type && lot.orderSupplierItem.type === supplierItemTypes.PACKAGING_CONSUMABLE.key && lot.orderSupplierItem.productType)

	for (const key of productTypeToAdd) {
		const lotsWithGroup = packagingConsumableProductLots.filter(lot => isProductTypeWithGroupIngredient(lot, key))
		// lot to display manually even if quantity is 0
		if (newLot && isProductTypeWithGroupIngredient(newLot, key) && newLot.quantity === 0) {
			lotsWithGroup.push(newLot)
		}
		if (lotsWithGroup.length > 0) {
			const lotsForGroup = groupLotsByCharacter(lotsWithGroup)

			sortedLots.set(supplierItemTypes.PACKAGING_CONSUMABLE.key + "-" + key.value, lotsForGroup)
		}
	}

	return sortedLots
}

export const filterLotsByGroupIngredient = (lotsData, filter) => {
	if (filter.length > 0) {
		for (const [key,] of lotsData) {
			if (filter.filter(el => el.value === key).length === 0) {
				lotsData.delete(key)
			}
		}
	}

	return lotsData
}

export const filterLotsBySearchValue = (lotsData, filter) => {
	const exp = new RegExp(filter.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), "i")

	for (const [group, value] of lotsData) {

		for (const [char, values] of value) {
			lotsData.get(group).set(char, [...values].filter(el => exp.test(el.orderSupplierItem.name)))
			if (lotsData.get(group).get(char).length === 0) {
				lotsData.get(group).delete(char)
			}
		}
		if (lotsData.get(group).size === 0) {
			lotsData.delete(group)
		}
	}

	return lotsData
}

export const filterSecondaryDLCsByDlc = (secondaryDlcData, filter, currentDate, warningDate) => {
	for (const [group, value] of secondaryDlcData) {
		for (const [char, values] of value) {
			let filteredSecondaryDlCs = []

			if (filter.includes("ALL")) {
				filteredSecondaryDlCs = [...values]
			}
			else {
				if (filter.includes("PASSED")) {
					const elements = values.filter(el => el.secondaryDLC < currentDate)
					filteredSecondaryDlCs.push(...elements)
				}

				if (filter.includes("SHORT")) {
					const elements = values.filter(el => currentDate <= el.secondaryDLC && el.secondaryDLC <= warningDate)
					filteredSecondaryDlCs.push(...elements)
				}

				if (filter.includes("STANDARD")) {
					const elements = values.filter(el => el.secondaryDLC > warningDate)
					filteredSecondaryDlCs.push(...elements)
				}
			}

			if (filteredSecondaryDlCs.length > 0) {
				secondaryDlcData.get(group).set(char, filteredSecondaryDlCs)
			}
			else {
				secondaryDlcData.get(group).delete(char)
			}
		}
	}
	return secondaryDlcData

}

export const filterLotsByDlc = (lotsData, filter, currentDate, warningDate, isInventory = false) => {
	for (const [group, value] of lotsData) {

		for (const [char, values] of value) {
			let filteredLots = []

			if (filter.includes("ALL")) {
				if (isInventory) {
					filteredLots = [...values]
				} else {
					// display lots that:
					// - have stock
					// - or don't have stock but have output event with production date equal to current date (the date selected on the UI)
					filteredLots = values.filter(el => {
						const hasValidDlc = el.dlc >= currentDate
						const hasStock = el.quantity > 0
						const hasOutputOnTime = el.events?.some(event => event.mode === LOT_OUTPUT_MODE && dayjs(event.productionDate).diff(currentDate, "day") === 0)
						return hasValidDlc && (hasStock || !!hasOutputOnTime)
					})
				}
			}
			else {
				if (filter.includes("PASSED")) {
					const elements = values.filter(el => el.dlc < currentDate)
					filteredLots.push(...elements)
				}

				if (filter.includes("SHORT")) {
					const elements = values.filter(el => currentDate <= el.dlc && el.dlc <= warningDate)
					filteredLots.push(...elements)
				}

				if (filter.includes("STANDARD")) {
					const elements = values.filter(el => el.dlc > warningDate)
					filteredLots.push(...elements)
				}
			}

			if (filteredLots.length > 0) {
				lotsData.get(group).set(char, filteredLots)
			}
			else {
				lotsData.get(group).delete(char)
			}
		}

		if (lotsData.get(group).size === 0) {
			lotsData.delete(group)
		}
	}
	return lotsData
}

const getRelatedOutput = (outputLeft, supplierItemId) => outputLeft.filter(inventory => inventory.ingredientId === supplierItemId)

export const filterQuantityOutput = (lotsData, outputInventoryData, date, mode) => {
	for (const [, value] of lotsData) {
		for (const [, values] of value) {
			const originalOutputLeft = _cloneDeep(outputInventoryData)
			let outputLeft = _cloneDeep(outputInventoryData)

			values.sort((a, b) => a.dlc < b.dlc ? -1 : 1)

			// we calculate all output already done by supplier item id
			const quantityAlreadyOutputBySupplierItem = {}
			values.forEach((lot) => {
				const eventsOutput = lot.events && lot.events.length > 0 ? lot.events.filter(event => event.mode === "OUTPUT" && event.productionDate === date) : []
				if (!quantityAlreadyOutputBySupplierItem[lot.orderSupplierItem.supplierItemId]) {
					quantityAlreadyOutputBySupplierItem[lot.orderSupplierItem.supplierItemId] = 0
				}

				quantityAlreadyOutputBySupplierItem[lot.orderSupplierItem.supplierItemId] = eventsOutput
					.map(event => event.quantity)
					.reduce((a, b) => {
						return a + (b * lot.orderSupplierItem.units.stock.weight)
					}, quantityAlreadyOutputBySupplierItem[lot.orderSupplierItem.supplierItemId])
			})

			values.forEach(currentValue => {
				//we get the quantity already output for the current value
				const currentQuantityAlreadyOutput = quantityAlreadyOutputBySupplierItem && quantityAlreadyOutputBySupplierItem[currentValue.orderSupplierItem.supplierItemId] ? quantityAlreadyOutputBySupplierItem[currentValue.orderSupplierItem.supplierItemId] : 0
				let outputInventoryRelated = getRelatedOutput(outputLeft, currentValue.orderSupplierItem.supplierItemId)
				const originalRelatedOutputInventory = getRelatedOutput(originalOutputLeft, currentValue.orderSupplierItem.supplierItemId)
				let outputWeight = 0
				outputInventoryRelated.forEach(outputInventory => {
					outputWeight += outputInventory.weight
				})

				currentValue.hasAlreadyQuantityOutput = currentQuantityAlreadyOutput > 0
				/** outputWeight = La quantité qu'on DOIT sortir **/
				outputWeight -= currentQuantityAlreadyOutput // we decrease the output already done

				let totalOutputWeight = 0
				originalRelatedOutputInventory.forEach(inventory => totalOutputWeight += inventory.weight)
				currentValue.quantityOutput = 0 // Quantité restante à sortir

				//totalOutputWeight = Le besoin total à sortir
				currentValue.totalOutputWeight = originalRelatedOutputInventory.length > 0 ? totalOutputWeight - currentQuantityAlreadyOutput : 0
				if (outputWeight > 0) {
					/** quantityWeight = Quantité DISPONIBLE **/
					let quantityWeight = currentValue.quantity * currentValue.orderSupplierItem.units.stock.weight
					// if the quantity available is < than all weight we need to output
					if (quantityWeight < outputWeight) {
						currentValue.quantityOutput = quantityWeight > 0 ? roundNumber(quantityWeight / currentValue.orderSupplierItem.units.stock.weight, 1) : 0
						outputWeight -= quantityWeight
						outputLeft = reduceQuantityWeightLeft(outputLeft, currentValue.orderSupplierItem.supplierItemId, quantityWeight)
					} else {
						let quantityOutput = Math.ceil(outputWeight / currentValue.orderSupplierItem.units.stock.weight)
						//if the quantity to output is bigger than what we have in stock, we just output the remaining stock
						quantityOutput = quantityOutput <= currentValue.quantity ? quantityOutput : currentValue.quantity
						currentValue.quantityOutput = quantityOutput > 0 ? quantityOutput : 0
						outputWeight = 0
						outputLeft = reduceQuantityWeightLeft(outputLeft, currentValue.orderSupplierItem.supplierItemId, -1)
					}
				}
			})
		}
	}

	lotsData = removeLotsNotNeeded(lotsData, mode)

	return lotsData
}

export const removeLotsNotNeeded = (lotsData, mode) => {
	for (const [group, value] of lotsData) {
		for (const [char, values] of value) {
			let lotsToDisplay = []
			if (mode === LOT_OUTPUT_MODE) {
				lotsToDisplay = values.filter(elm => elm.hasAlreadyQuantityOutput || elm.quantity > 0)
			} else {
				lotsToDisplay = values.filter(elm => elm.quantity > 0)
			}

			lotsData.get(group).set(char, [...values].filter(el => typeof lotsToDisplay.find(elem => elem.objectId === el.objectId) !== "undefined"))
			if (lotsData.get(group).get(char).length === 0) {
				lotsData.get(group).delete(char)
			}
		}

		if (lotsData.get(group).size === 0) {
			lotsData.delete(group)
		}
	}

	return lotsData
}
const generateHypotheticalLotId = (lot) => {
	return lot.objectId + lot.dlc + lot.lotNumber
}

export const compareLots = (lot1, lot2) => {
	if (lot1.orderSupplierItem.name < lot2.orderSupplierItem.name) {
		return -1
	} else if (lot1.orderSupplierItem.name > lot2.orderSupplierItem.name) {
		return 1
	} else {
		return lot1.dlc < lot2.dlc ? -1 : 1
	}
}

export const compareSecondaryDLCs = (secondaryDLC1, secondaryDLC2) => {
	if (secondaryDLC1.orderSupplierItem.name < secondaryDLC2.orderSupplierItem.name) {
		return -1
	} else if (secondaryDLC1.orderSupplierItem.name > secondaryDLC2.orderSupplierItem.name) {
		return 1
	} else {
		return secondaryDLC1.secondaryDLC < secondaryDLC2.secondaryDLC ? -1 : 1
	}
}

export const isLotOrSecondaryDLCCardSelected = (selectedCard, card) => {
	if (!selectedCard) return false

	if (selectedCard.hypothetical) {
		const selectedCardId = generateHypotheticalLotId(selectedCard)
		const cardId = generateHypotheticalLotId(card)
		return selectedCardId === cardId
	}

	return card.objectId === selectedCard.objectId
}

export const formatLotOrSecondaryDLCCard = (document, collection, mode, currentDate) => {
	if (collection === "Lot") {
		const lot = document
		const formattedLot = {
			collection,
			name: lot.orderSupplierItem.name,
			dlc: dayjs(lot.dlc).format("DD/MM/YYYY"),
			dlcTimestamp: lot.dlc,
			lotNumber: lot.lotNumber,
			quantity: lot.quantity,
			quantityOutput: lot.quantityOutput,
			hasAlreadyQuantityOutput: lot.hasAlreadyQuantityOutput,
			unit: getStockUnitWeight(lot.orderSupplierItem),
			weight: lot.orderSupplierItem.units && lot.orderSupplierItem.units.stock && lot.orderSupplierItem.units.stock.weight,
			hypothetical: lot.hypothetical
		}

		const dates = mode === LOT_INVENTORY_MODE
			? (document && document.events && document.events.filter(e => e.mode === "INVENTORY").map(e => e.date && dayjs.utc(e.date).startOf("day").valueOf())) || []
			: (document && document.events && document.events.filter(e => e.mode === "OUTPUT" && e.productionDate === currentDate).map(e => e.date && dayjs.utc(e.date).startOf("day").valueOf())) || []

		const isUpdated = dates.includes(dayjs.utc().startOf("day").valueOf())

		formattedLot.isUpdated = isUpdated

		const isGrey = document.display ? false : +document.quantity === 0
		formattedLot.isGrey = isGrey

		if (lot.hypothetical) {
			formattedLot.unit = "Kg"
		}
		return formattedLot
	}
	if (collection === "SecondaryDLC") {
		const secondaryDLC = document
		const events = (document.events || []).map(e => e.date && dayjs.utc(e.date).startOf("day").valueOf())
		// is updated =events contain today
		const isUpdated = events.includes(dayjs.utc().startOf("day").valueOf())
		return {
			collection,
			name: secondaryDLC.name,
			dlc: dayjs(secondaryDLC.secondaryDLC).format("DD/MM/YYYY"),
			dlcTimestamp: secondaryDLC.secondaryDLC,
			lotNumber: secondaryDLC.lotNumber,
			quantity: secondaryDLC.quantity,
			unit: "Kg",
			isUpdated
		}
	}
	return document
}

/**
 * hypothetical lots for remaining quantity
 * productionItems expectedProduction for each recipe - total of quantityOutput of lots (with the same supplierItem)
 * @param {*} lotsData 
 * @param {*} outputInventoryData
 * @param {*} stockZone
 * @param {*} allSubstituteLots
 * @param {*} date
 * @param {*} supplierItemsHypotheticalTreated
 * @returns
 */
export const addHypotheticalOutputLots = (lotsData, outputInventoryData, stockZone, allSubstituteLots, date, supplierItemsHypotheticalTreated) => {
	const lots = _cloneDeep(lotsData)
	const supplierItemIds = []
	for (const [group, value] of lots) {
		for (const [char, values] of value) {
			values.forEach(elm => supplierItemIds.push(elm.orderSupplierItem.supplierItemId))
			const groupedLotsBySupplierItem = groupBy(values, "orderSupplierItem.supplierItemId")
			for (const groupedLots of Object.values(groupedLotsBySupplierItem)) {
				// total of the quantityOutput of the displayed outputted lots
				// with testing the first item we can be sure to have any item in the array
				// because below we use the last item as hypothetical lot
				if (groupedLots[0] && groupedLots[0].totalOutputWeight !== null) {
					let totalQuantityOutput = 0
					for (const lot of groupedLots) {
						if (lot.orderSupplierItem.units && lot.orderSupplierItem.units.stock) {
							totalQuantityOutput += lot.quantityOutput * lot.orderSupplierItem.units.stock.weight
						}
					}

					// use the last item as copy to respect the order
					// if order is not important, we can copy any item in the array as hypothetical lot
					const lastGroupedLot = groupedLots[groupedLots.length - 1]
					const quantityMissing = lastGroupedLot?.totalOutputWeight - totalQuantityOutput
					if (quantityMissing > 0 && (!stockZone || (lastGroupedLot.stockZone.objectId === stockZone.objectId))) {
						const supplierItemHypothetical = supplierItemsHypotheticalTreated.find(supplierItemHypothetical => supplierItemHypothetical.objectId === lastGroupedLot.orderSupplierItem.supplierItemId)
						const hasBeenTreated = supplierItemHypothetical && supplierItemHypothetical.hypotheticalLotsTreated.find(hypotheticalLot => hypotheticalLot.stockZoneId === lastGroupedLot.stockZone.objectId && hypotheticalLot.date === date)
						if (!hasBeenTreated) {
							let quantitySubstituteOutput = getQuantitySubstituteAlreadyOutput(allSubstituteLots, lastGroupedLot.orderSupplierItem.supplierItemId, date)
							const newGroupedLots = [
								...groupedLots,
								{
									...lastGroupedLot,
									objectId: generateHypotheticalLotId(lastGroupedLot),
									hypothetical: true,
									// copy the last item
									quantityOutput: roundNumber((quantityMissing - quantitySubstituteOutput), 2),
									hasAlreadyQuantityOutput: false,

								},
							]

							const mergedLots = [...lots.get(group).get(char), ...newGroupedLots]
							lots.get(group).set(char, mergedLots.filter((lot, index) => mergedLots.indexOf(lot) === index))
						}
					}
				}
			}
		}
	}

	// handle hypothetical lot without existing similar lot
	const rawOutputInventoryDataLeft = outputInventoryData.filter(elm => {
		const supplierItemHypothetical = supplierItemsHypotheticalTreated.find(supplierItemHypothetical => supplierItemHypothetical.objectId === elm.ingredientId)
		const hasBeenTreated = supplierItemHypothetical && supplierItemHypothetical.hypotheticalLotsTreated.find(hypotheticalLot => hypotheticalLot.stockZoneId === stockZone.objectId && hypotheticalLot.date === date)

		return !supplierItemIds.includes(elm.ingredientId) && elm.nbUnit > 0 && (!stockZone || (elm.storageArea === stockZone.name)) && !hasBeenTreated
	})

	// concat nb unit of same ingredient id separated into different product type in one
	const outputInventoryDataLeft = Object.values(rawOutputInventoryDataLeft.reduce((acc, { ingredientId, ingredientName, nbUnit, units }) => {
		const unitWeightMissing = units && units.stock ? nbUnit * units.stock.weight : 1
		acc[ingredientId] = { ingredientId, ingredientName, nbUnit: (acc[ingredientId] ? acc[ingredientId].nbUnit : 0) + unitWeightMissing, units }
		return acc
	}, {}))

	if (outputInventoryDataLeft.length > 0 && lots.size >= 0) {
		const formattedOutputInventoryLeft = outputInventoryDataLeft.map(({ ingredientId, ingredientName, nbUnit, units }) => {
			let quantitySubstituteOutput = getQuantitySubstituteAlreadyOutput(allSubstituteLots, ingredientId, date)

			return (
				{
					objectId: ingredientId,
					supplierItemId: ingredientId,
					orderSupplierItem: { name: ingredientName, units, supplierItemId: ingredientId, type: "RAW_MATERIAL" },
					hypothetical: true,
					quantityOutput: roundNumber((nbUnit - quantitySubstituteOutput), 2),
					hasAlreadyQuantityOutput: false,
					stockZone: stockZone
				}
			)
		})

		const lotsForGroup = new Map()
		const characters = formattedOutputInventoryLeft.map(lot => lot.orderSupplierItem.name.trim().charAt(0).toUpperCase())

		for (const char of characters) {
			const lotsForCharacter = formattedOutputInventoryLeft.filter(lot => lot.orderSupplierItem.name.trim().charAt(0) === char.toUpperCase())
			lotsForGroup.set(char, lotsForCharacter)
		}

		lots.set(UNKNOWN_LOT_GROUP_ID, lotsForGroup)
	}

	return lots
}

export const reduceQuantityWeightLeft = (outputLeft, supplierItemId, quantityWeightToReduce) => {
	const outputInventoryRelated = outputLeft.filter(inventory => inventory.ingredientId === supplierItemId)
	outputInventoryRelated.forEach(outputInventory => {
		// -1 means there is not output inventory left
		if (quantityWeightToReduce === -1) {
			outputInventory.weight = 0
		} else {
			if (outputInventory.weight >= quantityWeightToReduce) {
				outputInventory.weight -= quantityWeightToReduce
				quantityWeightToReduce = 0
			} else {
				quantityWeightToReduce -= outputInventory.weight
				outputInventory.weight = 0
			}
		}
	})

	return outputLeft
}

export const filteredOutputLots = (lotsData, outputInventoryData, selectedDate) => {
	const supplierItemsIds = outputInventoryData?.map((supplierItem) => supplierItem.ingredientId)

	if (supplierItemsIds.length > 0) {
		for (const [group, value] of lotsData) {
			for (const [char, values] of value) {
				lotsData.get(group).set(char, [...values].filter(el => {
					// a lot with quantityOutput < 1 is not displayed
					if (el.quantityOutput < 1) return false

					return (el.hypothetical || (supplierItemsIds.includes(el.orderSupplierItem.supplierItemId) && el.quantity > 0))
						&&
						(el.quantityOutput > 0 || el.events.filter(e => e.mode === "OUTPUT" && e.productionDate === selectedDate).length > 0)
				}
				))
				if (lotsData.get(group).get(char).length === 0) {
					lotsData.get(group).delete(char)
				}
			}
			if (lotsData.get(group).size === 0) {
				lotsData.delete(group)
			}
		}
	} else {
		lotsData = []
	}

	return lotsData
}

export const filterOnRawMaterial = (lotsData) => {
	for (const [group, value] of lotsData) {
		for (const [char, values] of value) {
			lotsData.get(group).set(char, [...values].filter(el => el.orderSupplierItem.type === "RAW_MATERIAL"))
			if (lotsData.get(group).get(char).length === 0) {
				lotsData.get(group).delete(char)
			}
		}
		if (lotsData.get(group).size === 0) {
			lotsData.delete(group)
		}
	}

	return lotsData
}

export const getLotInventoryErrorObject = () => {
	return {
		type: "LOT_INVENTORY_OPEN_DETAIL_SNACKBAR",
		inventoryOutputSnackBar: {
			open: true,
			duration: 5000,
			type: "error",
			message: "Erreur lors du téléchargement de l'inventaire."
		}
	}
}

export const getLotsIngredientIds = (lotsData) => {
	let ids = []
	if (lotsData) {
		for (const [, value] of lotsData) {
			for (const [, values] of value) {
				values.forEach(elm => ids.push(elm.orderSupplierItem.supplierItemId))
			}
		}
	}
	return ids
}

export const cleanHypotheticalLots = (lots, hypotheticalLotRemoved) => {
	for (const [group, value] of lots) {
		for (const [char, values] of value) {
			values.forEach((currentLot, index) => {
				if (currentLot.hypothetical && currentLot.objectId === hypotheticalLotRemoved.objectId) {
					lots.get(group).get(char).splice(index, 1)
					if (lots.get(group).get(char).length === 0) {
						lots.get(group).delete(char)
						if (lots.get(group).size === 0) {
							lots.delete(group)
						}
					}
				}
			})
		}
	}
}

export const getQuantitySubstituteAlreadyOutput = (allSubstituteLots, supplierItemId, date) => {
	let quantitySubstituteOutput = 0
	allSubstituteLots.forEach(substituteLot => {
		const weight = substituteLot.orderSupplierItem && substituteLot.orderSupplierItem.units && substituteLot.orderSupplierItem.units.stock.weight ? substituteLot.orderSupplierItem.units.stock.weight : 1
		substituteLot.events.forEach(event => {
			if (event.mode === "OUTPUT" && event.productionDate === date && event.originalSupplierItem && event.originalSupplierItem.objectId === supplierItemId) {
				quantitySubstituteOutput += event.quantity * weight
			}
		})
	})

	return quantitySubstituteOutput
}