import Parse from "parse"
import { escapeRegExp } from "lodash"
import moment from "moment"
import dayjs from "dayjs"

import { getStockZone } from "../site/parseSiteManager"
import { parseLimitRequest } from "../../utils"
import { LOT_OUTPUT_MODE } from "../../utils/lotInventoryUtils"
import { getSupplierItemWithId } from "../suppliers/supplierItems/parseSupplierItemManager"

const Lot = Parse.Object.extend("Lots")
const OrderSupplierItem = Parse.Object.extend("OrderSupplierItem")
const Supplier = Parse.Object.extend("Suppliers")
const StockZone = Parse.Object.extend("StockZone")
const SupplierItem = Parse.Object.extend("SupplierItems")

export async function getLot({ id, toJson = true, include = [], select = [] }) {
	try {
		const lotQuery = new Parse.Query(Lot)
			.equalTo("objectId", id)

		if (include && include.length) {
			lotQuery.include(include)
		}
		if (select && select.length) {
			lotQuery.select(select)
		}
		const lot = await lotQuery
			.first() || null

		return lot ? toJson ? lot.toJSON() : lot : null
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function createLot(values, orderSupplierItem) {
	try {
		const lot = new Lot()

		lot.set("dlc", moment.utc(values.dlc).startOf("day").valueOf())
		lot.set("lotNumber", values.lotNumber)
		lot.set("quantity", values.quantity) // will change when lot is updated
		lot.set("initialQuantity", values.quantity) // will never change
		lot.set("events", [{
			mode: "RECEPTION",
			user: Parse.User.current().toJSON(),
			date: moment.utc().valueOf(),
			quantity: parseFloat(values.quantity.toFixed(3)),
		}])
		lot.set("orderSupplierItem", orderSupplierItem)
		lot.set("receptionDate", values.receptionDate)

		const parseStockZone = await getStockZone({ stockZoneId: values.stockZone.objectId, toJson: false })
		const supplierItem = values.orderSupplierItem && values.orderSupplierItem.supplierItemId ? await getSupplierItemWithId(values.orderSupplierItem.supplierItemId, [], false) : null

		lot.set("stockZone", parseStockZone)
		lot.set("supplierItem", supplierItem)

		return Promise.resolve(lot)
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getLots(stockZone, include = [], toJson = true) {
	try {
		const lots = await new Parse.Query(Lot)
			.equalTo("stockZone", stockZone)
			.include(include)
			.limit(parseLimitRequest)
			.find() || []

		return toJson ? lots.map(lot => lot.toJSON()) : lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getSelectingLots(stockZone = null, select, include = [], toJson = true) {
	try {
		let lots = new Parse.Query(Lot)
			.select(select)
			.include(include)

		if (stockZone) {
			lots.equalTo("stockZone", stockZone)
		}

		lots = await lots.limit(parseLimitRequest).find() || []

		return toJson ? lots.map(lot => lot.toJSON()) : lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getLotsEvents(stockZone, productionDate, include = [], filters) {
	try {
		const query = await new Parse.Query(Lot)
			.equalTo("stockZone", stockZone)
			.equalTo("events.mode", LOT_OUTPUT_MODE)
			.equalTo("events.productionDate", productionDate)
			.include(include)

		if (filters?.search) {
			const regex = new RegExp(escapeRegExp(filters.search), "ig")
			const innerOrderSupplierItemQuery = new Parse.Query(OrderSupplierItem)
				.matches("name", regex)
			query.matchesQuery("orderSupplierItem", innerOrderSupplierItemQuery)
		}

		if (filters?.rowsPerPage) {
			query.limit(filters?.rowsPerPage)
		} else {
			query.limit(25)
		}

		if (filters?.page) {
			query.skip(filters.page)
		}

		const lots = await query.withCount().find()

		return lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function updateLot(lotId, quantity, events) {
	try {
		const lot = await new Parse.Query(Lot)
			.equalTo("objectId", lotId)
			.first()

		if (lot) {
			lot.set("quantity", quantity)
			lot.set("events", events)

			const newLot = await lot.save()

			return newLot.toJSON()
		}

		return null
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function deleteLot(lotId, toJson = true) {
	try {
		const lot = await new Parse.Query(Lot)
			.include("creditsNotes")
			.equalTo("objectId", lotId)
			.first()

		if (lot.has("creditNotes")) {
			for (const creditNote of lot.get("creditNotes")) {
				await creditNote.destroy()
			}
		}

		const deletedLot = await lot.destroy()

		return toJson ? deletedLot.toJSON() : deletedLot
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getLotsBySupplierItemsAndStockZones(supplierItemsIds, stockZones, filterQuantity = true, toJson = true) {
	try {
		const orderSupplierItemsQuery = await new Parse.Query(OrderSupplierItem)
			.containedIn("supplierItemId", supplierItemsIds)

		const query = new Parse.Query(Lot)
			.matchesQuery("orderSupplierItem", orderSupplierItemsQuery)
			.include(["stockZone", "orderSupplierItem.commercialName.group"])

		if (filterQuantity) {
			query.greaterThan("quantity", 0)
		}

		if (stockZones.length) {
			const stockZonesIds = stockZones.map((stockZone) => stockZone.objectId)
			const stockZonesQuery = await new Parse.Query(StockZone)
				.containedIn("objectId", stockZonesIds)

			query.matchesQuery("stockZone", stockZonesQuery)
		}

		const lots = await query.limit(parseLimitRequest)
			.find() || []

		return toJson ? lots.map(el => el.toJSON()) : lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}

export async function getLotsForDispatch(subcontractorProduct, stockZones, productDispatch, toJson = true) {
	try {
		const productDispatchDlc = moment.utc(productDispatch.productionDate).add(productDispatch.lifeTime, "days").valueOf()

		const orderSupplierItemsQuery = await new Parse.Query(OrderSupplierItem)
			.equalTo("supplierItemId", subcontractorProduct.name.objectId)

		const stockZonesIds = stockZones.map((stockZone) => stockZone.objectId)
		const stockZonesQuery = await new Parse.Query(StockZone)
			.containedIn("objectId", stockZonesIds)

		const lots = await new Parse.Query(Lot)
			.equalTo("dlc", productDispatchDlc)
			.matchesQuery("stockZone", stockZonesQuery)
			.matchesQuery("orderSupplierItem", orderSupplierItemsQuery)
			.include(["stockZone", "orderSupplierItem.commercialName.group"])
			.ascending("createdAt")
			.limit(parseLimitRequest)
			.find() || []

		return toJson ? lots.map(el => el.toJSON()) : lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}

/**
 * 
 * @param {Set} toOutputSupplierItemIds 
 * @param {Set} allLotsSupplierItemIds 
 * @returns a set containing supplier item ids 
 */
export async function getSubstituteIds(toOutputSupplierItemIds, allLotsSupplierItemIds) {

	const substituteIds = new Set()
	await new Parse.Query(SupplierItem)
		.containedIn("objectId", Array.from(toOutputSupplierItemIds))
		.select("substitutionList")
		.each(supplierItem => {
			const itemSubstituteIds = supplierItem.get("substitutionList")?.map(substitute => substitute.objectId)
			if (!itemSubstituteIds) {
				return
			}
			itemSubstituteIds.forEach(substituteId => {
				if (allLotsSupplierItemIds.has(substituteId) && !toOutputSupplierItemIds.has(substituteId)) {
					// this substitute is in our page and not in toOutputSupplierItemIds
					substituteIds.add(substituteId)
				}
			})
		})
	return substituteIds
}

export async function getLotsByOrderSupplierItem(orderSupplierItems, select = ["quantity", "orderSupplierItem"], toJson = true) {
	try {
		const lots = await new Parse.Query(Lot)
			.select(select)
			.containedIn("orderSupplierItem", orderSupplierItems)
			.limit(parseLimitRequest)
			.find() || []

		return toJson ? lots.map(el => el.toJSON()) : lots
	}
	catch (e) {
		return Promise.reject(e)
	}
}
/**
 * LOADING LOTS FOR HISTORY VIEW
 * @param {*} filters 
 * @param {*} toJSON 
 * @returns 
 */
export async function loadFilteredLotsForHistory(filters = {}, toJSON = true) {
	try {

		const lotsQuery = new Parse.Query(Lot)
			.notEqualTo("deleted", true)
			.descending("receptionDate")
			.include("supplierItem.supplier")
			.select(["supplierItem", "receptionDate", "lotNumber", "dlc"])

		if (filters.name && !filters.supplier) {
			const supplierItemQuery = new Parse.Query(SupplierItem)
				.matches("name", new RegExp(filters.name, "i"))
			lotsQuery.matchesQuery("supplierItem", supplierItemQuery)
		}

		if (filters.supplier) {
			const supplierQuery = new Parse.Query(Supplier)
				.matches("name", new RegExp(filters.supplier, "i"))
			const supplierItemQuery = new Parse.Query(SupplierItem)
				.matchesQuery("supplier", supplierQuery)
			if (filters.name) {
				supplierItemQuery.matches("name", new RegExp(filters.name, "i"))
			}
			lotsQuery.matchesQuery("supplierItem", supplierItemQuery)
		}

		if (filters.lotNumber) {
			lotsQuery.matches("lotNumber", new RegExp(filters.lotNumber, "i"))
		}

		if (filters.receptionDateUnique) {
			lotsQuery.exists("receptionDate")
			lotsQuery.greaterThanOrEqualTo("receptionDate", dayjs(filters.receptionDateUnique).startOf("day").valueOf())
			lotsQuery.lessThanOrEqualTo("receptionDate", dayjs(filters.receptionDateUnique).endOf("day").valueOf())
		}

		if (filters.receptionDate && !filters.receptionDateUnique) {
			const start = dayjs(filters.receptionDate.start).startOf("day").valueOf()
			const end = dayjs(filters.receptionDate.end).endOf("day").valueOf()
			lotsQuery.exists("receptionDate")
			lotsQuery.greaterThanOrEqualTo("receptionDate", start)
			lotsQuery.lessThanOrEqualTo("receptionDate", end)
		}

		if (filters.dlc) {
			lotsQuery.exists("dlc")
			lotsQuery.greaterThanOrEqualTo("dlc", dayjs(filters.dlc).startOf("day").valueOf())
			lotsQuery.lessThanOrEqualTo("dlc", dayjs(filters.dlc).endOf("day").valueOf())
		}

		if (!Object.keys(filters).length) {
			lotsQuery.limit(100)
		}
		else {
			lotsQuery.limit(parseLimitRequest)
		}

		const lots = await lotsQuery.find()

		return toJSON ? lots.map(lot => lot.toJSON()) : lots
	} catch (e) {
		return Promise.reject(e)
	}
}
