import { getData } from "../index"

const defaultState = {
	productionSubstitutions: [],
  productionSubstitution: [],
  productionSubstitutionSnackbar: { open: false, type: "", message: "", duration: 1000 },
	supplierItemsOptions: []
}

export default function (state = defaultState, action) {
	switch (action && action.type) {
		case "PRODUCTION_SUBSTITUTIONS_LOADED":
			return {
				...state,
				productionSubstitutions: action.productionSubstitutions,
			}
		case "PRODUCTION_SUBSTITUTION_LOADED":
			return {
				...state,
        productionSubstitution: action.productionSubstitution,
			}
		case "PRODUCTION_SUBSTITUTION_SNACKBAR_LOADED":
			return {
				...state,
				productionSubstitutionSnackbar: action.snackbar,
			}
		case "PRODUCITON_SUBSTITUTIONS_SUPPLIER_ITEMS_OPTIONS_LOADED":
			return {
				...state,
				supplierItemsOptions: action.supplierItemsOptions
			}
		default:
			return {
				...state
			}
	}
}

export const getProductionSubstitutionsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSubstitutions.productionSubstitutions", errorIfNotFound && "No productionSubstitutions found")
}

export const getProductionSubstitutionSelector = (state, errorIfNotFound = false) => {
  return getData(state, "productionSubstitutions.productionSubstitution", errorIfNotFound && "No productionSubstitution found")
}

export const getProductionSubstitutionSnackbarSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSubstitutions.productionSubstitutionSnackbar", errorIfNotFound && "No productionSubstitutionSnackbar found")
}

export const getProductionSubstitutionSupplierItemsOptionsSelector = (state, errorIfNotFound = false) => {
	return getData(state, "productionSubstitutions.supplierItemsOptions", errorIfNotFound && "No supplierItems options found")
}