import React from "react"

const CreditNoteBloc = (props) => {
	const {
		data
	} = props
	
	return (
		<div>
			{data}
		</div>
	)
}

export default CreditNoteBloc