import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	typo: {
		fontSize: 20,
		fontWeight: 700,
		color: COLORS.SITE_INFORMATION_VALUE
	}
})

const CreditNoteTitle = (props) => {
	const {
		title
	} = props
	
	const classes = useStyles()
	
	return (
		<div className={classes.container}>
			<Typography className={classes.typo}>
				{title}
			</Typography>
		</div>
	)
}

export default CreditNoteTitle