import React, { useState, useEffect, useRef, useMemo } from "react"

import { ErrorMessage, Field } from "formik"
import { Autocomplete, Box, Button, CircularProgress, MenuItem, Select, Stack } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import ClearIcon from "@mui/icons-material/Clear"
import { cloneDeep, debounce, isEqual, uniqBy } from "lodash"
import _cloneDeep from "lodash/cloneDeep"

import { COLORS, roundNumber } from "../../../../utils"
import { PRODUCTION_STEPS_COL_WIDTHS, COLS_OFFSETS } from "../../../../utils/constant"
import {
  getEmptyStepComponent,
  getDefaultStepComponents,
  resetStepComponent,
  recalculateCostValues,
  updateCopyStepComponentCost,
  loopSupplierItemOnPriorSteps,
  updateAllRecipesFieldsOnChange,
  getProductionStepPointerObj,
  updatePriorStepsInUlteriorComponents
} from "../../../../utils/recipes"

import { searchSupplierItemsAutocomplete } from "../../../../parseManager/suppliers/supplierItems/parseSupplierItemManager"
import { convertGramsIntoKilos, convertKilosIntoGrams } from "../../../../utils/ressources"
import RemoveColumn from "../RemoveColumn"
import { StyledErrorMessage, StyledProductionStepTextField, StyledStepBodyCell, StyledStepFirstBodyColumn, StyledStepText } from "../StyledSectionComponents"

const widths = PRODUCTION_STEPS_COL_WIDTHS

const getOptionLabel = (option, stepNumbers) => {
  const currentStepNumber = stepNumbers && option.data && stepNumbers[option.data.index]
  // display step root index if it's a prior steps
  if (option.categoryId === "PRIOR_STEPS") {
    return currentStepNumber + ". " + option.name
  }
  return option.name
}

const FormikTextField = ({ field, ...props }) => (
  <StyledProductionStepTextField {...field} {...props} />
)

const autocompleteSx = {
  textField: {
    "& .MuiInput-input": {
      cursor: "pointer"
    }
  }
}

const FormikAutocomplete = ({ form, loading, field, readOnly = false, isHovered = false, supplierItems, priorSteps, category, ...props }) => {
  const { value } = field
  const { handleChange } = form

  const currentValue = value && category === "SUPPLIER_ITEM" ?
    supplierItems.find(supplierItem => supplierItem.objectId === value.id) :
    value && priorSteps.find(priorStep => priorStep.data.objectId === value.id)

  return (
    <Autocomplete
      {...props}
      sx={{ flex: 1, pointer: "cursor" }}
      options={props.options}
      // current values is found from supplierItems
      // values from the searched supplierItems options
      // so we need value so that the input is not empty after selection
      value={currentValue || value}
      groupBy={(option) => option.category}
      renderInput={(params) => (
        <StyledProductionStepTextField
          {...params}
          isHovered={isHovered}
          variant="standard"
          fullWidth
          inputProps={{ ...params.inputProps, readOnly }}
          sx={readOnly ? autocompleteSx.textField : null}
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export const FormikEditableStepSelect = ({ field, children, readOnly = false, ...props }) => {
  const { name, value } = field

  return (
    <Select
      {...props}
      name={name}
      value={value && value.objectId}
      variant="standard"
      isClearable={true}
      renderInput={(params) => (
        <StyledProductionStepTextField
          {...params}
          variant="standard"
          fullWidth
          inputProps={{ ...params.inputProps, readOnly }}
          sx={readOnly ? autocompleteSx.textField : null}
        />
      )}
    >
      {children}
    </Select>
  )
}

const getTransformationModeAvailable = (supplierItem, transformationModes, parentStep) => {
  return supplierItem && transformationModes && supplierItem.transformationModes ? (supplierItem.transformationModes.filter(transformation =>
    transformation?.transformationMode && transformationModes.find(t => t.objectId === transformation.transformationMode.objectId) && transformation.transformationMode.transformationType === parentStep.transformation
  )) : []
}

const EditableStepComponent = ({
  stepComponent,
  stepComponents,
  steps,
  parentStep,
  transformationModes,
  indexComponent,
  indexStep,
  sectionIndex,
  supplierItems = [],
  isHover,
  hasError,
  setFieldValue,
  fromRecipe,
  recipe,
  section,
  stepNumbers
}) => {
  const [supplierItemsOptions, setSupplierItemsOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [priorSteps, setPriorSteps] = useState([])

  const _stopPropagation = (event) => event && event.stopPropagation()

  const _calculatePriorSteps = (fromRecipe) => {
    const priorSteps = []
    let found = false

    steps.forEach((currentStep) => {
      let step = getProductionStepPointerObj(currentStep, fromRecipe)
      // if step is reusable we need to be able to select the last productionStep as a prior step
      // @see KFC 1852
      if (step.isReusable && step.productionSteps && !!step.productionSteps.length) {
        const temp = step
        const stepRef = step.productionSteps[step.productionSteps.length - 1]
        step = { ...stepRef, grossWeight: temp.grossWeight, netWeight: temp.netWeight, cost: temp.cost }
      }

      if (step.stepComponents && step.stepComponents.length > 0) {
        if (!found && !step.stepComponents.find(currentStepComponent => currentStepComponent.index === stepComponent.index)) {
          step.category = "PRIOR_STEPS"
          const copyStep = _cloneDeep(step)
          /** COPY STEP COMPONENT COSTS CALCULATION */
          for (let copyStepComponent of copyStep.stepComponents) {
            copyStepComponent = updateCopyStepComponentCost(copyStepComponent)
            /** PRIOR STEP COSTS CALCULATION */
            if (copyStepComponent.priorSteps && copyStepComponent.priorSteps.stepComponents && copyStepComponent.priorSteps.stepComponents.length > 0) {
              loopSupplierItemOnPriorSteps(copyStepComponent.priorSteps.stepComponents)
            }
          }

          priorSteps.push({
            name: copyStep.name ? copyStep.name : "NO_NAME",
            value: copyStep.objectId,
            index: copyStep.index,
            data: copyStep,
            category: "Résultat d'étapes antérieures",
            categoryId: "PRIOR_STEPS",
            stepRootIndex: copyStep.stepRootIndex
          })
        } else {
          found = true
          for (const stepComponent of step.stepComponents) {
            const currentStep = priorSteps.find(priorStep => priorStep.data?.index === stepComponent.priorSteps?.index)
            if (currentStep) {
              stepComponent.priorSteps.name = currentStep.name
            }
          }
        }
      }
    })

    // remove from options priorSteps already used as prior steps in another step so that they can't be selected twice
    const filteredPriorSteps = priorSteps.filter((priorStep) => {
      const alreadyUsed = steps.some((step) => {
        const stepRef = fromRecipe ? step.step : step
        if (stepRef.productionSteps) {
          const productionSteps = stepRef.productionSteps
          return productionSteps.some((productionStep) => {
            const stepComponents = productionStep.stepComponents
            return stepComponents && stepComponents.some((stepComponent) => {
              return stepComponent.priorSteps && stepComponent.priorSteps.index === priorStep.index
            })
          })
        } else if (stepRef.stepComponents) { // reusable
          const stepComponents = stepRef.stepComponents
          return stepComponents.some((stepComponent) => {
            return stepComponent.priorSteps && stepComponent.priorSteps.index === priorStep.index
          })
        }
      })
      return !alreadyUsed
    })

    const alreadyUsedPriorSteps = priorSteps.filter((priorStep) => {
      return !filteredPriorSteps.some((filteredPriorStep) => filteredPriorStep.index === priorStep.index)
    })
    return {
      priorSteps: filteredPriorSteps,
      alreadyUsedPriorSteps
    }
  }

  useEffect(() => {
    const { priorSteps, alreadyUsedPriorSteps } = _calculatePriorSteps(fromRecipe)
    setPriorSteps(priorSteps)
    const updatedSupplierItemsOptions = supplierItemsOptions.filter((option) => !alreadyUsedPriorSteps.some((alreadyUsedPriorStep) => alreadyUsedPriorStep.index === option.data.index))
    const options = [...priorSteps, ...updatedSupplierItemsOptions]
    const optionsDeduplicated = options.filter((obj, index) => options.findIndex((item) => item.value === obj.value && item.categoryId === obj.categoryId) === index)
    setSupplierItemsOptions(uniqBy(optionsDeduplicated, (option) => option.index))
  }, [steps])

  let supplierItem = stepComponent.supplierItem

  const _updatePriorStepsInUlteriorComponents = (method = "CREATE", modifiedStepComponent = null) => { // could be delete or create
    const { name } = parentStep
    updatePriorStepsInUlteriorComponents({
      currentStepComponent: modifiedStepComponent,
      currentStepName: name,
      currentStepIndex: indexStep,
      allSteps: steps,
      section,
      recipe,
      method,
      isProductionSteps: !!fromRecipe
    })
  }

  const _addStepComponent = (index, event = null) => {
    const newStepComponents = [...stepComponents]

    const emptyComponent = getEmptyStepComponent()

    newStepComponents.splice(index + 1, 0, emptyComponent)

    if (fromRecipe) {
      setFieldValue(
        `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents`,
        newStepComponents
      )
    } else {
      setFieldValue(
        `productionSteps[${indexStep}].stepComponents`,
        newStepComponents
      )
    }

    _updatePriorStepsInUlteriorComponents("CREATE", emptyComponent)

    _stopPropagation(event)
  }

  const _removeStepComponent = (index, event) => {
    let isPseudoDeletion = false
    const emptyStepComponent = getDefaultStepComponents()
    const stepComponentToDelete = stepComponents[index]

    stepComponents.splice(index, 1)
    if (!stepComponents.length) {
      stepComponents.splice(0, 0, emptyStepComponent)
      isPseudoDeletion = true
    }

    if (fromRecipe) {
      setFieldValue(`sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents`, stepComponents)
    } else {
      setFieldValue(`productionSteps[${indexStep}].stepComponents`, stepComponents)
    }


    _updatePriorStepsInUlteriorComponents("DELETE", stepComponentToDelete)

    if (isPseudoDeletion) {
      _updatePriorStepsInUlteriorComponents("CREATE", emptyStepComponent)
    }

    updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: stepComponent,
      isProductionSteps: fromRecipe,
    })

    _stopPropagation(event)
  }

  const clearStepComponentTransformationMode = () => {
    stepComponent.transformationMode = null
    stepComponent.transformRate = 100
    stepComponent.grossWeight = stepComponent.netWeight
    setFieldValue(stepComponentTransformationModeName, null)
    setFieldValue(stepComponentTransformRateName, 100)
    stepComponent = recalculateCostValues(stepComponent)

    updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: stepComponent,
      isProductionSteps: fromRecipe
    })
  }

  const transformationModesOptions = getTransformationModeAvailable(supplierItem, transformationModes, parentStep)

  const onChangeSupplierItemOrPriorStepAutocomplete = (newValue) => {
    stepComponent = resetStepComponent(stepComponent)
    newValue.data.id = newValue.data.objectId

    const priorStepFieldName = fromRecipe
      ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].priorSteps`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].priorSteps`

    const supplierItemFieldName = fromRecipe
      ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].supplierItem`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].supplierItem`

    if (newValue.categoryId === "PRIOR_STEPS") {
      stepComponent.priorSteps = newValue.data
      setFieldValue(priorStepFieldName, newValue.data)
      setFieldValue(supplierItemFieldName, null)
      return
    }

    supplierItem = newValue.data
    stepComponent.supplierItem = supplierItem
    setFieldValue(priorStepFieldName, null)
    setFieldValue(supplierItemFieldName, newValue.data)
    stepComponent = recalculateCostValues(stepComponent)
  }

  const searchingSupplier = useRef(
    debounce(async (event) => {
      if (event && event.type === "change") {
        setLoading(true)
        const supplierItemsFilters = { type: "RAW_MATERIAL" }
        const search = event.target.value
        const supplierItems = await searchSupplierItemsAutocomplete(search, supplierItemsFilters)
        const { priorSteps } = _calculatePriorSteps()
        const result = [...priorSteps, ...supplierItems.map((supplierItem) => ({
          name: supplierItem.name.toLowerCase(),
          value: supplierItem.objectId,
          index: supplierItem.index,
          data: supplierItem,
          category: "Articles fournisseurs",
          categoryId: "SUPPLIER_ITEM"
        }
        ))]

        setSupplierItemsOptions(result)
        setLoading(false)
      }
    }, 700)
  ).current

  const handleChangeNetWeight = (event) => {
    const newParentStep = cloneDeep(parentStep)
    let newStepComponent = { ...stepComponent, netWeight: convertGramsIntoKilos(+event.target.value) }
    newStepComponent = recalculateCostValues(newStepComponent)

    // update step component
    newParentStep.stepComponents[indexComponent] = newStepComponent

    if (fromRecipe) {
      // update parent step and steps
      const index = steps.findIndex(step => isEqual(step.step, parentStep))
      steps[index].step = newParentStep
    }
    else {
      // update parent step and steps
      const index = steps.indexOf(parentStep)
      steps[index] = newParentStep
    }
    // compute recipe data with new parent step and stepcompoent and retrieve total gross weight
    const { reusableStepTotalGrossWeight } = updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: newStepComponent,
      isProductionSteps: !!fromRecipe,
    })

    // recipe
    if (fromRecipe) {
      setFieldValue(
        `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}]`,
        newStepComponent
      )
    }
    else {
      // reusable step BO
      setFieldValue("grossWeight", reusableStepTotalGrossWeight)
      setFieldValue(
        `productionSteps[${indexStep}].stepComponents[${indexComponent}]`,
        newStepComponent
      )
    }
  }

  const handleChangeTransformationMode = (event) => {
    const transformationMode = transformationModesOptions.find(option => option.transformationMode.objectId === event.target.value)

    const newParentStep = cloneDeep(parentStep)

    let newStepComponent = {
      ...stepComponent,
      transformationMode: transformationMode.transformationMode,
      transformRate: transformationMode.transformationRate,
    }

    newStepComponent = recalculateCostValues(newStepComponent) // the change of grossweight is done here

    // update step component
    newParentStep.stepComponents[indexComponent] = newStepComponent

    if (fromRecipe) {
      // update parent step and steps
      const index = steps.findIndex(step => isEqual(step.step, parentStep))
      steps[index].step = newParentStep
      // update step form values
      setFieldValue(`sections[${sectionIndex}].productionSteps[${indexStep}].step`, newParentStep)
    }
    else {
      // update parent step and steps
      const index = steps.indexOf(parentStep)
      steps[index] = newParentStep
      // update step form values
      setFieldValue(`productionSteps[${indexStep}]`, newParentStep)
    }

    updateAllRecipesFieldsOnChange({
      recipe,
      section,
      steps,
      parentStep,
      currentStepComponentChanged: newStepComponent,
      isProductionSteps: !!fromRecipe
    })
  }

  const stepComponentArticleFormName = useMemo(() => {
    if (fromRecipe) {
      return stepComponent.supplierItem
        ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].supplierItem`
        : `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].priorSteps`
    }
    return stepComponent.supplierItem
      ? `productionSteps[${indexStep}].stepComponents[${indexComponent}].supplierItem`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].priorSteps`
  }, [fromRecipe, indexStep, indexComponent, sectionIndex, stepComponent, stepComponent.supplierItem])

  const stepComponentTransformationModeName = useMemo(() => {
    return fromRecipe
      ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].transformationMode`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].transformationMode`
  }, [fromRecipe, sectionIndex, indexComponent, indexStep])

  const stepComponentTransformRateName = useMemo(() => {
    return fromRecipe
      ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].transformRate`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].transformRate`
  }, [fromRecipe, sectionIndex, indexComponent, indexStep])

  const netWeightName = useMemo(() => {
    return fromRecipe
      ? `sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].netWeight`
      : `productionSteps[${indexStep}].stepComponents[${indexComponent}].netWeight`
  }, [fromRecipe, sectionIndex, indexComponent, indexStep])

  return (
    <Box
      sx={{
        display: "flex"
      }}
      onClick={_stopPropagation}
    >
      <StyledStepFirstBodyColumn className="flexRow center" style={{ backgroundColor: COLORS.PRODUCTION_STEPS_COMPONENT_WHITE }} leftStep={COLS_OFFSETS.stepComponents}>
        {isHover && (
          <Button onClick={(e) => _addStepComponent(indexComponent, e)} className="flexCenter" sx={{ position: "absolute", left: -8 }}>
            <img alt="plus icon" src="/icons/plus-blue.svg" />
          </Button>
        )}
        <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
          <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Field
                name={stepComponentArticleFormName}
                loading={loading}
                component={FormikAutocomplete}
                options={supplierItemsOptions}
                isHovered={isHover}
                forcePopupIcon={isHover}
                supplierItems={supplierItems}
                stepComponent={stepComponent}
                isOptionEqualToValue={(option, value) => value && option.value === value.value}
                priorSteps={priorSteps}
                getOptionLabel={(option) => getOptionLabel(option, stepNumbers)}
                onChange={(_, newValue) => onChangeSupplierItemOrPriorStepAutocomplete(newValue)}
                onInputChange={searchingSupplier}
                category={stepComponent.supplierItem ? "SUPPLIER_ITEM" : "PRIOR_STEPS"}
                renderOption={(props, option) =>
                (
                  <li {...props} key={`parent-${parentStep && parentStep.objectId}-${sectionIndex}-${indexStep}-${indexComponent}-${stepComponent.priorSteps && stepComponent.priorSteps.objectId}-${option.value}`}>
                    {option.name}
                  </li>
                )
                }
                disableClearable
                key={`parent-${parentStep && parentStep.objectId}-${sectionIndex}-step-${indexStep}-component-${indexComponent}-${stepComponent.priorSteps && stepComponent.priorSteps.objectId}`}
              />
              <ErrorMessage
                name={`sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].supplierItem`}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
              <ErrorMessage
                name={`sections[${sectionIndex}].productionSteps[${indexStep}].step.stepComponents[${indexComponent}].priorSteps`}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            </Stack>
            <ErrorMessage
              name={`sections[${sectionIndex}].productionSteps[${indexComponent}].step.name`}
              render={(message) => (
                <StyledErrorMessage>{message}</StyledErrorMessage>
              )}
            />
          </Stack>
        </Stack>
        {/* error message while not on hover */}
        {!isHover && hasError(indexComponent, "description") && (
          <ErrorMessage
            name={`sections[${sectionIndex}].productionSteps[${indexComponent}].step.description`}
            render={(message) => (
              <StyledErrorMessage>{message}</StyledErrorMessage>
            )}
          />
        )}
      </StyledStepFirstBodyColumn>
      <StyledStepBodyCell align="left" width={widths[1]}>
        <StyledStepText weight={0}>{stepComponent.grossWeight ? roundNumber(convertKilosIntoGrams(stepComponent.grossWeight), 3) : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[2]}>
        <StyledStepText weight={0}>{supplierItem && supplierItem.pricePerKg ? roundNumber(supplierItem.pricePerKg, 2) + " €" : "-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[3]}>
        <StyledStepText weight={0}>
          {stepComponent.realCost || stepComponent.realCost === 0 ? `${roundNumber(stepComponent.realCost, 3)} €` : "-"}
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[4]}>
        <StyledStepText weight={0}>
          {transformationModesOptions.length > 0 && isHover ? (
            <Stack className="flex1">
              <Field
                name={stepComponentTransformationModeName}
                component={FormikEditableStepSelect}
                stepComponent={stepComponent}
                transformationModesOptions={transformationModesOptions}
                isClearable={true}
                endAdornment={<IconButton sx={{ display: stepComponent.transformationMode ? "" : "none" }} onClick={() => clearStepComponentTransformationMode()}><ClearIcon /></IconButton>}
                onChange={handleChangeTransformationMode}
              >
                {transformationModesOptions && transformationModesOptions.map((transformation, keyIndex) => (
                  <MenuItem isClearable={true} key={indexStep + "-" + indexComponent + "-" + keyIndex + "-" + transformation.transformationMode.objectId} value={transformation.transformationMode.objectId}>
                    {transformation.transformationMode.name}
                  </MenuItem>
                ))}
              </Field>
              <ErrorMessage
                name={stepComponentTransformationModeName}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            </Stack>
          )
            :
            (
              <StyledStepText weight={0}>
                {stepComponent.transformationMode && stepComponent.transformationMode.name ? stepComponent.transformationMode.name : "-"}
              </StyledStepText>
            )
          }
        </StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[6]}>
        <StyledStepText weight={0}>{supplierItem && stepComponent.transformRate ? stepComponent.transformRate + " %" : "100 %"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[5]}>
        {supplierItem && isHover ?
          (
            <Stack>
              <Field
                component={FormikTextField}
                name={netWeightName}
                onClick={_stopPropagation}
                onChange={handleChangeNetWeight}
                value={isHover ? convertKilosIntoGrams(stepComponent.netWeight) : (stepComponent.netWeight) || 0}
                onWheel={(e) => e.target.blur()} // prevent increase and decrease on mouse wheel
                type="number"
              />
              <ErrorMessage
                name={netWeightName}
                render={(message) => (
                  <StyledErrorMessage>{message}</StyledErrorMessage>
                )}
              />
            </Stack>
          )
          :
          <StyledStepText weight={0}>{stepComponent.netWeight ? roundNumber(convertKilosIntoGrams(stepComponent.netWeight), 3) : "-"}</StyledStepText>
        }
      </StyledStepBodyCell>
      {/* ------------ kitchenArea ------------ */}
      <StyledStepBodyCell align="left" width={widths[7]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[8]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      <StyledStepBodyCell align="left" width={widths[9]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ stepDuration ------------ */}
      <StyledStepBodyCell align="left" width={widths[10]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ stepDurationUnit ------------ */}
      <StyledStepBodyCell align="left" width={widths[11]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ end step weighing ------------ */}
      <StyledStepBodyCell align="left" width={widths[12]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* ------------ end step weighing ------------ */}
      <StyledStepBodyCell align="left" width={widths[13]}>
        <StyledStepText>{"-"}</StyledStepText>
      </StyledStepBodyCell>
      {/* -------- delete icon -------- */}
      <RemoveColumn
        type="stepComponent"
        isHover={isHover}
        onClick={(e) => _removeStepComponent(indexComponent, e)}
      />
    </Box>
  )
}

export default EditableStepComponent
