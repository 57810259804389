import React from "react"
import { COLORS } from "../../../utils"
import makeStyles from "@mui/styles/makeStyles"
import moment from "moment"

const useStyles = makeStyles(() => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        height: 116,
        width: 166,
        borderRadius: 10,
        boxShadow: `0px 1px 1px ${COLORS.BLUE_PAPER_SELECTED}`,
        padding: 8,
        marginRight: 16,
        marginBottom: 16
    },
    card: {
        composes: "$root",
        color: COLORS.DARK_GREY,
        backgroundColor: COLORS.WHITE
    },
    cardSelected: {
        composes: "$root",
        color: COLORS.WHITE,
        backgroundColor: COLORS.PRIMARY_COLOR
    },
    values: {
        width: "100%",
        color: COLORS.DARK_GREY
    },
    valuesSelected: {
        width: "100%",
        color: COLORS.WHITE
    },
    title: {
        fontWeight: 700,
        textAlign: "left",
        width: "100%",
        marginTop: 0,
        marginBottom: 12,
        fontSize: 13
    },
    lotTitleSelected: {
        composes: "$title",
        color: COLORS.WHITE,
    },
    lotTitle: {
        composes: "$title",
        color: COLORS.GREY_900,
    },
    row: {
        display: "flex"
    },
    tableLabel: {
        textTransform: "uppercase",
        textAlign: "left",
        fontSize: 10,
        padding: 0
    },
    tableValue: {
        marginLeft: "auto",
        fontSize: 10,
        padding: 0
    },
    creditNoteRow: {
        composes: "$row",
        fontSize: 10,
        textTransform: "uppercase",
        color: COLORS.ORANGE_CREDIT_NOTE
    },
    lastRow: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        paddingBottom: 8,
        paddingRight: 16,
        width: "100%"
    },
    lastRowTableLabel: {
        textTransform: "uppercase",
        textAlign: "left",
        fontSize: 10,
        paddingTop: 6
    },
    lastRowTableValue: {
        marginLeft: "auto",
        fontSize: 17,
        fontWeight: 500
    }
}))

const LotCard = (props) => {
    const {
        lot,
        onSelectLot,
        selectedLot,
        creditNotes
    } = props

    const classes = useStyles()

    return (
        <div
            className={selectedLot && lot.id === selectedLot.id ? classes.cardSelected : classes.card}
            onClick={() => { onSelectLot(lot) }}
        >
            <p className={selectedLot && lot.id === selectedLot.id ? classes.lotTitleSelected : classes.lotTitle}>
                {`LOT ${lot.lotNumber}`}
            </p>
            <div className={selectedLot && lot.id === selectedLot.id ? classes.valuesSelected : classes.values}>
                <div className={classes.row}>
                    <div className={classes.tableLabel}>
                        dlc :
                    </div>
                    <div className={classes.tableValue}>
                        {moment(lot.dlc).format("DD/MM/YYYY")}
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.tableLabel}>
                        zone :
                    </div>
                    <div className={classes.tableValue}>
                        {lot?.stockZone?.name}
                    </div>
                </div>
                {
                    creditNotes > 0 &&
                    <div className={classes.creditNoteRow}>
                        {`${creditNotes} avoir${creditNotes > 1 ? "s" : ""}`}
                    </div>

                }
                <div className={classes.lastRow}>
                    <div className={classes.lastRowTableLabel}>
                        quantité
                    </div>
                    <div className={classes.lastRowTableValue}>
                        {lot.quantity}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LotCard
