import Parse from "parse"
import { parseLimitRequest } from "../../../utils"
import { getIngredientWithId } from "../../ingredients/internal/parseCommercialNameManager"
import { supplierItemTypes } from "../../../utils/supplierItemUtils"
import { getCookingMode } from "../../../utils/recipes"
import { getParseSite } from "../../site/parseSiteManager"
import { getSupplierWithId } from "../../products/resources/supplier/parseSupplierManager"
import { getRecipesBySupplierItem } from "../../recipe/parseRecipeManager"
import { recipeSectionsFormInitialValues } from "../../../actions/Utils/utils"
import { escapeRegExp } from "lodash"
import { queryFilters } from "../../utilsManager"

const CookingMode = Parse.Object.extend("CookingMode")
export const SupplierItem = Parse.Object.extend("SupplierItems")


export const getSupplierItemsBySupplier = async (supplier, toJSON = true) => {
    try {
        const supplierItems = (await new Parse.Query(SupplierItem)
            .equalTo("supplier", supplier)
            .notEqualTo("deleted", true)
            .include(["commercialName.group.family.name", "site", "stockZone"])
            .ascending("name")
            .limit(parseLimitRequest)
            .find()) || []

        return toJSON ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItemsBySupplier error : ", e)
        return Promise.reject(e)
    }
}

export const querySearchSupplierItems = async (search, supplier, toJson = true) => {
    try {
        const regex = new RegExp(escapeRegExp(search), "ig")

        const supplierItems = (await new Parse.Query(SupplierItem)
            .equalTo("supplier", supplier)
            .notEqualTo("deleted", true)
            .matches("name", regex)
            .find()) || []

        return toJson ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.products.supplier.parseSupplierItemManager.querySearchSupplierItems error : ", e)
        return Promise.reject(e)
    }
}

export async function searchSupplierItemsByName(name, showNoCommercialName = true) {
    try {
        const query = await new Parse.Query(SupplierItem)
            .contains("name", name.toUpperCase())
            .equalTo("type", supplierItemTypes.RAW_MATERIAL.key)
            .notEqualTo("deleted", true)
            .include("cookingModes.cookingMode")
            .include("commercialName")
            .include("commercialName.allergens")

        if (!showNoCommercialName) {
            query.notEqualTo("commercialName", null)
        }

        return query.find() || []
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.searchSupplierItemsByName : ", e)
        return Promise.reject(e)
    }
}

export async function getSupplierItems({ includes = [], selects = [], sortBy = "name", sortDirection = "asc", toJSON = false } = {}) {
    try {
        const query = new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)

        if (includes?.length) {
            query.include(includes)
        }

        if (selects?.length) {
            query.select(selects)
        }

        if (sortDirection === "desc") {
            query.descending(sortBy)
        } else {
            query.ascending(sortBy)
        }

        const supplierItems = await query.limit(4500).find()

        return toJSON ? (supplierItems ? supplierItems.map(el => el.toJSON()) : []) : supplierItems
    } catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItems : ", e)
        return []
    }
}


export async function getSupplierItemWithId(id, includes = [], toJson = true) {
    try {
        const supplierItem = (await new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)
            .equalTo("objectId", id)
            .include(includes)
            .first())

        if (!supplierItem) return null

        return toJson ? supplierItem.toJSON() : supplierItem
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItemWithId : ", e)
        return Promise.reject(e)
    }
}

export const deleteSupplierItemById = async (id) => {
    try {
        const supplierItem = await new Parse.Query(SupplierItem)
            .equalTo("objectId", id)
            .first()

        if (!supplierItem) return
        supplierItem.set("deleted", true)
        await supplierItem.save()
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.deleteSupplierItemById : ", e)
        return Promise.reject(e)
    }
}

export async function updateSupplierItem(supplierItemId, keys, values, toJson = true) {
    try {
        const supplierItem = await getSupplierItemWithId(supplierItemId, ["commercialName", "commercialName.group.family", "commercialName.allergens", "cookingModes.cookingMode", "supplier", "site", "stockZone", "units"], false)

        if (!supplierItem && values) {
            return null
        }

        for (const key of keys) {
            if (key === "commercialName") {
                const commercialName = await getIngredientWithId(values[key].objectId, ["commercialName", "commercialName.group.family", "commercialName.allergens", "cookingModes.cookingMode"], false)

                supplierItem.set(key, commercialName)
            }
            else if (key === "cookingModes") {
                const finalCookingModes = []

                for (const value of values) {
                    const parseCookingMode = await getCookingMode(value.cookingMode.objectId)

                    finalCookingModes.push({
                        cookingMode: parseCookingMode,
                        transformRate: +value.transformRate
                    })
                }

                supplierItem.set(key, finalCookingModes)
            }
            else if (key === "stockage") {

                const site = await getParseSite(values.site, [], false)
                const currentStockZone = site.get("stockZones").find(el => el.id === values.stockZone)

                supplierItem.set("site", site)
                supplierItem.set("stockZone", currentStockZone)
            }
            else if (key === "billingUnits") {
                const copy = supplierItem.get("units")

                if (copy) {
                    copy.billing.price = parseFloat(values.billingUnitPrice.toFixed(3))

                    if (copy.order) {
                        copy.order.price = parseFloat((values.billingUnitPrice * copy.order.weight / copy.billing.weight).toFixed(3))
                    }
                    if (copy.stock) {
                        copy.stock.price = parseFloat((values.billingUnitPrice * copy.stock.weight / copy.billing.weight).toFixed(3))
                    }

                    supplierItem.set("units", copy)

                } else {
                    const units = {
                        billing: { price: parseFloat(values.billingUnitPrice.toFixed(3)) }
                    }
                    supplierItem.set("units", units)
                }

            }
            else if (key === "supplierArticleName") {
                if (values.supplierArticleName) {
                    supplierItem.set("supplierArticleName", values.supplierArticleName)
                } else {
                    supplierItem.set("supplierArticleName", values.name)
                }
            }
            else if (key === "productType") {
                if (supplierItem.get("type") === supplierItemTypes.SALABLE_PRODUCT.key || supplierItem.get("type") === supplierItemTypes.PACKAGING_CONSUMABLE.key) {
                    supplierItem.set("productType", values.productType)
                }
            }
            else {
                supplierItem.set(key, values[key])
            }
        }

        const newSupplierItem = await supplierItem.save()

        return newSupplierItem ? toJson ? supplierItem.toJSON() : supplierItem : null
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.updateSupplierItem : ", e)
        return Promise.reject(e)
    }
}

export async function updateRecipesFoodcost(newSupplierItem) {
    // update the recipe foodcost if the pricePerKg field is changed
    const recipes = await getRecipesBySupplierItem(newSupplierItem.objectId, false)
    for (const recipe of recipes) {
        const recipeValues = recipeSectionsFormInitialValues(recipe)
        recipe.set("foodcost", recipeValues.cost)
        recipe.save()
    }
}
export async function getSupplierItemsWithIds(ids, toJSON = true) {
    try {
        const supplierItems = (await new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)
            .containedIn("objectId", ids)
            .find()) || []

        return toJSON ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItemsWithIds : ", e)
        return Promise.reject(e)
    }
}

export async function createSupplierItem(values, original, toJson = true) {
    const supplierItem = original ? await getSupplierItemWithId(original.objectId, ["commercialName", "cookingModes.cookingMode"], false) : new SupplierItem()
    const keyToIgnore = [
        "family", "allergens", "group", "commercialName", "cookingModes", "supplier"
    ]
    if (values.commercialName) {
        const commercialName = await getIngredientWithId(values.commercialName.objectId, [], false)
        supplierItem.set("commercialName", commercialName)
    }
    else {
        supplierItem.unset("commercialName")
    }

    if (values.cookingModes) {
        supplierItem.set("cookingModes", values.cookingModes.map(cookingMode => {
            return { cookingMode: cookingMode.cookingMode, transformRate: parseFloat(cookingMode.transformRate) }
        }))
        // add default cookingModes with transformRate 100
    } else {
        const cookingModes = await getCookingModes(false)
        supplierItem.set("cookingModes", cookingModes.map(cookingMode => {
            return { cookingMode, transformRate: 100 }
        }))
    }


    if (values.supplier) {
        const supplier = await getSupplierWithId(values.supplier.objectId, [], false)
        supplierItem.set("supplier", supplier)
    }

    Object.keys(values).forEach(function (key) {
        const val = values[key]
        if (!keyToIgnore.includes(key) && val) {
            supplierItem.set(key, val)
        }
    })

    if (supplierItem.get("type") === "RAW_MATERIAL") {
        supplierItem.set("useOnRecipe", true)
    }

    supplierItem.set("availabilityAtSupplier", true)

    await supplierItem.save()

    return toJson
        ? supplierItem.toJSON()
        : supplierItem
}

export async function getCookingModes(toJson = true) {
    try {
        const cookingModes = (await new Parse.Query(CookingMode)
            .notEqualTo("deleted", true)
            .limit(parseLimitRequest)
            .find()) || []

        return toJson
            ? cookingModes.map(cookingMode => cookingMode.toJSON())
            : cookingModes
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getCookingModes : ", e)
        return Promise.reject(e)

    }
}

export async function changeSupplierItemState(supplierItemId, value, toJson = true) {
    try {
        const supplierItem = await getSupplierItemWithId(supplierItemId, [], false)
        supplierItem.set("isActive", value)

        await supplierItem.save()

        return toJson
            ? supplierItem.toJSON()
            : supplierItem
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.changeSupplierItemState : ", e)
        return Promise.reject(e)

    }
}

export async function getRawMaterialSupplierItems({ toJSON = true, includes = [], select = [] } = {}) {
    try {
        const query = new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)
            .equalTo("type", supplierItemTypes.RAW_MATERIAL.key)
        
        if (includes?.length) {
            query.include(includes)
        }
        if (select?.length) {
            query.select(select)
        }

        const supplierItems = await query.limit(parseLimitRequest).find() || []

        return toJSON ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getRawMaterialSupplierItems : ", e)
        return Promise.reject(e)
    }
}

export async function getSupplierItemRelatives(supplierItemIdChild, toJSON = true) {
    try {
        const supplierItems = await new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)
            .equalTo("substitutionList.objectId", supplierItemIdChild)
            .limit(parseLimitRequest)
            .find() || []

        return toJSON ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getRawMaterialSupplierItems : ", e)
        return Promise.reject(e)
    }
}

export async function getSupplierItemsAssociatedToCommercialName(commercialNameId, fieldsToSelect = [], toJSON = true) {
    try {
        const ingredient = await getIngredientWithId(commercialNameId, [], false)
        const supplierItems = await new Parse.Query(SupplierItem)
            .notEqualTo("deleted", true)
            .equalTo("commercialName", ingredient)
            .select(fieldsToSelect)
            .limit(parseLimitRequest)
            .find() || []

        return toJSON ? supplierItems.map(supplierItem => supplierItem.toJSON()) : supplierItems
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItemsAssociatedToCommercialName : ", e)
        return Promise.reject(e)
    }
}

export const searchSupplierItemsAutocomplete = async (search, filters) => {
    try {
        const regex = new RegExp(escapeRegExp(search), "ig")

        const query = new Parse.Query(SupplierItem)
            .include("cookingModes.cookingMode")
            .equalTo("isActive", true)
            .equalTo("type", "RAW_MATERIAL")
            .notEqualTo("deleted", true)
            .matches("name", regex)

        queryFilters(query, filters)

        const supplierItems = await query.find() || []


        return supplierItems.map(supplierItem => supplierItem.toJSON())
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.searchSupplierItemsByName : ", e)
        return Promise.reject(e)
    }
}

/**
 * search supplier item by name or by id
 * @param {*} search 
 * @returns 
 */
export const searchPackagingSupplierItems = async (search) => {
    try {
        const regex = new RegExp(escapeRegExp(search), "ig")

        let query = new Parse.Query(SupplierItem)

        query = new Parse.Query.or(
            new Parse.Query(SupplierItem).matches("name", regex),
            new Parse.Query(SupplierItem).equalTo("objectId", search),
        )

        query
            .include("cookingModes.cookingMode")
            .equalTo("isActive", true)
            .equalTo("type", "PACKAGING_CONSUMABLE")
            .notEqualTo("deleted", true)

        const supplierItems = await query.find() || []

        return supplierItems.map(supplierItem => supplierItem.toJSON())
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.searchPackagingSupplierItems : ", e)
        return Promise.reject(e)
    }
}

export async function getSupplierItemHypotheticalTreated(ids) {
    try {
        const supplierItems = await new Parse.Query(SupplierItem)
            .select(["objectId", "name", "hypotheticalLotsTreated"])
            .containedIn("objectId", ids)
            .exists("hypotheticalLotsTreated")
            .limit(parseLimitRequest)
            .find() || []

        return supplierItems.map(supplierItem => supplierItem.toJSON())
    }
    catch (e) {
        console.error("parseManager.suppliers.supplierItems.parseSupplierItemManager.getSupplierItemHypotheticalTreated : ", e)
        return Promise.reject(e)
    }
}
