import React from "react"
import ControlPointIcon from "@mui/icons-material/ControlPoint"
import makeStyles from "@mui/styles/makeStyles"
import {COLORS} from "../../../utils"

const useStyles = makeStyles(() => ({
    container: {
        color: COLORS.DARK_GREY,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: 116,
        width: 166,
        borderRadius: 10,
        boxShadow: `0px 1px 1px ${COLORS.BLUE_PAPER_SELECTED}`
    },
    addIcon: {
        color: COLORS.PRIMARY_COLOR,
        width: 40,
        height: 40
    }
}))

const AddLotCard = (props) => {
    const {
        onAddLot
    } = props

    const classes = useStyles()

    return (
        <div
            className={classes.container}
            onClick={onAddLot}
        >
            <ControlPointIcon
                className={classes.addIcon}
            />
        </div>
    )
}

export default AddLotCard
