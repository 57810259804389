import React from "react"
import { makeStyles } from "@mui/styles"
import Typography from "@mui/material/Typography"
import { COLORS } from "../../../utils"

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    typo: {
        fontSize: 20,
        fontWeight: 700,
        color: COLORS.SITE_INFORMATION_VALUE
    },
    label: {
        fontWeight: 400
    }
})

const CreditNoteLotInfos = (props) => {
    const {
        title,
        lotNumber,
        dlc
    } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.typo}>
                {title}
            </Typography>
             <Typography className={classes.typo}>
                 <span className={classes.label}>LOT : </span>{lotNumber}
             </Typography>
             <Typography className={classes.typo}>
                 <span className={classes.label}>DLC : </span>{dlc}
             </Typography>
        </div>
    )
}

export default CreditNoteLotInfos