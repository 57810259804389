import React, { useState } from "react"
import FullScreenWrapper from "../../FullScreenWrapper"
import CreditNote from "./CreditNote"
import { creditNotesReasons, creditNotesType } from "../../../utils/creditNoteUtils"
import Button from "@mui/material/Button"

const CreditNoteDelivery = (props) => {
	const {
		headerData,
        orderData,
        onSaveCreditNote
	} = props

    const [reason, setReason] = useState(null)
    const [comment, setComment] = useState(null)
    const [images, setImages] = useState([])


    const actions = (callback) => {
        switch (callback.method) {
            case "UPDATE_COMMENT":
                setComment(callback.data.comment)
                return
            case "UPDATE_IMAGES":
                setImages(callback.data.images)
                return
            case "UPDATE_REASON":
                setReason(callback.data.reason)
                return
            default: 
                return
        }
    }

    const saveCreditNote = () => {
        onSaveCreditNote({
            reason,
            comment,
            images,
            amount: orderData.totalAmount
        })
    }

    const renderCreditNoteAction = () => {
        return (
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveCreditNote}
                    disabled={!reason}
                >
                    Envoyer la demande d'avoir
                </Button>
            </div>
        )
    }

    const data = {
        type: creditNotesType.DELIVERY,
        header: {...headerData, actions: renderCreditNoteAction()},
        items: [
            creditNotesReasons.TEMPERATURE.key,
            creditNotesReasons.NOT_PRESENTED.key,
            creditNotesReasons.LATE_DELIVERY.key,
            creditNotesReasons.MISSING_DOCUMENT.key,
            creditNotesReasons.TRUCK_CLEANLINESS.key,
            creditNotesReasons.OTHER.key
        ],
        imageName: `order_${orderData.orderNumber}`,
        callback: actions,
        amount: orderData.totalAmount
    }

	return (
        <FullScreenWrapper>
            <CreditNote
                data={data}
            />

        </FullScreenWrapper>
	)
}

export default CreditNoteDelivery