import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import moment from "moment"
import { generateStockUnitLabel } from "../../../utils/orderReception"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { creditNotesType } from "../../../utils/creditNoteUtils"

const useStyles = makeStyles(() => ({
	input: {
		width: 249
	},
	firstBloc: {
		marginTop: 50,
		marginLeft: 49
	},
	bloc: {
		marginTop: 70,
		marginLeft: 49
	},
	btnBloc: {
		textAlign: "center"
	},
	firstBtn: {
		marginTop: 115,
		width: 219,
		"@media (max-height: 770px)": {
			marginTop: 40
		}
	},
	secondBtn: {
		marginTop: 16,
		width: 219
	}
}))

const LotPreview = (props) => {
	const {
		lot,
		onDeleteLot,
		onAddCreditNote
	} = props
	
	const classes = useStyles()
	
	const deleteLot = () => {
		onDeleteLot({
			method: "DELETE_LOT",
			data: {
				id: lot.id,
				quantity: lot.quantity
			}
		})
	}

	const addCreditNote = () => {
		onAddCreditNote({
			method: "SHOW_ADD_CREDIT_NOTE_MODAL",
			data: {
				type: creditNotesType.LOT
			}
		})
	}
	
	return (
		<div>
			<div className={classes.firstBloc}>
				<TextField
					variant="standard"
					label="Lot"
					value={lot.lotNumber}
					disabled
				/>
			</div>
			<div className={classes.bloc}>
				<TextField
					variant="standard"
					label="DLC"
					value={moment(lot.dlc).format("DD/MM/YYYY")}
					disabled
				/>
			</div>
			<div className={classes.bloc}>
				<TextField
					variant="standard"
					label="Quantité"
					value={lot.quantity}
					disabled
				/>
				<p>
					{ generateStockUnitLabel(lot.orderSupplierItem) }
				</p>
			</div>
			<div className={classes.bloc}>
				<TextField
					variant="standard"
					label="Zone de stockage"
					value={lot.stockZone.name}
					disabled
				/>
			</div>
			<div className={classes.btnBloc}>
				<Button
					variant="contained"
					color="primary"
					className={classes.firstBtn}
					onClick={addCreditNote}
				>
					générer un avoir
				</Button>
				<Button
					variant="outlined"
					color="primary"
					className={classes.secondBtn}
					onClick={() => {deleteLot()}}
				>
					supprimer ce lot
				</Button>
			</div>
		</div>
	)
}

export default LotPreview
