import React, { useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import RepeatIcon from "@mui/icons-material/Repeat"
import { makeStyles } from "@mui/styles"
import { Button, Icon, Stack, Typography } from "@mui/material"

import { COLORS } from "../../utils"
import GenericTopBarHeader from "../../components/GenericTopBarHeader"
import GenericHeaderReturnButton from "../../components/GenericHeaderReturnButton"
import MoreMenu from "../../components/MoreMenu"
import { PRODUCTION_STEPS_SUPERVISION_URLS, showProductionStepsSupervision } from "../../actions/ProductionSteps/ProductionStepExecutions"
import { getProductionStepsProductionDaySelector, getProductionStepsSiteSelector, getProductionStepsKitchenAreaSelector } from "../../reducers/Production/ProductionSteps"
import { showProductionStepsLogin } from "../../actions/ProductionSteps/ProductionStepExecutions"

const useStyles = makeStyles({
  headerBlock: {
    flex: "0 0 64px",
    width: "100%"
  },
  header: {
    width: "100%"
  },
  headerTitle: {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    color: COLORS.UTILITARY_PURPLE
  },
  headerSubtitle: {
    paddingLeft: 8,
    fontSize: 16,
    color: COLORS.DRAFT_GREY
  }
})

const ProductionStepsSupervisionHeader = ({ pathname = "", rightAction, onBack}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const productionDay = useSelector(getProductionStepsProductionDaySelector)
  const site = useSelector(getProductionStepsSiteSelector)
  const kitchenArea = useSelector(getProductionStepsKitchenAreaSelector)

  // actions from more menus
  const openPSEGenerationDialog = () => {
    dispatch({
      type: "TOGGLE_PSE_GENERATION_DIALOG",
      open: true,
      isForOneRecipe: false
    })
  }

  const openPSEGenerationForOneRecipeDialog = () => {
    dispatch({
      type: "TOGGLE_PSE_GENERATION_DIALOG",
      open: true,
      isForOneRecipe: true
    })
  }

  const moreMenus = useMemo(() => {
    switch (pathname) {
      case PRODUCTION_STEPS_SUPERVISION_URLS.filter:
        return [
          {
            onClick: openPSEGenerationDialog,
            label: "Générer les étapes de production pour une journée"
          },
          {
            onClick: openPSEGenerationForOneRecipeDialog,
            label: "Regénérer les étapes pour une recette"
          },
        ]
      default:
        return []
    }
  }, [pathname])

  const subtitle = useMemo(() => {
    if (pathname === PRODUCTION_STEPS_SUPERVISION_URLS.login) return ""

    const formattedProductionDay = moment.utc(productionDay).format("DD/MM/YYYY")
    return `/ ${site?.name} - ${kitchenArea?.name} - ${formattedProductionDay}`
  }, [pathname, site, kitchenArea, productionDay])

  const returnAction = useMemo(() => {
    switch (pathname) {
      case PRODUCTION_STEPS_SUPERVISION_URLS.filter:
        return null
      case PRODUCTION_STEPS_SUPERVISION_URLS.read:
        return () => dispatch(showProductionStepsLogin())
      case PRODUCTION_STEPS_SUPERVISION_URLS.productionStepExecution:
        return () => dispatch(showProductionStepsSupervision())
      default:
        return null
    }
  }, [pathname])


  const cancelAction = useMemo(() => {
    switch (pathname) {
      case PRODUCTION_STEPS_SUPERVISION_URLS.hoursEdit:
        return () => dispatch(showProductionStepsSupervision())
      default:
        return null
    }
  }, [pathname])

  const formId = useMemo(() => {
    switch (pathname) {
      case PRODUCTION_STEPS_SUPERVISION_URLS.hoursEdit:
        return "productionStepsHoursEdit"
      default:
        return ""
    }
  }, [pathname])

  const _renderLeftAction = useCallback(() => {
    if (returnAction || onBack) {
      return (
        <GenericHeaderReturnButton handleClick={onBack || returnAction} />
      )
    }
    return null
  }, [returnAction, onBack])

  const _renderRightAction = useCallback(() => {
    const showMenus = moreMenus && moreMenus.length !== 0
    const showButtons = cancelAction || formId
    return <div className={classes.rightAction}>
      {showMenus && <MoreMenu menus={moreMenus} />}
      {showButtons && (
        <Stack direction="row" spacing={2}>
          {cancelAction && <Button variant="outlined" onClick={cancelAction}>Annuler</Button>}
          {formId && <Button type="submit" form={formId} variant="contained" color="primary">Enregistrer</Button>}
        </Stack>)
      }
    </div>
  }, [moreMenus, cancelAction, formId])

  return (
    <div className={classes.headerBlock}>
      <GenericTopBarHeader
        leftAction={_renderLeftAction()}
        title={(
          <div className={classes.headerTitle}>
            <Icon>
              <RepeatIcon />
            </Icon>
            <Typography variant="h7" sx={{ fontWeight: 500 }}>
              Étapes de production
            </Typography>
          </div>
        )}
        info={
          <p className={classes.headerSubtitle}>
            {subtitle}
          </p>
        }
        rightAction={rightAction || _renderRightAction()}
        className={classes.header}
      />
    </div>
  )
}

export default ProductionStepsSupervisionHeader