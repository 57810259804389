import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"
import { DatePicker } from "@mui/x-date-pickers"

import {
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  styled,
  Stack,
  FormHelperText,
} from "@mui/material"
import { COLORS, getTimezoneDate } from "../utils"
import { DateSchema } from "../utils/yupValidators"


const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: 500,
    padding: 10
  },
})

const sx = {
  title: {
    pb: 0,
    fontSize: 16,
    color: COLORS.BLACK_500,
    fontWeight: 400,
    lineHeight: 1.375
  },
}

export const DateFormDialog = ({ onClose, open, onConfirm, buttonText, title, inputLabel }) => {
  const [disabledButton, setDisabledButton] = useState(true)
  const formikRef = useRef(null)

  const handleConfirm = () => {
    if (!formikRef.current) return
    formikRef.current.submitForm()
  }

  const handleSubmit = (values) => {
    onConfirm(values)
    // onClose()
  }

  const handleCancel = () => {
    onClose()
  }

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" sx={sx.title}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: 4 }}>
        <Formik
          innerRef={formikRef}
          onSubmit={handleSubmit}
          validationSchema={DateSchema}
          initialValues={{ date: null }}
          validateOnChange
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Stack spacing={1}>
                  <DatePicker
                    showToolbar={false}
                    value={values.date}
                    name="date"
                    inputFormat="DD/MM/YY"
                    label={inputLabel}
                    disablePast
                    renderInput={(params) => <TextField {...params} variant="standard" error={!!errors.date} InputLabelProps={{ shrink: true }} />}
                    onChange={(date) => {
                      setFieldValue("date", getTimezoneDate(date))
                      setDisabledButton(false)
                    }}
                  />
                  {errors.date && (
                    <FormHelperText error sx={{ my: 1 }}>
                      {errors.date}
                    </FormHelperText>
                  )}
                </Stack>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleCancel} color="primary">
          Annuler
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={disabledButton}

        >
          {buttonText}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default DateFormDialog
